import React from 'react'
import NavBar from '../components/Nav'
import BlogPosts from '../components/BlogPosts'
import { Helmet } from 'react-helmet'

function ViewBlog() {
  return (
    <>
        <Helmet>
          <title>Peer2Own - Blog</title>
        </Helmet>
        <NavBar />
        <br />
        <div className='container'>
            <h1 className='p2o-page-title'>Blog Posts</h1>
            <BlogPosts />
        </div>
    </>
  )
}

export default ViewBlog