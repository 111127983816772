const { createSlice } = require("@reduxjs/toolkit")

const initialState = false

const LoadingSlice = createSlice({
    name: 'loading',
    initialState,
    reducers: {
        setLoadingState(state, action) {
            const newstate = action.payload
            return newstate
        }
    }
})

module.exports = {
    LoadingSlice,
}    