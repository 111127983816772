import React, {useState, useEffect} from 'react'
import LoadingIndicator from './LoadingIndicator'
import Project from './Project'
import { useProject } from '../hooks/useProject'
import { useSelector } from 'react-redux'

function MyProjects() {
  const user = useSelector(state => state.user)
  const [projects, setProjects] = useState([])
  const [isLoading, setIsLoading]= useState(false)

  const {watchMyProjects} = useProject()

  useEffect(() => {
      (async () => {
          await watchMyProjects(user.uid, setProjects)
          setIsLoading(false)
      })()
  }, [])

  return (
    <>
      {
          isLoading ?
          <div className='text-center'>
              <LoadingIndicator color='#000' />
          </div> :
          (
            projects.length > 0 ?
            projects.map((project, index) => <Project key={index} project={project} />) :
            <div className='h3 text-center'>
              You have not joined any projects yet.
            </div>
          )
      }
    </>
  )
}

export default MyProjects