import * as React from "react";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

function CustomLightbox ({open, setOpen, slides}) {
    return (
        <Lightbox
            open={open}
            close={() => setOpen(false)}
            slides={slides}
        />
    )
}

export default CustomLightbox