import React, {useState} from 'react'
import { usePreference } from '../hooks/usePreference'
import {useSelector} from 'react-redux'
import LoadingIndicator from './LoadingIndicator';
import Toast from './Toast';

function AddPreferenceForm() {
    const user = useSelector(state => state.user);
    const [propertyType, setPropertyType] = useState('')
    const [propertyUse, setPropertyUse] = useState('')
    const [choiceLocation, setChoiceLocation] = useState('')
    const [choiceLocationPlace, setChoiceLocationPlace] = useState('')
    const [choiceLocationPlaces, setChoiceLocationPlaces] = useState([])
    const [budget, setBudget] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [successMessage, setSuccessMessage] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)

    const { addPreference, fetchChoiceLocationPlaces } = usePreference()

    const handlePropertyType = event => {
        const propertyType = event.target.value
        setPropertyType(propertyType)
    }

    const handlePropertyUse = event => {
        const propertyUse = event.target.value
        setPropertyUse(propertyUse)
    }

    const handleChoiceLocation = async event => {
        const choiceLocation = event.target.value
        setChoiceLocation(choiceLocation)

        const places = await fetchChoiceLocationPlaces(choiceLocation)
        setChoiceLocationPlaces(places)
    }

    const handleChoiceLocationPlace = event => {
        const choiceLocationPlace = event.target.value
        setChoiceLocationPlace(choiceLocationPlace)
    }

    const handleBudget = event => {
        const budget = event.target.value
        setBudget(budget)
    }

    const validatePreference = () => {
        if(propertyType === '') throw new Error("Select Property Type");
        if(propertyUse === '') throw new Error("Select Property Use");
        if(choiceLocation === '') throw new Error("Select a Choice Location");
        if(choiceLocationPlace === '') throw new Error("Select a Place In Your Choice Location");
        if(budget === '') throw new Error("Select a Budget");

        return true
    }

    const handleAddPreference = async event => {
        try {
            event.preventDefault();
            setIsLoading(true)
            const preference = {
                propertyType: propertyType,
                propertyUse: propertyUse,
                choiceLocation: choiceLocationPlace ? choiceLocation + ' - ' + choiceLocationPlace : choiceLocation,
                budget: budget
            }
    
            if(!validatePreference()) return;
            await addPreference(user.uid, preference)

            setSuccessMessage('Preference Added Successfully.')
            setIsLoading(false)
            setPropertyType('')
            setPropertyUse('')
            setChoiceLocation('')
            setBudget('')
        } catch (error) {
            setErrorMessage(error.message)
            setIsLoading(false)
            setPropertyType('')
            setPropertyUse('')
            setChoiceLocation('')
            setBudget('')
        }
    }

  return (
    <div className='add-preference-form'>
        {
          successMessage &&
          <Toast message={successMessage} setSuccess={setSuccessMessage}/>
        }
        {
          errorMessage &&
          <Toast message={errorMessage} setError={setErrorMessage}/>
        }

        <h5 className='add-preference-form-title'>
            <strong>Add Your Preference</strong>
        </h5>
        <hr className='add-preference-form-title-divider'/>
      
        <form onSubmit={handleAddPreference}>
            <div className="form-group">
                <label for="propertyTypeControlSelect">Property Type</label>
                <select value={propertyType} onChange={handlePropertyType} className="form-control add-pref-select" id="propertyTypeControlSelect">
                    <option value='' className='add-pref-option'>Select Property Type</option>
                    <option value='Plot of Land'>Plot of Land</option>
                    <option value='Acre of Land'>Acre of Land</option>
                    <option value='Hectre of Land'>Hectre of Land</option>
                    <option value='Studio Apartment'>Studio Apartment</option>
                    <option value='1 Bedroom Apartment'>1 Bedroom Apartment</option>
                    <option value='2 Bedroom Apartment'>2 Bedroom Apartment</option>
                    <option value='3 Bedroom Apartment'>3 Bedroom Apartment</option>
                    <option value='4 Bedroom Apartment'>4 Bedroom Apartment</option>
                    <option value='Terrace Duplex'>Terrace Duplex</option>
                    <option value='Semi-detached Duplex'>Semi-detached Duplex</option>
                    <option value='Estate'>Estate</option>
                    <option value='Luxury Apartment'>Luxury Apartment</option>
                    <option value='Office Building'>Office Building</option>
                    <option value='Warehouse Space'>Warehouse Space</option>
                    <option value='Factory Building'>Factory Building</option>
                </select>
            </div>
            <div className="form-group">
                <label for="propertyUseControlSelect">Property Use</label>
                <select value={propertyUse} onChange={handlePropertyUse} className="form-control" id="propertyUseControlSelect">
                    <option value=''>Select Property Use</option>
                    <option value='Residential'>Residential</option>
                    <option value='Commercial'>Commercial</option>
                    <option value='Industrial'>Industrial</option>
                    <option value='Agricultural'>Agricultural</option>
                    <option value='Mixed'>Mixed</option>
                    <option value='Sports'>Sports</option>
                    <option value='Entertainment'>Entertainment</option>
                </select>
            </div>
            <div className="form-group">
                <label for="choiceLocationControlSelect">Choice Location</label>
                <select value={choiceLocation} onChange={handleChoiceLocation} className="form-control" id="choiceLocationControlSelect">
                    <option value=''>Select Choice Location</option>
                    <option value='Abia'>Abia</option>
                    <option value='Abuja'>Abuja</option>
                    <option value='Adamawa'>Adamawa</option>
                    <option value='Akwa Ibom'>Akwa Ibom</option>
                    <option value='Anambra'>Anambra</option>
                    <option value='Bauchi'>Bauchi</option>
                    <option value='Bayelsa'>Bayelsa</option>
                    <option value='Benue'>Benue</option>
                    <option value='Borno'>Borno</option>
                    <option value='Cross River'>Cross River</option>
                    <option value='Delta'>Delta</option>
                    <option value='Ebonyi'>Ebonyi</option>
                    <option value='Edo'>Edo</option>
                    <option value='Ekiti'>Ekiti</option>
                    <option value='Enugu'>Enugu</option>
                    <option value='Gombe'>Gombe</option>
                    <option value='Imo'>Imo</option>
                    <option value='Jigawa'>Jigawa</option>
                    <option value='Kaduna'>Kaduna</option>
                    <option value='Kano'>Kano</option>
                    <option value='Katsina'>Katsina</option>
                    <option value='Kebbi'>Kebbi</option>
                    <option value='Kogi'>Kogi</option>
                    <option value='Kwara'>Kwara</option>
                    <option value='Nasarawa'>Nasarawa</option>
                    <option value='Lagos'>Lagos</option>
                    <option value='Niger'>Niger</option>
                    <option value='Ogun'>Ogun</option>
                    <option value='Ondo'>Ondo</option>
                    <option value='Osun'>Osun</option>
                    <option value='Oyo'>Oyo</option>
                    <option value='Plateau'>Plateau</option>
                    <option value='Rivers'>Rivers</option>
                    <option value='Sokoto'>Sokoto</option>
                    <option value='Taraba'>Taraba</option>
                    <option value='Yobe'>Yobe</option>
                    <option value='Zamfara'>Zamfara</option>
                </select>
            </div>
            <div className='form-group'>
                <label for="choiceLocationControlSelect">Choice Location Place</label>
                <select value={choiceLocationPlace} onChange={handleChoiceLocationPlace} className="form-control" id="choiceLocationPlaceControlSelect">
                    <option value=''>Select A Place In Your Choice Location</option>
                    {
                        choiceLocationPlaces.map(place => {
                            return (
                                <option key={place} value={place}>{place}</option>
                            )
                        })
                    }
                </select>
            </div>
            <div className="form-group">
                <label for="budgetControlSelect">Budget</label>
                <select value={budget} onChange={handleBudget} className="form-control" id="budgetControlSelect">
                    <option value=''>Select Budget</option>
                    <option value='₦500,000 - ₦2 million'>₦500,000 - ₦2 million</option>
                    <option value='₦2 million - ₦5 million'>₦2 million - ₦5 million</option>
                    <option value='₦5 million - ₦10 million'>₦5 million - ₦10 million</option>
                    <option value='₦10 million - ₦20 million'>₦10 million - ₦20 million</option>
                    <option value='₦20 million - ₦50 million'>₦20 million - ₦50 million</option>
                    <option value='₦50 million - ₦100 million'>₦50 million - ₦100 million</option>
                    <option value='Above ₦100 million'>Above ₦100 million</option>
                </select>
            </div>
            <button type="submit" className="btn cta-btn add-pref-btn">
                {isLoading ? <LoadingIndicator /> : 'Add Preference'}
            </button>
        </form>
    </div>
  )
}

export default AddPreferenceForm