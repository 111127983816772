import React, {useState} from 'react'
import {
    Link
} from 'react-router-dom'
import Nav from '../components/Nav'
import Footer from '../components/Footer'
import { useAuth } from '../hooks/useAuth'
import Toast from '../components/Toast'
import LoadingIndicator from '../components/LoadingIndicator'
import { Helmet } from 'react-helmet'

function ForgotPassword() {
    const [emailAddress, setEmailAddress] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [successMessage, setSuccessMessage] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)

    const {_sendPasswordResetEmail} = useAuth()

    const handleEmailAddress = event => {
        setEmailAddress(event.target.value)
    }

    const validate = () => {
        if(emailAddress === '') throw new Error("Enter Your Email Address")
        return true
    }

    const handleSendPasswordResetEmail = async event => {
        try {
            event.preventDefault()
            setIsLoading(true)
            if(!validate()) return;
            await _sendPasswordResetEmail(emailAddress)
            setSuccessMessage("Password Reset Email Sent Successfully")
            setIsLoading(false)
        } catch (error) {
            setErrorMessage(error.message)
            setIsLoading(false)
        }
    }
  return (
    <>
        <Helmet>
            <title>Peer2Own -  Forgot Password</title>
        </Helmet>
        <Nav />
        {
            successMessage &&
            <Toast message={successMessage} setSuccess={setSuccessMessage}/>
        }
        {
            errorMessage &&
            <Toast message={errorMessage} setError={setErrorMessage}/>
        }
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-xl-10 col-lg-12 col-md-9">
                    <div className="card o-hidden border-0 shadow-lg my-5">
                        <div className="card-body p-0">
                            <div className="row">
                                <div className="col-lg-6 d-none d-lg-block bg-password-image"></div>
                                <div className="col-lg-6">
                                    <div className="p-5">
                                        <h1 className="h4 mb-2 p2o-form-title">Forgot Your Password?</h1>
                                        <hr className='p2o-form-title-divider'/>
                                        <div className='text-center'> 
                                            <p className="mb-4">We get it, stuff happens. Just enter your email address below
                                                and we'll send you a link to reset your password!</p>
                                        </div>
                                        <form onSubmit={handleSendPasswordResetEmail}>
                                            <div className="form-group">
                                                <input 
                                                    type="email"
                                                    name='email'
                                                    value={emailAddress}
                                                    onChange={handleEmailAddress} 
                                                    className="form-control form-control-user"
                                                    id="inputEmail" 
                                                    aria-describedby="emailHelp"
                                                    placeholder="Enter Your Email Address" 
                                                />
                                            </div>
                                            <button 
                                                type='submit' 
                                                className="btn btn-primary btn-user btn-block cta-btn"
                                            >
                                                {
                                                    isLoading ?
                                                    <LoadingIndicator /> :
                                                    "Reset Passsword"
                                                }
                                            </button>
                                        </form>
                                        <hr />
                                        <div className="text-center">
                                            <Link class="small" to="/signup">Create an Account!</Link>
                                        </div>
                                        <div className="text-center">
                                            <Link className="small" to="/login">Already have an account? Login!</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </>
  )
}

export default ForgotPassword