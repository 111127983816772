import React from 'react'
import Nav from '../components/Nav'

function UnAuthorized() {
  return (
    <div>
        <Nav />
        <br/>
        <div className='container text-center'>
          <div className='jumbotron'>
              <h2>You are not authorized to view this page.</h2>
          </div>
        </div>
    </div>
  )
}

export default UnAuthorized