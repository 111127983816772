import React from 'react'
import Nav from '../components/Nav';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet';
import MyProjects from '../components/MyProjects';

function ViewMyProjects() {
  return (
    <div>
        <Helmet>
            <title>Peer2Own -  My Projects</title>
        </Helmet>
        <Nav />
        <br />
        <div className='container'>
            <h2 className='p2o-page-title'>
              <strong>My Projects</strong>
            </h2>
            <p>
              <h5 className='text-black'>
                These are the projects you have joined in the past.
              </h5>
            </p>
            <MyProjects /> 
        </div>
        <br />
        <Footer />
    </div>
  )
}

export default ViewMyProjects