import React, {useEffect} from 'react'
import NavBar from '../components/Nav'
import Footer from '../components/Footer'
import ProposeLandForm from '../components/ProposeLandForm'
import { useParams, Link, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'

function ProposeLand() {
    const { globalPrefId } = useParams();
    const user = useSelector(state => state.user)

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

  return (
    <div>
        <Helmet>
            <title>Peer2Own -  Sell Your Land</title>
        </Helmet>
        <NavBar />
        <br />
        <div className='container'>
            <div className='row justify-content-center'>
                <div className='col-md-6 p2o-form'>
                    <h2 className='p2o-form-title'>Sell Your Land</h2>
                    <small className='p2o-form-title-caption'>
                        Showcase your piece of land in order to have it used for development of upcoming 
                        projects. You can submit as many properties as you want.
                    </small><br />
                    <small className='text-danger'>
                        Please fill this form with the correct information. Providing false information will lead to rejection of this submission and
                        possible suspension of your Peer2Own account.
                    </small>
                    <hr className='p2o-form-title-divider'/>
                  {/*   {
                        !user.isSubscribed &&
                        <div className='alert alert-danger text-center'>
                            Only Subscribed Members Can Sell Land. 
                            &nbsp;
                            <Link to='/subscribe' className='btn btn-success'>Subscribe</Link>
                        </div>
                    } */}
                    <ProposeLandForm 
                        globalPrefId={globalPrefId}
                    />
                </div>
            </div>
        </div>
        <br />
        <Footer />
    </div>
  )
}

export default ProposeLand