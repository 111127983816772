const { createSlice } =  require("@reduxjs/toolkit")

const userInitialState = {
    uid: null,
    active: false,
    isSubscribed: false,
    role: null,
    loggedIn: false,
    onboarded: false,
    emailAddress: null,
    emailVerified: false,
    phoneNumber: null,
    firstName: null,
    lastName: null,
    gender: null,
    dob: null,
    profileImgUrl: null,
    instagramHandle: null,
    twitterHandle: null,
    referrerId: null
}

const UserSlice = createSlice({
    name: 'user',
    initialState: userInitialState,
    reducers: {
        setUserState(state, action) {
            const newstate = action.payload
            return newstate
        }
    }
})

module.exports = {
    UserSlice,
    userInitialState
}    