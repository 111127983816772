import React, {useState, useEffect} from 'react'
import { useParams, Link } from 'react-router-dom'
import { useOpportunity } from '../hooks/useOpportunity'
import { usePreference } from '../hooks/usePreference';
import Nav from '../components/Nav';
import Footer from '../components/Footer';
import JoinOpportunity from '../components/JoinOpportunityCard';
import LoadingIndicator from '../components/LoadingIndicator'
import { Helmet } from 'react-helmet';
import CustomLightbox from '../components/CustomLightbox';
import { useSelector } from 'react-redux';
import { RWebShare } from 'react-web-share';
import ShareIcon from '../assets/share-icon.svg'

function ViewOpportunities() {
    const user = useSelector(state => state.user)
    const [globalPref, setGlobalPref] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const {globalPrefId} = useParams();
    const {getBuildingType} = useOpportunity();

    const [buildingType, setBuildingType] = useState(null)
    const [openLightbox, setOpenLightbox] = useState(false)
    const [slides, setSlides] = useState([])

    useEffect(() => {
      (async () => {
          const globalPref = await getGlobalPreference(globalPrefId)
          setGlobalPref(globalPref)
          setIsLoading(false)
      })()
    }, [])

    useEffect(() => {
      (async() => {
          if(!globalPref) return;
          const buildingType = await getBuildingType(globalPref.propertyType)
          setBuildingType(buildingType)
      })()
    }, [globalPref])

    const handleOpenLightbox = projectDesignImages => {
        setOpenLightbox(true)
        const images = []
        projectDesignImages.forEach(img => {
            images.push({
                src: img,
                alt: "Building Model Image"
            })
        })
        setSlides(images)
    }

    const {getGlobalPreference} = usePreference()

  return (
    <div>
        <Helmet>
            <title>Peer2Own -  Join Opportunity</title>
        </Helmet>
        <Nav />
        <br />
        <div className='container'>
            <CustomLightbox 
              open={openLightbox} 
              setOpen={setOpenLightbox} 
              slides={slides}
            />
            <h2 className='p2o-page-title'>
              <strong>Join Opportunity</strong>
              &nbsp;
              {
                user.role === 'admin' ?
                <Link to={`/admin/${globalPrefId}/create-project`} className='btn btn-sm btn-danger cta-btn'>
                  Create Project
                </Link> :
                <Link to={`/${globalPrefId}/sell-your-land`} className='btn btn-sm btn-danger cta-btn'>
                  Sell Your Land
                </Link>
              }
            </h2>
            <p>
              <h5 className='text-black'>
                Join the queue of financially-ready peers who would love to start a 
                project with you
              </h5>
            </p>
            <div 
              style={{
                width: '100%', 
                textAlign: 'center',
                fontSize: "16px",
                borderRadius: '50px', 
                margin: '8px', 
                padding: '8px', 
                background: '#C13ABB'
              }}
            >
              <RWebShare
                className="btn btn-primary cta-btn"
                data={{
                  text: `Hey! I came across this opportunity to own a property (${globalPref?.propertyType}) and I thought it would be something you might be interested in.`,
                  url: `https://peer2own.com/join-opportunity/${globalPref?.id}`,
                  title: "Find People To Share Land & Build With You.",
                }}
                onClick={() => console.log("shared successfully!")}
              >
                <span className='text-white'>
                  <img src={ShareIcon} alt="Share Icon" width='30px' />
                  &nbsp;
                  Share this Opportunity With Friends
                </span>
              </RWebShare>
            </div>
            {
              isLoading ?
              <div className='text-center'>
                <LoadingIndicator color='#000' />
              </div> :
              <JoinOpportunity 
                globalPref={globalPref}
                buildingType={buildingType}
                handleOpenLightbox={() => handleOpenLightbox(buildingType.images)}
              />
            }
            
           {/*  {
              opportunities.length > 0 ?
              <Opportunities opportunities={opportunities} /> :
              (
                isLoading ? 
                <div className='text-center'>
                  <LoadingIndicator color='#000'/>
                </div> :
                <div className='h3'>
                  There are no Opportunities for the selected preference.
                </div>
              ) 
            } */}
        </div>
        <br />
        <Footer />
    </div>
  )
}

export default ViewOpportunities