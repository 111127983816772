import React, {useState, useEffect} from 'react'
import Nav from '../components/Nav'
import Footer from '../components/Footer'
import Requests from '../components/Requests'
import { useOpportunity } from '../hooks/useOpportunity'
import LoadingIndicator from '../components/LoadingIndicator'
import RequestsFilter from '../components/RequestsFilter'
import { Helmet } from 'react-helmet'

function ViewRequests() {
    const [requests, setRequests] = useState([])
    const [filterModeOn, setFilterModeOn] = useState(false)
    const [filteredRequests, setFilteredRequests] = useState([])
    const [searchQuery, setSearchQuery] = useState('')
    const [filterValue, setFilterValue] = useState('pending')
    const [isLoading, setIsLoading] = useState(true)
    const {watchAllRequests} = useOpportunity()

    useEffect(() => {
        (async () => {
            await watchAllRequests(setRequests)
            setIsLoading(false)
        })()
    }, [])

    const handleSearch = event => {
        setFilterValue('pending')
        setFilterModeOn(true)
        setSearchQuery(event.target.value)
        const searchQuery = event.target.value
        const searchedRequests = requests.filter(request => {
            if (
                (
                    request.emailAddress && 
                    request.emailAddress.toLowerCase().startsWith(searchQuery.toLowerCase())
                ) ||
                (
                    request.oppId &&
                    request.oppId.toLowerCase().startsWith(searchQuery.toLowerCase())
                )
            ) return true
            return false
        })
        setFilteredRequests(searchedRequests)
        if(searchQuery.length === 0) setFilterModeOn(false)
    }

    const handleFilterRequests = event => {
        setSearchQuery('')
        setFilterValue(event.target.value)
        if(event.target.value === 'pending') {
            const pendingRequests = requests.filter(request => !request.approved && !request.declined)
            setFilterModeOn(true)
            setFilteredRequests(pendingRequests)
        }
        if(event.target.value === 'approved') {
            const approvedRequests = requests.filter(request => request.approved)
            setFilterModeOn(true)
            setFilteredRequests(approvedRequests)
        }
        if(event.target.value === 'declined') {
            const declinedRequests = requests.filter(request => request.declined)
            setFilterModeOn(true)
            setFilteredRequests(declinedRequests)
        }
        if(event.target.value === 'all') setFilterModeOn(false)
    }

  return (
    <div>
        <Helmet>
            <title>Peer2Own -  View Opportunity Join Requests</title>
        </Helmet>
        <Nav />
        <br />
        <div className='container'>
            <h1 className='p2o-page-title'>Requests</h1>
            <RequestsFilter 
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                filterValue={filterValue}
                handleSearch={handleSearch}
                handleFilterRequests={handleFilterRequests}
            />
            <br />
            {
                isLoading ?
                <div className='text-center'>
                    <LoadingIndicator color='#000' />
                </div> :
                <Requests 
                    requests={
                        filterModeOn ?
                        filteredRequests :
                        requests
                    } 
                />
            }
        </div>
        <br />
        <Footer />
    </div>
  )
}

export default ViewRequests