import React, {useState, useEffect} from 'react'
import { useOpportunity } from '../hooks/useOpportunity'
import { usePreference } from '../hooks/usePreference';
import LoadingIndicator from './LoadingIndicator';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Toast from './Toast';

function JoinRequestForm({globalPrefId}) {
    const user = useSelector(state => state.user)
    const [globalPref, setGlobalPref] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [successMessage, setSuccessMessage] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)
    const { sendJoinRequest } = useOpportunity(globalPrefId)
    const {getGlobalPreference} = usePreference()
    const navigate = useNavigate()

    useEffect(() => {
        (async () => {
            const globalPref  = await getGlobalPreference(globalPrefId)
            setGlobalPref(globalPref)
        })()
    })

    const validateRequest = event => {
        //if(!user.isSubscribed) throw new Error("Only Subscribed Users Can Join Opportunities");
        if(!event.target[0]?.files[0]) throw new Error("Please Upload Your Proof-of-Funds");
        return true
    }

    const handleRequest = async event => {
        try {
            event.preventDefault();
            setIsLoading(true)
            if(!validateRequest(event)) return;

            const joinRequest = {
                uid: user.uid,
                globalPrefId: globalPrefId,
                file: event.target[0]?.files[0],
                size: event.target[0]?.files[0].size,
                status: 'pending'
            }
            await sendJoinRequest(setIsLoading, joinRequest)
            setSuccessMessage("Your Request Has Been Sent. We'll contact you once your request is handled.")
        } catch (error) {
            setErrorMessage(error.message)
            setIsLoading(false)
        }
    }

  return (
    <div>
        {
            successMessage &&
            <Toast message={successMessage} setSuccess={setSuccessMessage}/>
        }
        {
            errorMessage &&
            <Toast message={errorMessage} setError={setErrorMessage}/>
        }
        <h5 className='text-black'>
            Please provide a bank statement from the current month that shows you have <br />
            <strong>{globalPref? ` ${globalPref.budget} ` : ` enough money `}</strong>
            to join a project. The bank statement must have the same name as the name on your 
            profile, but please hide your address as that is private information.
        </h5>
        <form type='file' onSubmit={handleRequest} enctype="multipart/form-data">
            <div class="form-group"> 
                <br /><br />
                <label for="propertyTypeControlSelect" className='text-purple'>
                    <strong>Upload Proof-of-Funds Document</strong>
                </label>
                <br />
                <small>
                    Document must be in pdf, jpg or png format and must not be larger than 500kb
                </small>
                <input
                    type="file" 
                    id="proof-of-funds" 
                    name="proof-of-funds" 
                    accept="image/png, image/jpeg, .pdf" 
                />
            </div>
            <button type="submit" class="btn btn-primary p2o-form-btn">
                {isLoading ? <LoadingIndicator /> : 'Send Request'}
            </button>
        </form>
    </div>
  )
}

export default JoinRequestForm