import {db, storage, serverTimestamp, documentId} from '../firebase'
import { 
    doc,
    collection,
    getDoc,
    getDocs,
    addDoc,
    updateDoc,
    deleteDoc,
    query,
    orderBy,
    limit,
    onSnapshot,
    where
} from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL} from 'firebase/storage'
import { useDispatch, useSelector } from "react-redux";
import { useUser } from './useUser';
import { UploadingProgressActions } from '../redux/store';

import { useEmail } from './useEmail';
import { URL } from '../constants';
import { useOpportunity } from './useOpportunity';
import { usePreference } from './usePreference';

export function useProject() {
    const dispatch = useDispatch()
    const {setUploadingProgressState} = UploadingProgressActions

    const {sendEmail} = useEmail()
    const {getUser} = useUser();

    const {getGlobalPreference} = usePreference()
    const {moveUserToEndOfParticipantsQueue} = useOpportunity();

    const createProject = async data => {
        try {
            const projCollectionRef = collection(db, `projects`)
            const projRef = await addDoc(projCollectionRef, data)
            return projRef.id
        } catch (error) {
            throw new Error(error.message)
        }
    }

    
    const getProject = async projId => {
        try {
            const projRef = doc(db, `projects`, projId)
            const projSnap = await getDoc(projRef)
            if(projSnap.exists()) {
                return {...projSnap.data(), id: projRef.id}
            } else {
                return null
            }
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const updateProject = async (projId, data) => {
        try {
            const projRef = doc(db, `projects`, projId);
            await updateDoc(projRef, data)
            return true
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const deleteProject = async projId => {
        try {
            const projRef = doc(db, `projects`, projId);
            await deleteDoc(projRef);
            return true;
        } catch (error) {
            throw new Error(error.message)
        }
    }
    
    const uploadProjectDesignPhotos = async (projId, files) => {
        const proj = await getProject(projId)
        const projectDesignImagesUrls = proj.projectDesignImagesUrls || []
        files.forEach(file => {
            if(file.size > 524288) throw new Error('Image cannot be larger than 512KB')
            const storagePath = 'projects/' + projId + "/projectDesignPhotos/" + file.name
            const storageRef = ref(storage, storagePath);
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on('state_changed',
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes);
                const progressPercentage = progress * 100
                dispatch(setUploadingProgressState({
                    show: true,
                    progress: progressPercentage,
                    message: "Uploading Photos..."
                }))
                switch (snapshot.state) {
                    case 'paused':
                        dispatch(setUploadingProgressState({
                            show: true,
                            progress: progressPercentage,
                            message: "Upload Paused..."
                        }))
                        break;
                    case 'running':
                        dispatch(setUploadingProgressState({
                            show: true,
                            progress: progressPercentage,
                            message: "Uploading Photos..."
                        }))
                        break;
                    default:
                }
            }, 
            (error) => {
                switch (error.code) {
                    case 'storage/unauthorized':
                        // User doesn't have permission to access the object
                        break;
                    case 'storage/canceled':
                        // User canceled the upload
                        break;
    
                    case 'storage/unknown':
                        // Unknown error occurred, inspect error.serverResponse
                        break;
                    default: 
                }
            }, 
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                    projectDesignImagesUrls.push(downloadURL)
                    updateProject(
                        projId, 
                        {
                            projectDesignImagesUrls: projectDesignImagesUrls
                        }
                    )
                });
            }); 
        })
    }

    const countProjectsStarted = async globalPrefId => {
        try {
            const projectsCollection = collection(db, `projects`)
            const q = query(projectsCollection, where("globalPrefId", "==", globalPrefId))
            const snapshot = await getDocs(q);
            const projects = []
            snapshot.forEach(doc => projects.push(doc.data))
            return projects.length
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const watchMyProjects = async (uid, setProjects) => {
        try {
            const q = query(collection(
                db, "projects"), 
                where('participants', 'array-contains-any', [{hasMadePayment: false, uid: uid}, {hasMadePayment: true, uid: uid}])
            );
            const unsub = onSnapshot(q, snapshot => {
                const projects = []
                snapshot.forEach(doc => projects.push({id: doc.id, ...doc.data()}))
                setProjects(projects)
            })
            return unsub
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const watchProjectParticipants = async (projId, setParticipants) => {
        try {
            const proj = await getProject(projId);
            const q = query(
                collection(db, `projects`), 
                where(documentId(), 'in', proj.participants)
            );
            const unsub = onSnapshot(q, snapshot => {
                const participants = []
                snapshot.forEach(doc => participants.push({id: doc.id, ...doc.data()}))
                setParticipants(participants)
            })
            return unsub
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const watchAllProjects = async (setProjects) => {
        try {
            const projCollection = collection(db, `projects`)
            const q = query(projCollection, orderBy("status", "desc"))
            const unsub = onSnapshot(q, async snapshot => {
                const projects = []
                snapshot.forEach(doc => {
                    projects.push({...doc.data(), id: doc.id})
                })
                setProjects(projects)
            });
            return unsub
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const sendInvite = async (uid, globalPrefId, projId) => {
        try {
            const user = await getUser(uid)
            const project = await getProject(projId)
            const globalPref =  await getGlobalPreference(globalPrefId)

            const projectLink = URL + `/project/${projId}`
            const text = ""
            const html = `
            <p>
                Hi ${user.firstName},
            </p>
            <p>
                It's your turn to join a project! We're excited to let you know that we have created
                a project that matches your preferences, and we'd love for you to be a part of it.
                Join a new ${globalPref.propertyType} project!
            </p>
            <p>
                We're looking for financially-ready individuals to join us in making this project 
                a success.
            </p>
            <p>
                If you are ready to join this project, please accept this invitation by 
                clicking on the following link:
            </p>
            <p>${projectLink}</p>
            <p>
                Once you have accepted the invitation, you will be removed from the queue and will 
                be expected to make the payment, before the deadline.
            </p>
            <p>
                Please note that the deadline for payment is [date]. If you are not able to make 
                the payment by the deadline, you will be added to the end of the queue.
            </p>
            <p>
                You can be added to the end of the queue up to 3 times. After that, you will be removed 
                from the queue and would need to Join a new Opportunity to receive updates on new 
                projects.
            </p>    
            
            <p>
                Please make a payment of ${project.estimatedCostPerParticipant} to the following account:
            </p>
            
            <p>
                Bank Name: [Bank Name]
                Account Name: [Peer2Own Account Name]
                Account Number: [Peer2Own Account Number]
            </p>
            
            <p>
                Once you have made the payment, please send us a confirmation email at 
                [email address]. We will then add you to the list of participants for the 
                take-off meeting.
            </p>    
            <p>
                The take-off meeting will be held on [date] at [time] in [location]. 
                This meeting is an opportunity for us to discuss the project in more detail 
                and to answer any questions you may have.
            </p>
            <p>
                We look forward to working with you on this exciting project!
            </p>
            <p>
                Sincerely,
                Peer2Own Team
            </p>
            `
          /*   const res = await sendEmail(
                "Peer2Own",
                "hello@peer2own.com",
                user.emailAddress,
                "Invitation to Join A New Project",
                text,
                html
            ) */

            await updateProject(
                projId,
                {
                    invites: [...project.invites, {uid, sent: true, accepted: false}]
                }
            )
            await moveUserToEndOfParticipantsQueue(uid, globalPref)
            return true;
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const checkIfInviteHasBeenSent = async (uid, projId) => {
        try {
            const project = await getProject(projId)
            const filter = project.invites.filter(invite => invite.uid === uid && invite.sent)
            if(filter.length > 0) return true
            return false
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const markInviteAsAccepted = async (uid, projId) => {
        try {
            const project = await getProject(projId)
            const filter = project.invites.filter(
                invite => invite.uid === uid && invite.sent
            ) 
            const findIndex = project.invites.findIndex(
                invite => invite.uid === uid && invite.sent
            )
            const userInvite = {...filter[0], accepted: true}
            const newInvites = [
                ...project.invites.slice(0, findIndex),
                userInvite,
                ...project.invites.slice(findIndex + 1)
            ]
            await updateProject(
                projId,
                {invites: newInvites}
            )
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const joinProject = async (uid, projId) => {
        try {
            const project = await getProject(projId)
            const participants = project.participants
            const newParticipants = [...participants, {uid, hasMadePayment: false}]
            await updateProject(
                projId,
                {participants: newParticipants}
            )
            return true
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const checkIfUserIsAProjectParticipant = (uid, projectParticipants) => {
        try {
            const filter = projectParticipants.filter(participant => participant.uid === uid);
            if(filter.length > 0) return true;
            return false;
        } catch (error) {
            throw new Error(error.message)
        }
    }

    return {
        createProject,
        updateProject,
        deleteProject,
        uploadProjectDesignPhotos,
        watchProjectParticipants,
        watchMyProjects,
        watchAllProjects,
        sendInvite,
        checkIfInviteHasBeenSent,
        getProject,
        joinProject,
        markInviteAsAccepted,
        checkIfUserIsAProjectParticipant,
        countProjectsStarted
    }

}
