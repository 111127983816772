import React, {useEffect} from 'react'
import NavBar from '../components/Nav'
import Footer from '../components/Footer'
import {
    contactPhoneNumber, 
    contactEmailAddress, 
    michandraEmailAddress
} from '../constants'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'

function Terms() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
      }, [pathname]);
      
  return (
    <>
        <Helmet>
            <title>Peer2Own -  Terms of Engagement</title>
        </Helmet>
        <NavBar />
        <br />
        <div className='container text-black'>
            <h2 className='p2o-page-title'>Terms of Engagement</h2>
            <p>
                This App was created to provide Real Estate investment solutions where Investors 
                with low budgets can connect to enable them buy authentic land and build properties 
                of any type or size in developed and decent locations of their choice. 
                It is not to be used for Real Estate transactions. All Real Estate transactions are 
                executed offline in our Office(s) holding physical meetings as provided in our 
                contact information, or virtual meetings to meet the convenience of Investors.
            </p> 
            <p>
                <mark>
                    REGISTRATION/MATCHING FEES PAID ONLINE COVERS ONLY GETTING MATCHED OR PROPOSING 
                    YOUR LAND; AND DOES NOT COVER REAL ESTATE TRANSACTIONS WHICH WILL BE CARRIED OUT 
                    AND EXECUTED OFFLINE. DO NOT PAY COMMISSIONS TO ANYONE ONLINE. Kindly report any 
                    demand, or request of such nature by calling <strong>{contactPhoneNumber}</strong> or send an 
                    email to: via <strong>{contactEmailAddress}</strong>, or <strong>{michandraEmailAddress}</strong>
                </mark>
             </p>
            <p>
                To establish a business relationship with you there are certain processes we need 
                to carry out together and these include amongst others:
                <ol>
                    <li>Getting you matched [Every Client].</li>
                    <li>Entering into a Contract with Us [Every Client].</li>
                    <li>Investigating the Title [If you are proposing your land].</li>
                    <li>Conducting a site visitation [If you are proposing your land].</li>
                    <li>Evaluating the Land [If you are proposing your land].</li>
                </ol>
            </p>
            <h3>Disclaimer</h3>
            <ul>
                <li>
                    <strong>No Attorney Client Relationship.</strong> This App is made available by the publisher for 
                    informative, networking, educational, and trading purposes only as well as to give you
                    general information and a general understanding of the Nigerian Real Estate Industry;
                    not to provide specific legal advice. By using this App you understand that there is no 
                    attorney client relationship between you and the Blog/App publisher. The Blog/App 
                    should not be used as a substitute for competent legal advice from a licensed 
                    professional attorney in your state. NOTHING CONTAINED ANYWHERE HEREIN IS OFFERED AS OR 
                    CONSTITUTES LEGAL ADVICE. No representations are made about the up-to-date or accuracy 
                    of the information contained in any page on this site or referred to from this site 
                    respectively, and, there is no promise or guarantee that anything contained herein is 
                    correct, complete or up-to-date. Users of the App should be aware that articles 
                    published on this site were written prior to posting on this site and the information 
                    in them may not be current. NO ONE SHOULD RELY ON THIS SITE, ARTICLES ON OR REFERRED TO 
                    FROM THIS SITE, OR INFORMATION CONTAINED IN ANY OF THE LINKS ON THIS SITE WITHOUT FIRST 
                    EXPRESSLY ENQUIRING AND RECEIVING CONFIRMATION OF ACCURACY OF INFORMATION FROM THE 
                    COMPANY, OR SEEKING APPROPRIATE PROFESSIONAL ADVICE.
                </li>
                <li>
                    <strong>Links to Other Blogs/Apps.</strong> This site may contain links to other Internet sites 
                    and/or materials. Such links are not endorsements of any products or services 
                    in such sites.
                </li>
                <li>
                    <strong>Limitation of Damages.</strong> The publisher expressly disclaims all liability for any 
                    viruses or other contamination of your computer system or other device used to 
                    access this App as a result of your use of this App, and expressly disclaims 
                    all liability for actions taken or not taken based on any or all of the 
                    contents of this App. THE APP IS PROVIDED “AS IS”, WITHOUT WARRANTY OF ANY 
                    KIND, EITHER EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION WARRANTIES OF 
                    MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, SECURITY, ACCURACY 
                    AND NON-INFRINGEMENT. WITHOUT LIMITING THE FOREGOING, THE PUBLISHER MAKES NO 
                    WARRANTY OR REPRESENTATION, EITHER EXPRESS OR IMPLIED, THAT ACCESS TO OR 
                    OPERATION OF THE APP WILL BE UNINTERRUPTED OR ERROR-FREE.
                    USE OF THE APP IS AT YOUR OWN RISK. YOU ASSUME FULL RESPONSIBILITY AND RISK OF 
                    LOSS RESULTING FROM YOUR DOWNLOADING AND/OR USE OF FILES, INFORMATION, OTHER 
                    COMMUNICATIONS, CONTENT OR OTHER MATERIAL (INCLUDING, WITHOUT LIMITATION, 
                    SOFTWARE) ACCESSED THROUGH OR OBTAINED BY MEANS OF THE APP. THE PUBLISHER 
                    ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ANY ERRORS OR OMISSIONS ON THIS SITE.
                </li>
                <li>
                    <strong>Modification of Terms and Conditions of Use.</strong> The Publisher reserves the right to 
                    revise these terms at any time and will update this post accordingly. 
                    Your continued use is your agreement to the revised terms. 
                    Please read this page regularly, as it is likely to change from time to time.
                </li>
            </ul>
        </div>
        <br />
        <Footer />
    </>
  )
}

export default Terms