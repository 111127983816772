const { createSlice } = require("@reduxjs/toolkit")

const initialState = []

const MyPreferencesSlice = createSlice({
    name: 'myPreferences',
    initialState,
    reducers: {
        setMyPreferencesState(state, action) {
            const newstate = action.payload
            return newstate
        }
    }
})

module.exports = {
    MyPreferencesSlice
}    