import React, {useState} from 'react'
import { useUser } from '../hooks/useUser'
import {useSelector} from 'react-redux'
import { CustomDatePicker } from './CustomDatePicker';
import LoadingIndicator from './LoadingIndicator';
import Toast from './Toast';
import {CopyToClipboard} from 'react-copy-to-clipboard';


function EditProfileForm() {
    const user = useSelector(state => state.user);
    const [firstName, setFirstName] = useState(user?.firstName || '')
    const [lastName, setLastName] = useState(user?.lastName || '')
    const [phoneNumber, setPhoneNumber] = useState(user?.phoneNumber || '')
    const [gender, setGender] = useState(user?.gender || '')
    const [dob, setDob] = useState(
        user?.dob ?
        new Date((user?.dob?.seconds * 1000) + (user?.dob?.nanoseconds * 1000000)) : 
        new Date()
    )
    const [instagramHandle, setInstagramHandle] = useState(user?.instagramHandle || '')
    const [xHandle, setXHandle] = useState(user?.xHandle || '')
    
    const [isLoading, setIsLoading] = useState(false)
    const [successMessage, setSuccessMessage] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)


    const { updateUserState } = useUser()

    const handleFirstName = event => {
        setFirstName(event.target.value)
    }

    const handleLastName = event => {
        setLastName(event.target.value)
    }

    const handlePhoneNumber = event => {
        setPhoneNumber(event.target.value)
    }

    const handleGender = event => {
        setGender(event.target.value)
    }

    const handleInstagramHandle = event => {
        setInstagramHandle(event.target.value)
    }

    const handleXHandle = event => {
        setXHandle(event.target.value)
    }

    const handleReferralLinkCopied = () => {
        setSuccessMessage("Referral Link Copied")
    }

    const shortenText = (text, maxLength) => {
        return text.length > maxLength ? text.slice(0, maxLength - 3) + "..." : text;
    }

    const validateEditProfile = () => {
        if(firstName === '') throw new Error("Enter Your First Name");
        if(lastName === '') throw new Error("Enter Your Last Name");
        if(phoneNumber === '') throw new Error("Enter Your Phone Number");
        if(gender === '') throw new Error("Select Your Gender");
        if(dob === '') throw new Error("Select Your Date of Birth");

        return true
    }

    const handleEditProfile = async event => {
        try {
            event.preventDefault();
            setIsLoading(true)
            const profile = {
                firstName: firstName,
                lastName: lastName,
                phoneNumber: phoneNumber,
                gender: gender,
                dob: dob,
                instagramHandle: instagramHandle,
                xHandle: xHandle
            }
    
            if(!validateEditProfile()) return
            await updateUserState(user.uid, profile)
            setSuccessMessage("Profile Edited Successfully")
            setIsLoading(false)
        } catch (error) {
            setErrorMessage(error.message)
            setIsLoading(false)
        }
    }

  return (
    <div>
        {
            successMessage &&
            <Toast message={successMessage} setSuccess={setSuccessMessage}/>
        }
        {
            errorMessage &&
            <Toast message={errorMessage} setError={setErrorMessage}/>
        }
        <form onSubmit={handleEditProfile}>
            <div className='form-group'>
                <label>Referral Link</label>
                <div style={{flex: 1, wordWrap: 'break-word'}}>
                    {`${'https://peer2own.com/' + user.uid}`}
                </div><br />
                <div><strong>Refer & Earn 25% Of The Agency Commission</strong></div>
                <CopyToClipboard 
                    text={`https://peer2own.com/${user.uid}`}
                    onCopy={handleReferralLinkCopied}
                >
                    <button className='btn cta-btn-transparent'>Copy Link</button>
                </CopyToClipboard>
            </div>
            <div class="form-group">
                <label for="propertyTypeControlSelect">First Name</label>
                <input 
                    id='firstNameInput'
                    name='firstName'
                    value={firstName}
                    onChange={handleFirstName}
                    className='form-control p2o-form-field'
                />
            </div>
            <div class="form-group">
                <label for="lastNameInput">Last Name</label>
                <input 
                    id='lastNameInput'
                    name='lastName'
                    value={lastName}
                    onChange={handleLastName}
                    className='form-control p2o-form-field'
                />
            </div>
            <div class="form-group">
                <label for="phoneNumberInut">Phone Number</label>
                <input 
                    id='phoneNumberInput'
                    name='phoneNumber'
                    value={phoneNumber}
                    onChange={handlePhoneNumber}
                    className='form-control p2o-form-field'
                />
            </div>
            <div class="form-group">
                <label for="genderControlSelect">Gender</label>
                <select value={gender} onChange={handleGender} class="form-control" id="genderControlSelect">
                    <option value=''>Select Gender</option>
                    <option value='male'>Male</option>
                    <option value='female'>Female</option>
                </select>
            </div>
            <div class="form-group">
                <label for="dobControlSelect">Date of Birth</label>
                <div>
                    <CustomDatePicker
                        date={dob}
                        setDate={setDob}
                    />
                </div>
            </div>
            <div class="form-group">
                <label for="instagramHandleInut">Instagram Handle</label>
                <input 
                    id='instagramHandleInput'
                    name='instagramHandle'
                    value={instagramHandle}
                    onChange={handleInstagramHandle}
                    className='form-control p2o-form-field'
                />
            </div>
            <div class="form-group">
                <label for="xHandleInut">X Handle</label>
                <input 
                    id='xHandleInput'
                    name='xHandle'
                    value={xHandle}
                    onChange={handleXHandle}
                    className='form-control p2o-form-field'
                />
            </div>
            <button type="submit" class="btn btn-primary p2o-form-btn">
                {isLoading ? <LoadingIndicator /> : 'Submit'}
            </button>
        </form>
    </div>
  )
}

export default EditProfileForm