import React, {useState} from 'react'
import LandProposal from './LandProposal'
import CustomLightbox from './CustomLightbox'
import { useSelector } from 'react-redux'

function LandProposals({ landProposals }) {
    const user = useSelector(state => state.user)

    const [openLightbox, setOpenLightbox] = useState(false)
    const [slides, setSlides] = useState([])

    const handleOpenLightbox = landPhotos => {
        setOpenLightbox(true)
        const images = []
        landPhotos.forEach(img => {
            images.push({
                src: img,
                alt: "Land Photos"
            })
        })
        setSlides(images)
    }

  return (
    <div className='table-responsive'>
        <CustomLightbox 
            open={openLightbox} 
            setOpen={setOpenLightbox} 
            slides={slides}
        />
        <table className="table table-sm table-striped text-center p2o-table">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Land Location</th>
                    <th scope="col">Land Size</th>
                    <th scope="col">Land Cost</th>
                    <th scope="col">Global Pref ID</th>
                    <th scope="col">Photos</th>
                    {
                        user.role === 'admin' &&
                        <>
                            <th scope="col">&nbsp;</th>
                            <th scope="col">&nbsp;</th>
                        </>
                    }
                    <th scope="col">&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                {
                    landProposals.length === 0 ? 
                    <div className='text-center'>No Land Proposals</div> :
                    landProposals.map((landProposal, index) => <LandProposal key={index} sn={index + 1} landProposal={landProposal} handleOpenLightbox={() => handleOpenLightbox(landProposal.landPhotos)} />)
                }
            </tbody>
        </table>
    </div>
  )
}

export default LandProposals