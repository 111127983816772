import React, {useState, useEffect} from 'react'
import Nav from '../components/Nav'
import Footer from '../components/Footer'
import LandProposals from '../components/LandProposals'
import { useLandProposal } from '../hooks/useLandProposal'
import LoadingIndicator from '../components/LoadingIndicator'
import LandProposalsFilter from '../components/LandProposalsFilter'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'

function ViewMyLandProposals() {
    const user = useSelector(state => state.user)
    const [landProposals, setLandProposals] = useState([])
    const [filterModeOn, setFilterModeOn] = useState(false)
    const [filteredLandProposals, setFilteredLandProposals] = useState([])
    const [searchQuery, setSearchQuery] = useState('')
    const [filterValue, setFilterValue] = useState('pending')
    const [isLoading, setIsLoading] = useState(true)
    const {watchLandsForUser} = useLandProposal()

    useEffect(() => {
        let unsub;
        (async () => {
            unsub = await watchLandsForUser(user.uid, setLandProposals)
            setIsLoading(false)
        })()

        return unsub;
    }, [])

    const handleSearch = event => {
        setFilterValue('pending')
        setFilterModeOn(true)
        setSearchQuery(event.target.value)
        const searchQuery = event.target.value
        const searchedLandProposals = landProposals.filter(landProposal => {
            if (
                (
                    landProposal.landLocation &&
                    landProposal.landLocation.toLowerCase().startsWith(searchQuery.toLowerCase())
                ) ||
                (
                    landProposal.landSize &&
                    landProposal.landSize.toLowerCase().startsWith(searchQuery.toLowerCase())
                ) ||
                (
                    landProposal.landCost && 
                    landProposal.landCost.toLowerCase().startsWith(searchQuery.toLowerCase())
                ) ||
                (
                    landProposal.globalPrefId &&
                    landProposal.globalPrefId.toLowerCase().startsWith(searchQuery.toLowerCase())
                ) ||
                (
                    landProposal.oppId &&
                    landProposal.oppId.toLowerCase().startsWith(searchQuery.toLowerCase())
                ) 
                
            ) return true
            return false
        })
        setFilteredLandProposals(searchedLandProposals)
        if(searchQuery.length === 0) setFilterModeOn(false)
    }

    const handleFilterLandProposals = event => {
        setSearchQuery('')
        setFilterValue(event.target.value)
        if(event.target.value === 'pending') {
            const pendingLandProposals = landProposals.filter(landProposal => !landProposal.accepted && !landProposal.ignored)
            setFilterModeOn(true)
            setFilteredLandProposals(pendingLandProposals)
        }
        if(event.target.value === 'accepted') {
            const acceptedLandProposals = landProposals.filter(landProposal => landProposal.accepted)
            setFilterModeOn(true)
            setFilteredLandProposals(acceptedLandProposals)
        }
        if(event.target.value === 'ignored') {
            const ignoredLandProposals = landProposals.filter(landProposal => landProposal.ignored)
            setFilterModeOn(true)
            setFilteredLandProposals(ignoredLandProposals)
        }
        if(event.target.value === 'all') setFilterModeOn(false)
    }

  return (
    <div>
        <Helmet>
            <title>Peer2Own -  View My Land Proposals</title>
        </Helmet>
        <Nav />
        <br />
        <div className='container'>
            <h1 className='p2o-page-title'>My Land Proposals</h1>
            <LandProposalsFilter 
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                filterValue={filterValue}
                handleSearch={handleSearch}
                handleFilterLandProposals={handleFilterLandProposals}
            />
            <br />
            {
                isLoading ?
                <div className='text-center'>
                    <LoadingIndicator color='#000' />
                </div> :
                <LandProposals 
                    landProposals={
                        filterModeOn ?
                        filteredLandProposals :
                        landProposals
                    } 
                />
            }
        </div>
        <br />
        <Footer />
    </div>
  )
}

export default ViewMyLandProposals