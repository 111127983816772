import React from 'react'
import NavBar from '../components/Nav'

function ManageSubscription() {
  return (
    <div>
        <NavBar />
    </div>
  )
}

export default ManageSubscription