import axios from "axios";
import { API_URL } from "../constants";

export function useEmail() {

    const sendEmail = async ({fromName, fromEmail, toEmail, subject, text, html}) => {
        try {
            const {data} = await axios.post(API_URL + '/send-email', {
                fromName: fromName,
                fromEmail: fromEmail,
                toEmail: toEmail,
                subject: subject,
                text: text,
                html: html
            }, {
                headers: {
                    'Content-Type': 'text/plain'
                }
            })
            return data;
        } catch (error) {
            throw new Error(error.message)
        }
    }

    return {
        sendEmail
    }
}