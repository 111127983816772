import React, {useState, useEffect} from 'react'
import ProposedLandForOpp from './ProposedLandForOpp'
import { useLandProposal } from '../hooks/useLandProposal'
import LoadingIndicator from './LoadingIndicator'
import CustomLightbox from './CustomLightbox'

function ProposedLandsForOpp({globalPrefId, oppId}) {
    const [proposedLandsForOpp, setProposedLandsForOpp] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [openLightbox, setOpenLightbox] = useState(false)
    const [slides, setSlides] = useState([])

    const {watchLandProposalsForOpp} = useLandProposal()

    const handleOpenLightbox = landPhotos => {
        setOpenLightbox(true)
        const images = []
        landPhotos.forEach(img => {
            images.push({
                src: img,
                alt: "Land Photos"
            })
        })
        setSlides(images)
    }

    useEffect(() => {
        let unsub;
        const handleGetLandProposalsForOpp = async () => {
            unsub = await watchLandProposalsForOpp(globalPrefId, oppId, setProposedLandsForOpp)
            setIsLoading(false)
        }

        handleGetLandProposalsForOpp()
        return unsub;
    }, [])

  return ( 
    <div>
        {
            isLoading ?
            <div className='text-center'>
                <LoadingIndicator color='#000' />
            </div> :
            <div className='row'>
                 <CustomLightbox 
                    open={openLightbox} 
                    setOpen={setOpenLightbox} 
                    slides={slides}
                />
                {
                    proposedLandsForOpp.map(proposedLand => <ProposedLandForOpp key={proposedLand.id} proposedLand={proposedLand} handleOpenLightbox={() => handleOpenLightbox(proposedLand.landPhotos)} />)
                }
            </div>
            
        }
    </div>
  )
}

export default ProposedLandsForOpp