import React, {useState, useEffect} from 'react'
import Toast from './Toast'
import LoadingIndicator from './LoadingIndicator'
import { useProject } from '../hooks/useProject'
import { useNavigate } from 'react-router-dom'

function CreateProjectForm({globalPrefId}) {
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [numOfParticipants, setNumOfParticipants] = useState('')
  const [estimatedCostPerParticipant, setEstimatedCostPerParticipant] = useState('')
  const [successMessage, setSuccessMessage] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const {createProject} = useProject()

  const navigate = useNavigate();

  const validateForm = () => {
    if(title === '') throw new Error("Enter Project Title")
    if(description === '') throw new Error("Enter Project Description")
    if(numOfParticipants === '') throw new Error("Enter Number of Participants Required")
    if(estimatedCostPerParticipant === '') throw new Error("Enter Estimated Cost Per Participant")

    return true;
  }

  const handleSubmit = async event => {
    try {
      event.preventDefault()
      setIsLoading(true)

      if(!validateForm()) return;

      const project = {
        title: title,
        description: description,
        numOfParticipantsRequired: numOfParticipants,
        estimatedCostPerParticipant: estimatedCostPerParticipant,
        globalPrefId: globalPrefId,
        status: 'open',
        invites: [],
        participants: []
      }

      const confirmed = window.confirm("Confirm that you want to create this project.")
      if(confirmed){
        await createProject(project)
        setSuccessMessage("Project Created Successfully")
        setIsLoading(false)
        navigate("/admin/all-projects")
      }
    } catch (error) {
        setErrorMessage(error.message)
        setIsLoading(false)
    }
  }

  return (
    <div>
        {
          successMessage &&
          <Toast message={successMessage} setSuccess={setSuccessMessage}/>
        }
        {
          errorMessage &&
          <Toast message={errorMessage} setError={setErrorMessage}/>
        }
       <form onSubmit={handleSubmit}>
            <div className="form-group">
                <label for="titleControlSelect">Title</label>
                <input 
                  className='form-control p2o-form-field' 
                  value={title}
                  onChange={event => setTitle(event.target.value)} 
                />
            </div>
            <div className="form-group">
                <label for="descriptionControlSelect">Description</label>
                <textarea
                  className='form-control p2o-form-field'
                  value={description}
                  onChange={event => setDescription(event.target.value)} 
                />
            </div>
            <div className="form-group">
                <label for="numOfParticipantsControlSelect">
                  Number of Participants Required
                </label>
                <input 
                  className='form-control p2o-form-field' 
                  value={numOfParticipants} 
                  onChange={event => setNumOfParticipants(event.target.value)}
                />
            </div>
            <div className="form-group">
                <label for="estimatedCostPerParticipantControlSelect">
                  Estimated Contribution Per Participant
                </label>
                <input 
                  className='form-control p2o-form-field' 
                  value={estimatedCostPerParticipant} 
                  onChange={event => setEstimatedCostPerParticipant(event.target.value)}
                />
            </div>
            <button type='submit' className='btn btn-primary p2o-form-btn'>
                {isLoading ? <LoadingIndicator /> : 'Submit'}
            </button>
        </form>
    </div>
  )
}

export default CreateProjectForm