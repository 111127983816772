import React, {useEffect} from 'react'
import NavBar from '../components/Nav'
import Footer from '../components/Footer'
import {
    contactPhoneNumber, 
    contactEmailAddress, 
    michandraEmailAddress
} from '../constants'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'

function Privacy() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
      }, [pathname]);
      
  return (
    <>
        <Helmet>
            <title>Peer2Own -  Privacy Policy</title>
        </Helmet>
        <NavBar />
        <br />
        <div className='container text-black'>
            <h2 className='p2o-page-title'>Privacy Policy</h2>
            <p>
                This privacy notice discloses the privacy practices for <strong>www.peer2own.com</strong> and 
                www.michandra.com/peer2own. This privacy notice applies solely to information 
                collected by this Mobile and Web App/Site hereinafter called ‘App’.
            </p> 
            <p>
                It will notify you of the following:
            </p>
                <ul>
                    <li>
                        What personally identifiable information is collected from you through the 
                        App, how it is used and with whom it may be shared.
                    </li>
                    <li>
                        What choices are available to you regarding the use of your data.
                    </li>
                    <li>
                        The security procedures in place to protect the misuse of your information.
                    </li>
                    <li>
                        How you can correct any inaccuracies in the information.
                    </li>
                </ul>
                <h3>
                    Information Collection, Use, and Sharing
                </h3>
                <p>
                    We are the sole owners of the information collected on this site. 
                    We only have access to/collect information that you voluntarily give us via 
                    email or other direct contact from you. We will not sell or rent this 
                    information to anyone.
                </p>
                <p>
                    We will use your information to respond to you, regarding the reason you 
                    contacted us. We will not share your information with any third party outside 
                    of our organization, other than as necessary to fulfill your request, e.g. to 
                    connect you with peers, professionals, to ship an order; or as may be directed
                    by the Owner.
                </p>
                <p>
                    Unless you ask us not to, we may contact you via email in the future to tell 
                    you about specials, new products or services, or changes to this 
                    privacy policy.
                </p>
                <h3>
                    Your Access to and Control over Information
                </h3>
                <p>
                    You may opt out of any future contacts from us at any time. 
                    You can do the following at any time by contacting us via the email address or
                    phone number given on our App:
                </p>
                <ul>
                    <li>See what data we have about you, if any.</li>
                    <li>Change/correct any data we have about you.</li>
                    <li>Have us delete any data we have about you.</li>
                    <li>Express any concern you have about our use of your data.</li>
                </ul>
                <h3>Security</h3>
                <p>
                    We take precautions to protect your information. When you submit sensitive 
                    information via the website, your information is protected both online and 
                    offline. The computers/servers in which we store personally identifiable 
                    information are kept in a secure environment.
                </p>
                <p>
                    If you feel that we are not abiding by this privacy policy, you should contact 
                    us immediately via telephone at <strong>{contactPhoneNumber}</strong> or 
                    via <strong>{contactEmailAddress}</strong>, or <strong>{michandraEmailAddress}</strong>
                </p>
                <p>
                    From time-to-time our site requests information via surveys or contests. 
                    Participation in these surveys or contests is completely voluntary and you may 
                    choose whether or not to participate and therefore disclose this information. 
                    Information requested may include contact information 
                    (such as name and shipping address), and demographic information 
                    (such as zip code, age level). Contact information will be used to notify the 
                    winners and award prizes. Survey information will be used for purposes of 
                    monitoring or improving the use and satisfaction of this site.
                </p>
        </div>
        <br />
        <Footer />
    </>
  )
}

export default Privacy