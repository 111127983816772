import React, {useState, useEffect} from 'react'
import { useUser } from '../hooks/useUser'
import { usePreference } from '../hooks/usePreference';

function User({
    sn, 
    user, 
    setShowUserPreferences, 
    setSelectedUserPreferences,
    setSelectedUser
}) {
    const [userPreferences, setUserPreferences] = useState([]);

    const dateDisplayOptions = {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    };

    const timeJoined = user.timeJoined.toDate().toLocaleDateString('en-US', dateDisplayOptions)
    const {
      activateUser, 
      deactivateUser, 
      subscribeUser, 
      unsubscribeUser
    } = useUser()

    const { getUserPreferences } = usePreference()

    useEffect(() => {
      (async () => {
        const userPreferences = await getUserPreferences(user.id)
        setUserPreferences(userPreferences)
      })()
    }, [])

    const handleShowUserPreferences = (e, user, userPreferences) => {
      e.preventDefault()
      setSelectedUserPreferences(userPreferences)
      setSelectedUser(user)
      setShowUserPreferences(true)
      window.scrollTo(0, 0);
    }

    const handleActivateUser = async () => {
      const confirm = window.confirm("Are you sure you want to activate this user's account?")
      if(!confirm) return;
      await activateUser(user.id)
    }

    const handleDeactivateUser = async () => {
      const confirm = window.confirm("Are you sure you want to deactivate this user's account?")
      if(!confirm) return;
      await deactivateUser(user.id)
    }

    const handleSubscribeUser = async () => {
      const confirm = window.confirm("Are you sure you want to subscribe this user?")
      if(!confirm) return;
      await subscribeUser(user.id);
    }

    const handleUnsubscribeUser = async () => {
      const confirm = window.confirm("Are you sure you want to unsubscribe this user?")
      if(!confirm) return;
      await unsubscribeUser(user.id);
    }
  return (
    <tr className='p2o-table-row'>
      <th scope="row">{sn}</th>
      <td>{user?.emailAddress}</td>
      <td>{user?.emailVerified ? 'YES' : 'NO'}</td>
      <td>{user?.firstName}</td>
      <td>{user?.lastName}</td>
      <td>{user?.phoneNumber}</td>
      <td>
        <a href="/" onClick={(e) => handleShowUserPreferences(e, user, userPreferences)}>
          {userPreferences.length}
        </a>
      </td>
      <td>{timeJoined}</td>
      <td>
        {
          user.active ?
          <button onClick={handleDeactivateUser} className='btn btn-primary cta-btn btn-sm'>Deactivate</button> :
          <button onClick={handleActivateUser} className='btn btn-primary cta-btn btn-sm'>Activate</button>
        }
        <br /><br />
        {
          user.isSubscribed ?
          <button onClick={handleUnsubscribeUser} className='btn btn-primary p2o-gold-btn btn-sm'>Unsubscribe</button> :
          <button onClick={handleSubscribeUser} className='btn btn-primary p2o-gold-btn btn-sm'>Subscribe</button>
        }   
      </td>
    </tr>
  )
}

export default User