import React from 'react'
import GlobalPref from './GlobalPref'

function AllGlobalPrefs({ prefs }) {
  return (
    <div className='table-responsive'>
        <table className="table table-sm table-striped text-center p2o-table">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Budget</th>
                    <th scope="col">Choice Location</th>
                    <th scope="col">Property Type</th>
                    <th scope="col">Property Use</th>
                    {/* <th scope='col'>Occurences</th> */}
                    <th scope='col'>&nbsp;</th>
                    <th scope='col'>&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                {
                    prefs.length === 0 ? 
                    <div className='text-center'>No Preferences</div> :
                    prefs.map((pref, index) => <GlobalPref key={index} sn={index + 1} pref={pref} />)
                }
            </tbody>
        </table>
    </div>
  )
}

export default AllGlobalPrefs