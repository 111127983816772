import React from 'react'
import User from './User'
import UserPreferences from './UserPreferences'

function AllUsers({ 
    users,
    showUserPreferences,
    setShowUserPreferences,
    selectedUser,
    setSelectedUser,
    selectedUserPreferences,
    setSelectedUserPreferences
}) {

  return (
    showUserPreferences ?
    <UserPreferences 
        user={selectedUser}
        userPreferences={selectedUserPreferences}
        setShowUserPreferences={setShowUserPreferences}
    /> :
    <div className='table-responsive'>
        <table className="table table-sm table-striped text-center p2o-table">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Email Address</th>
                    <th scope="col">Email Verified</th>
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">Phone Number</th>
                    <th scope="col">Preferences Count</th>
                    <th scope="col">Date Joined</th>
                    <th scope="col">&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                {
                    users.length === 0 ? 
                    <div className='text-center'>No Users</div> :
                    users.map((user, index) => {
                        return <User 
                                    key={index} 
                                    sn={index + 1} 
                                    user={user}
                                    setShowUserPreferences={setShowUserPreferences}
                                    setSelectedUserPreferences={setSelectedUserPreferences}
                                    setSelectedUser={setSelectedUser}
                                />
                    })
                }
            </tbody>
        </table>
    </div>
  )
}

export default AllUsers