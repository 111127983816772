import React, {useState, useEffect} from 'react'
import { usePreference } from '../hooks/usePreference'
import { useOpportunity } from '../hooks/useOpportunity'
import { Link } from 'react-router-dom'

function GlobalPref({sn, pref}) {
    const [openOpportunitiesCount, setOpenOpportunitiesCount] = useState([])
    const {countOpenOpportunities} = useOpportunity()

    useEffect(() => {
      (async () => {
        setOpenOpportunitiesCount(await countOpenOpportunities(pref?.id))
      })()
     
    }, [])
  return (
    <tr className='p2o-table-row'>
      <th scope="row">{sn}</th>
      <td>{pref?.budget}</td>
      <td>{pref?.choiceLocation}</td>
      <td>{pref?.propertyType}</td>
      <td>{pref?.propertyUse}</td>
     {/*  <td>
        <span className='badge badge-primary occurences-badge'>{pref?.occurences}</span>
      </td> */}
      <td>
        <Link to={`/join-opportunity/${pref?.id}`} className='btn btn-sm btn-primary cta-btn'>
            Join this Opportunity
        </Link>
      </td>
      <td>
        <Link to={`/${pref?.id}/sell-your-land`} className='btn btn-sm btn-danger p2o-gold-btn'>
            Sell Your Land
        </Link>
      </td>
    </tr>
  )
}

export default GlobalPref