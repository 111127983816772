import React from 'react'
import MyPreference from './MyPreference'

function MyPreferences({myPreferences}) {
  const reversedPrefs = [...myPreferences].reverse()
  return (
    <div className='row'>
      {
        reversedPrefs.length > 0 ?
        reversedPrefs.map((myPref, index) => <MyPreference key={index} pref = {myPref} index={index} />) :
        (
          <div className='h3 text-center'>
            You have not added any Preferences.
          </div>
        )
      }
    </div>
  )
}

export default MyPreferences