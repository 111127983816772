import React from 'react'
import Nav from '../components/Nav'
import Footer from '../components/Footer'
import { Link, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useUser } from '../hooks/useUser'

function Welcome() {
  const { referrerId } = useParams()
  const {storeReferrerIdLocally} = useUser()

  if(referrerId) storeReferrerIdLocally(referrerId)

  return (
    <div>
        <Helmet>
            <title>Peer2Own | Find People to Share Land And Build With You.</title>
            <meta
              name="description"
              content="Our mission is to make real estate more accessible and inclusive for everyone. We believe that everyone deserves the opportunity to build their dream home and build a better future for themselves and their families."
            />
        </Helmet>
        <Nav />
        <section className='container-fluid text-center welcome-header'>
            <br /><br />
            <h1 className='welcome-header-title'>Find People To Share Land</h1>
            <span className='welcome-header-title welcome-header-title-2'>
             And Build With You
            </span><span className='welcome-header-title welcome-header-title-2 text-white'>!</span>
            <br /><br />
            <p className='welcome-header-elevator-pitch'>
              Collaborate with like-minded people to acquire land and build any type of property that is beyond your budget.
            </p>
            <p className='welcome-header-elevator-pitch'>
              Get Your Registered Title & Track the progress of your building project on-the-go.
            </p>
            <br />
            <Link className='btn btn-primary p2o-large-btn get-started-btn' to='/signup'>
                Sign Up
            </Link>
        </section>
        <section className='text-center watch-video bg-white'>
            <div className='container'>
              <iframe 
                className='youtube-video'
                src="https://www.youtube.com/embed/623qrgiS3Ss?rel=0"
                title="Peer-to-Own" 
                frameborder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                allowfullscreen>
              </iframe>
              <br /><br />
              <Link className='btn btn-primary p2o-large-btn get-started-btn' to='/signup'>
                  Get Started
              </Link>
            </div>
        </section>
        <section className='container-fluid why-p2o'>
            <br/><br/>
            <div className='container'>
              <h3 className='text-center text-white why-p2o-header'>
                Why Peer2Own?
              </h3>
              <div className='row align-items-center why-p2o-item'>
                  <div className='col-md-4 text-center'>
                      <img src='./assets/luxury-apartments.jpg' width='100%' alt='Luxury Apartments' />
                  </div>
                  <div className='col'>
                      <br/>
                      <h2 className='text-white why-p2o-item-header'>
                        Luxurious Living With Pennies
                      </h2>
                      <p className='text-white why-p2o-item-body'>
                        With a low budget, you can team up with other people 
                        like you to buy a large plot of land and build a community of luxury homes. 
                        You would each own your own home, and you would share the costs of the land
                        and infrastructure. This is a great way to make luxurious living more 
                        affordable and accessible for everyone.
                      </p>
                  </div>
              </div>
              <div className='row align-items-center why-p2o-item'>
                  <div className='col'>
                      <br/>
                      <h2 className='text-white why-p2o-item-header'>Save Cost</h2>
                      <p className='text-white why-p2o-item-body'>
                        Invest smarter and save up to 65% by collaborating with like-minded people to 
                        acquire land and build real estate together.
                      </p>
                  </div>
                  <div className='col-md-4 text-center'>
                      <img src='./assets/save-cost.jpg' width='100%' alt='Save Cost' />
                  </div>
              </div>
              <div className='row align-items-center why-p2o-item'>
                  <div className='col-md-4 text-center'>
                      <img 
                        src='./assets/tailored-opportunities.jpg' 
                        width='100%' alt='Tailored Opportunities' 
                      />
                  </div>
                  <div className='col'>
                      <br/>
                      <h2 className='text-white why-p2o-item-header'>Tailored Opportunities</h2>
                      <p className='text-white why-p2o-item-body'>
                        Set your preference for property type, location, and budget, and join 
                        opportunities created for projects that match your preference.
                      </p>
                      <p className='text-white why-p2o-item-body'>
                        This way you are sure to get the best that your budget can afford.
                      </p>
                  </div>
              </div>
              <div className='row align-items-center why-p2o-item'>
                  <div className='col'>
                      <br/>
                      <h2 className='text-white why-p2o-item-header'>Financially-ready Peers</h2>
                      <p className='text-white why-p2o-item-body'>
                        You can be sure that the people you are getting matched with
                        are financially ready to begin a project, because an evidence of 
                        proof-of-funds is required before subscribed members can join opportunities.
                      </p>
                  </div>
                  <div className='col-md-4 text-center'>
                      <img src='./assets/financially-ready.jpg' width='100%' alt='Financially Ready' />
                  </div>
              </div>
              <div className='row align-items-center why-p2o-item'>
                  <div className='col-md-4 text-center'>
                      <img src='./assets/community-like-minds.jpg' width='100%' alt='Community of Like Minds' />
                  </div>
                  <div className='col'>
                      <br/>
                      <h2 className='text-white why-p2o-item-header'>Community of Like Minds</h2>
                      <p className='text-white why-p2o-item-body'>
                        Imagine living with a community of people who share your values and goals. 
                        Peer2Own is a platform that allows people to come together to buy land and
                        build sustainable homes and communities. It's a way to take control of your
                        own future and create a place where you can truly thrive. Together, you can 
                        build a community that is good for the environment and good for the people 
                        who live there. A community where you can raise your family, grow your 
                        business, and pursue your dreams.
                      </p>
                  </div>
              </div>
              <div className='row align-items-center why-p2o-item'>
                  <div className='col'>
                      <br/>
                      <h2 className='text-white why-p2o-item-header'>Access to Real Estate Professionals</h2>
                      <p className='text-white why-p2o-item-body'>
                        Buying land and building can be a risky and daunting process, 
                        but it doesn't have to be. With Peer2Own, you have the option to work with
                        experienced real estate professionals who will help you every step of the 
                        way. Our unlimited network of certified professionals can help you find the perfect 
                        piece of land with unencumbered title and work 
                        with you to ensure that construction is completed within the set time frame. 
                      </p>
                  </div>
                  <div className='col-md-4 text-center'>
                      <img src='./assets/professionals.jpg' width='100%' alt='Professionals' />
                  </div>
              </div>
              <div className='row align-items-center why-p2o-item'>
                  <div className='col-md-4 text-center'>
                      <img src='./assets/genuine-land-title.jpg' width='100%' alt='Genuine Land Title' />
                  </div>
                  <div className='col'>
                      <br/>
                      <h2 className='text-white why-p2o-item-header'>Own Your Title Document</h2>
                      <p className='text-white why-p2o-item-body'>
                        When you collaborate to buy land and build a property through our platform, you will be issued 
                        a title document that gives you legal ownership of your portion of the property. 
                        This title document can be passed on to your heirs, mortgaged or sold to another party 
                        whenever you wish. This gives you peace of mind knowing that your property is 
                        secure and that you have the freedom to manage it as you wish.
                      </p>
                  </div>
              </div>
              <div className='row align-items-center why-p2o-item'>
                  <div className='col'>
                      <br/>
                      <h2 className='text-white why-p2o-item-header'>Customized Floor Plan</h2>
                      <p className='text-white why-p2o-item-body'>
                        Imagine living in a home that you designed from the ground up, to perfectly 
                        suit your needs and lifestyle. With Peer2Own, you can make that dream a 
                        reality.
                        Whether you are collaborating to build a studio apartment,
                        2 bedroom apartment or terrace duplex, you will have 
                        the option to design your home, 
                        with a customized floor plan that is truly unique to you.
                        You can choose to have an open concept living space, additional bedrooms 
                        and bathrooms, and customize the size and layout of your rooms.
                      </p>
                  </div>
                  <div className='col-md-4 text-center'>
                      <img src='./assets/custom-floor-plan.PNG' width='100%' alt='Custom Floor Plan' />
                  </div>
              </div>
              <div className='row align-items-center why-p2o-item'>
                  <div className='col-md-4 text-center'>
                      <img src='./assets/project-tracking.jpg' width='100%' alt='Project Tracking' />
                  </div>
                  <div className='col'>
                      <br/>
                      <h2 className='text-white why-p2o-item-header'>Project Tracking</h2>
                      <p className='text-white why-p2o-item-body'>
                        Stay informed and keep track of your projects directly within the app. 
                        Get real-time updates on progress, milestones, and more.
                      </p>
                      <p className='text-white why-p2o-item-body'>
                        No matter where you are in the world, even if you don't reside where
                        your building project is, with Peer2Own you can trust that
                        your project is advancing as scheduled.
                      </p>
                  </div>
              </div>
              <div className='row align-items-center why-p2o-item'>
                  <div className='col'>
                      <br/>
                      <h2 className='text-white why-p2o-item-header'>Access to Mortgage Loans</h2>
                      <p className='text-white why-p2o-item-body'>
                        As a subscribed member in a project, you can access loans to support the completion of your project. 
                        This means that you can still build your dream home even if you don't have the full amount 
                        of money saved up.
                      </p>
                  </div>
                  <div className='col-md-4 text-center'>
                      <img 
                        src='./assets/mortgage-loan.jpg' 
                        width='100%'
                        alt='Access to Mortgage Loans' 
                      />
                  </div>
              </div>
              <br /><br />
              <div className='text-center'>
                <Link className='btn btn-primary p2o-large-btn get-started-btn' to='/signup'>
                    Get Started
                </Link>
              </div>
            </div>
        </section>
        <section className='welcome-propose-land'>
            <div className='container'>
                <div className='row align-items-center'>
                    <div className='col-md-6'>
                        <img 
                          src='./assets/available-land.jpg'
                          alt='Propose Land' 
                          width='100%'
                        />
                    </div>
                    <div className='col'>
                        <h2 className='text-white welcome-propose-land-header'>
                          Do you have Land for Sale?
                        </h2>
                        <p className='welcome-propose-land-sub-header'>
                          Are you a real estate agent with the owner's direct authority to sell?
                          <strong> Peer2Own can help you sell your land faster</strong> by providing 
                          you with first-hand information on what buyers want and the rare opportunity 
                          to sell your land to a highly qualified audience who are serious about 
                          buying.
                        </p>
                        <Link to='/land-sellers' className='btn btn-primary cta-btn'>
                          Learn More
                        </Link>
                    </div>
                </div>
            </div>
        </section>
        <section className='welcome-sign-up'>
          <div className='container'>
              <div className='row justify-content-center align-items-center'>
                  <div className='col-md-7 text-center'>
                        <h4 className='text-white'>
                          <strong>Sign up now for exclusive access.</strong>
                        </h4>
                        <p className='text-white'>
                          Start collaborating and building today!
                        </p>
                  </div>
                  <div className='col text-center'>
                        <Link to='/signup' className='btn btn-primary cta-btn white-btn welcome-sign-up-btn'>
                          Sign Up Now
                        </Link>
                  </div>
              </div>
          </div>
        </section>
        <Footer />
    </div>
  )
}

export default Welcome