import React from 'react'
import NavBar from '../components/Nav'
import Participants from '../components/Participants'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'

function ViewParticipants() {
  const {globalPrefId, oppId} = useParams()

  return (
    <div>
      <Helmet>
          <title>Peer2Own -  View Opportunity Participants Queue</title>
      </Helmet>
      <NavBar />
      <br />
      <div className='container'>
          <h2 className='p2o-page-title'>
            Participants
          </h2>
          <Participants 
            globalPrefId={globalPrefId}
            oppId={oppId}
          />
      </div>
      <br />
    </div>
  )
}

export default ViewParticipants