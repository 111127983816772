import {db, storage, serverTimestamp, documentId} from '../firebase'
import { 
    doc,
    collection,
    getDoc,
    getDocs,
    addDoc,
    updateDoc,
    deleteDoc,
    query,
    orderBy,
    limit,
    onSnapshot,
    where
} from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL} from 'firebase/storage'
import { useDispatch, useSelector } from "react-redux";
import { UploadingProgressActions } from '../redux/store';
import { usePreference } from './usePreference';

export function useOpportunity(globalPrefId = null) {
    const user = useSelector(state => state.user)

    const dispatch = useDispatch()
    const {setUploadingProgressState} = UploadingProgressActions

    const {
        getGlobalPreference, 
        updateGlobalPreference
    } = usePreference()

    const createOpportunity = async (globalPrefId, data) => {
        try {
            const oppCollectionRef = collection(db, `preferences/${globalPrefId}/opportunities`)
            const oppRef = await addDoc(oppCollectionRef, data)
            return oppRef.id
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const openOpportunity = async (globalPrefId, oppId) => {
        try {
           await updateOpportunity(globalPrefId, oppId, {status: 'open'}) 
           return true
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const closeOpportunity = async (globalPrefId, oppId) => {
        try {
            await updateOpportunity(globalPrefId, oppId, {status: 'closed'}) 
            return true
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const getBuildingType = async type => {
        try {
            const q = query(collection(
                db, "buildingTypes"), 
                where('type', '==', type)
            );

            const querySnapshot = await getDocs(q);
            const buildingTypes = []
            querySnapshot.forEach((doc) => {
                const buildingTypeId = doc.id
                const buildingType = {buildingTypeId, ...doc.data()}
                buildingTypes.push(buildingType)
            });
            return buildingTypes[0]
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const getAllOpportunities = (globalPrefId, setOpportunities, setIsLoading) => {
        try {
            const opportunitiesCollection = collection(db, `preferences/${globalPrefId}/opportunities`)
            const q = query(opportunitiesCollection, where("status", "!=", "initiating"), orderBy("status", "desc"))
            const unsub = onSnapshot(q, async snapshot => {
                const opportunities = []
                snapshot.forEach(doc => {
                    opportunities.push({...doc.data(), oppId: doc.id})
                })
                setOpportunities(opportunities)
                setIsLoading(false)
            });
            return unsub
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const countOpenOpportunities = async globalPrefId => {
        try {
            const opportunitiesCollection = collection(db, `preferences/${globalPrefId}/opportunities`)
            const q = query(opportunitiesCollection, where("status", "==", "open"))
            const snapshot = await getDocs(q);
            const opportunities = []
            snapshot.forEach(doc => opportunities.push(doc.data))
            return opportunities.length
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const countClosedOpportunities = async globalPrefId => {
        try {
            const opportunitiesCollection = collection(db, `preferences/${globalPrefId}/opportunities`)
            const q = query(opportunitiesCollection, where("status", "==", "closed"))
            const snapshot = await getDocs(q);
            const opportunities = []
            snapshot.forEach(doc => opportunities.push(doc.data))
            return opportunities.length
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const getOpenOpportunities = (globalPrefId, setOpportunities) => {
        try {
            const opportunitiesCollection = collection(db, `preferences/${globalPrefId}/opportunities`)
            const q = query(opportunitiesCollection, where("status", "==", "open"));
            const unsub = onSnapshot(q, async snapshot => {
                const opportunities = []
                snapshot.forEach(doc => {
                    opportunities.push({...doc.data(), oppId: doc.id})
                })
                setOpportunities(opportunities)
            });
            return unsub
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const getOpportunity = async (globalPrefId, oppId) => {
        try {
            const oppRef = doc(db, `preferences/${globalPrefId}/opportunities`, oppId)
            const oppSnap = await getDoc(oppRef)
            if(oppSnap.exists()) {
                return {...oppSnap.data(), oppId: oppRef.id}
            } else {
                return null
            }
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const updateOpportunity = async (globalPrefId, oppId, data) => {
        try {
            const oppRef = doc(db, `preferences/${globalPrefId}/opportunities`, oppId);
            await updateDoc(oppRef, data)
            return true
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const deleteOpportunity = async (globalPrefId, oppId) => {
        try {
            const oppRef = doc(db, `preferences/${globalPrefId}/opportunities`, oppId);
            await deleteDoc(oppRef);
            return true;
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const sendJoinRequest = async (setLoading, joinRequest) => {
        try {
            //if(!user.isSubscribed) throw new Error("You need to be subscribed to Join an Opportunity")
            if(joinRequest.size > 512391) throw new Error("File Size is too large!")
            setLoading(true)
            const globalPref = await getGlobalPreference(joinRequest.globalPrefId)
            //const opportunity = await getOpportunity(joinRequest.globalPrefId, joinRequest.oppId)
            //if(!opportunity) throw new Error("An Error Occured While Sending Request. Try Again Later.")
            
            const storagePath = joinRequest.globalPrefId + '/join-opportunity-requests/proof-of-funds/' + joinRequest.uid +'-'+ new Date().getTime()
            const storageRef = ref(storage, storagePath);
            const uploadTask = uploadBytesResumable(storageRef, joinRequest.file);
    
            uploadTask.on('state_changed',
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes);
                const progressPercentage = progress * 100

                dispatch(setUploadingProgressState({
                    show: true,
                    progress: progressPercentage,
                    message: "Uploading Document..."
                }))
                switch (snapshot.state) {
                    case 'paused':
                        dispatch(setUploadingProgressState({
                            show: true,
                            progress: progressPercentage,
                            message: "Upload Paused..."
                        }))
                        break;
                    case 'running':
                        dispatch(setUploadingProgressState({
                            show: true,
                            progress: progressPercentage,
                            message: "Uploading Document..."
                        }));
                        break;
                    default:
                }
            }, 
            (error) => {
                switch (error.code) {
                    case 'storage/unauthorized':
                        setLoading(false)
                        // User doesn't have permission to access the object
                        break;
                    case 'storage/canceled':
                        setLoading(false)
                        // User canceled the upload
                        break;
    
                    case 'storage/unknown':
                        setLoading(false)
                        // Unknown error occurred, inspect error.serverResponse
                        break;
                    default: 
                }
            }, 
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                    const newJoinRequest = {
                        uid: joinRequest.uid,
                        status: joinRequest.status,
                        proofOfFundsUrl: downloadURL
                    }
                    const newRequests = [newJoinRequest, ...globalPref.requests]
                    await updateGlobalPreference(
                        joinRequest.globalPrefId,
                        {requests: newRequests}
                    )
                    await addJoinRequestToAdminRequests({
                        ...newJoinRequest, 
                        emailAddress: user.emailAddress,
                        globalPrefId: joinRequest.globalPrefId,
                        timestamp: serverTimestamp()
                    })
                    setLoading(false)
                });
            }); 
        } catch (error) {
            throw new Error(error.message)   
        }
    }

    const addJoinRequestToAdminRequests = async request => {
        try {
            const reqsCollectionRef = collection(db, `requests`)
            const reqsRef = await addDoc(reqsCollectionRef, request)
            return reqsRef.id
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const fetchAllRequests = async () => {
        try {
            const q = query(collection(db, "requests"), orderBy("timestamp", "desc"));
            const querySnapshot = await getDocs(q);
            const requests = []
            querySnapshot.forEach(doc => {
                requests.push({id: doc.id, ...doc.data()})
            });
            return requests
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const watchAllRequests = async setRequests => {
        try {
            const q = query(collection(db, "requests"), orderBy("timestamp", "desc"));
            const unsub = onSnapshot(q, snapshot => {
                const requests = []
                snapshot.forEach(doc => requests.push({id: doc.id, ...doc.data()}))
                setRequests(requests)
            })
            return unsub
        } catch (error) {
           throw new Error(error.message) 
        }
    }

    const approveGlobalRequest = async request => {
        try {
            const requestsRef = doc(db, `requests`, request?.id)
            await updateDoc(requestsRef, {approved: true, declined: false})
            return true
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const declineGlobalRequest = async request => {
        try {
            const requestsRef = doc(db, `requests`, request?.id)
            await updateDoc(requestsRef, {approved: false, declined: true})
            return true
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const updateUserRequestStatus = (requests, request , newStatus) => {
        const userRequestFilter = requests.filter(req => req.uid === request.uid)
        const userRequestIndex = requests.findIndex(req => req.uid === request.uid)
        const userRequest = userRequestFilter[0]
        const newUserRequest = {...userRequest, status: newStatus}

        const newRequests = [
            ...requests.slice(0, userRequestIndex),
            newUserRequest,
            ...requests.slice(userRequestIndex + 1)
        ]

        return newRequests;
    }

    const addUserToParticipants = async (participants, request) => {
        const newParticipants =  await participantExists(
            request?.globalPrefId, 
            request?.oppId,
            request?.uid
        ) ? 
        participants :
        [request.uid, ...participants]

        return newParticipants;
    }

    const removeUserFromParticipants = async (participants, request) => {
        const newParticipants =  await participantExists(
            request?.globalPrefId, 
            request?.oppId,
            request?.uid
        ) ? participants.filter(uid => uid !== request.uid ) :
        participants

        return newParticipants;
    }

    const approveJoinRequest = async request => {
        try {
            await approveGlobalRequest(request);
            const globalPref = await getGlobalPreference(request.globalPrefId)
            //const opportunity = await getOpportunity(request?.globalPrefId, request?.oppId);

            const newRequests = updateUserRequestStatus(globalPref.requests, request, 'approved')
            const newParticipants = await addUserToParticipants(globalPref.participantsQueue, request)
            
            await updateGlobalPreference(
                request?.globalPrefId,
                {
                    requests: newRequests,
                    participantsQueue: newParticipants
                }
            )
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const declineJoinRequest = async request => {
        try {
            await declineGlobalRequest(request);
            //const opportunity = await getOpportunity(request?.globalPrefId, request?.oppId)
            const globalPref = await getGlobalPreference(request.globalPrefId)

            const newRequests = updateUserRequestStatus(globalPref.requests, request, 'declined')
            const newParticipants = await removeUserFromParticipants(globalPref.participantsQueue, request)
        
            await updateGlobalPreference(
                request?.globalPrefId,
                {
                    requests: newRequests,
                    participantsQueue: newParticipants
                }
            )
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const requestExists = async (globalPrefId, oppId, uid) => {
        try {
            //const opportunity = await getOpportunity(globalPrefId, oppId)
            const globalPref = await getGlobalPreference(globalPrefId)
            const filter = globalPref.requests.filter(
                request => request.uid === uid && 
                request.status === 'pending'
            )
            if(filter.length > 0) return true
            return false
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const participantExists = async (globalPrefId, uid) => {
        try {
           // const opportunity = await getOpportunity(globalPrefId, oppId)
            const globalPref = await getGlobalPreference(globalPrefId)
            const filter = globalPref.participantsQueue.filter(
                _uid => _uid === uid
            )
            if(filter.length > 0) return true
            return false
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const uploadProjectDesignPhotos = async (globalPrefId, oppId, files) => {
        const opp = await getOpportunity(globalPrefId, oppId)
        const projectDesignImagesUrls = opp.projectDesignImagesUrls || []
        files.forEach(file => {
            if(file.size > 524288) throw new Error('Image cannot be larger than 512KB')
            const storagePath = globalPrefId + '/opportunities/' + oppId + "/projectDesignPhotos/" + file.name
            const storageRef = ref(storage, storagePath);
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on('state_changed',
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes);
                const progressPercentage = progress * 100
                dispatch(setUploadingProgressState({
                    show: true,
                    progress: progressPercentage,
                    message: "Uploading Photos..."
                }))
                switch (snapshot.state) {
                    case 'paused':
                        dispatch(setUploadingProgressState({
                            show: true,
                            progress: progressPercentage,
                            message: "Upload Paused..."
                        }))
                        break;
                    case 'running':
                        dispatch(setUploadingProgressState({
                            show: true,
                            progress: progressPercentage,
                            message: "Uploading Photos..."
                        }))
                        break;
                    default:
                }
            }, 
            (error) => {
                switch (error.code) {
                    case 'storage/unauthorized':
                        // User doesn't have permission to access the object
                        break;
                    case 'storage/canceled':
                        // User canceled the upload
                        break;
    
                    case 'storage/unknown':
                        // Unknown error occurred, inspect error.serverResponse
                        break;
                    default: 
                }
            }, 
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                    projectDesignImagesUrls.push(downloadURL)
                    updateOpportunity(
                        globalPrefId,
                        oppId, 
                        {
                            projectDesignImagesUrls: projectDesignImagesUrls,
                            status: 'open'
                        }
                    )
                });
            }); 
        })
    }

    const watchParticipants = async (globalPrefId, setParticipants) => {
        try {
            //const opp = await getOpportunity(globalPrefId, oppId);
            const globalPref = await getGlobalPreference(globalPrefId)
            const q = query(collection(
                db, "users"), 
                where(documentId(), 'in', globalPref.participantsQueue)
            );
            const unsub = onSnapshot(q, snapshot => {
                const participants = []
                snapshot.forEach(doc => participants.push({id: doc.id, ...doc.data()}))
                setParticipants(participants)
            })
            return unsub
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const moveUserToEndOfParticipantsQueue = async (uid, globalPref) => {
        try {
            const participants = globalPref.participantsQueue
            const participantsWithoutThisUser = participants.filter(participantUid => participantUid !== uid)
            const newParticipantsQueue = [...participantsWithoutThisUser, uid]

            await updateGlobalPreference(
                globalPref.id,
                {participantsQueue: newParticipantsQueue} 
            )

        } catch (error) {
            throw new Error(error.message)
        }
    }

    const removeUserFromParticipantsQueue = async (uid, globalPref) => {
        try {
            const participants = globalPref.participantsQueue
            const participantsWithoutThisUser = participants.filter(participantUid => participantUid !== uid)
            const newParticipantsQueue = participantsWithoutThisUser
            
            await updateGlobalPreference(
                globalPref.id,
                {participantsQueue: newParticipantsQueue} 
            )
        } catch (error) {
            throw new Error(error.message)
        }
    }

    return {
        createOpportunity,
        openOpportunity,
        closeOpportunity,
        getAllOpportunities,
        getOpenOpportunities,
        getOpportunity,
        updateOpportunity,
        deleteOpportunity,
        sendJoinRequest,
        approveJoinRequest,
        declineJoinRequest,
        requestExists,
        countOpenOpportunities,
        countClosedOpportunities,
        uploadProjectDesignPhotos,
        watchAllRequests,
        watchParticipants,
        getBuildingType,
        moveUserToEndOfParticipantsQueue,
        removeUserFromParticipantsQueue
    }

}