import React, {useEffect} from 'react'
import Nav from '../components/Nav'
import Footer from '../components/Footer'
import { useParams, useLocation } from 'react-router-dom'
import CreateProjectForm from '../components/CreateProjectForm'
import { Helmet } from 'react-helmet'

function CreateProject() {

    const { globalPrefId } = useParams();
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

  return (
    <div>
        <Helmet>
            <title>Peer2Own -  Create Project</title>
        </Helmet>
        <Nav />
        <br />
        <div className='container'>
            <div className='row justify-content-center'>
                <div className='col-md-6 p2o-form'>
                    <h1 className='p2o-form-title'>Create Project</h1>
                    <hr className='p2o-form-title-divider' />
                    <CreateProjectForm 
                        globalPrefId={globalPrefId}
                    />
                </div>
            </div>    
        </div>
        <br />
        <Footer />
    </div>
  )
}

export default CreateProject