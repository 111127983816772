import {db, storage, serverTimestamp, documentId} from '../firebase'
import { 
    doc,
    collection,
    getDoc,
    getDocs,
    addDoc,
    updateDoc,
    deleteDoc,
    query,
    orderBy,
    limit,
    onSnapshot,
    where
} from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL} from 'firebase/storage'
import { useDispatch, useSelector } from "react-redux";
import { useOpportunity } from './useOpportunity';
import { UploadingProgressActions } from '../redux/store';

export function useLandProposal() {
    const user = useSelector(state => state.user)
    const {getOpportunity} = useOpportunity()
    const {setUploadingProgressState} = UploadingProgressActions
    const dispatch = useDispatch()

    const watchLandsForUser = async (uid, setLandsForUser) => {
        try {
            const q = query(collection(
                db, "landProposals"), 
                where('uid', '==', uid)
            );
            const unsub = onSnapshot(q, snapshot => {
                const lands = []
                snapshot.forEach(doc => lands.push({id: doc.id, ...doc.data()}))
                setLandsForUser(lands)
            })
            return unsub;
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const getLandsByLandTitleIdNumber = async landTitleIdNumber => {
        try {
            const q = query(collection(
                db, "landProposals"), 
                where('landTitleIdNumber', '==', landTitleIdNumber)
            );
            const querySnapshot = await getDocs(q);
            const lands = []
            querySnapshot.forEach((doc) => {
                lands.push({
                    id: doc.id, 
                    landTitleIdNumber: removeSymbolsFromInput(doc.data().landTitleIdNumber),
                    ...doc.data()
                });
            });
            return lands;
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const checkIfLandTitleIdNumberExists = async landTitleIdNumber => {
        try {
            const lands = await getLandsByLandTitleIdNumber(landTitleIdNumber)
            const landTitleIdNumbers = []
            lands.forEach(land => landTitleIdNumbers.push(
                removeSymbolsFromInput(land.landTitleIdNumber)
            ))
            const filter = landTitleIdNumbers.filter(
                _landTitleIdNumber => _landTitleIdNumber === removeSymbolsFromInput(landTitleIdNumber)
            )
            if(filter.length > 0) return true;
            return false
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const removeSymbolsFromInput = input => {
        try {
            const regex = /[^A-Za-z0-9]/;
            const parts = input.split(regex);
            return parts.join("")
        } catch (error) {
            throw new Error(error.message)
        }
    } 

    const sendLandProposal = async landProposal => {
        try {
            //if(!user.isSubscribed) throw new Error("Only Subscribed Members Are Authorized To Send Land Proposals.")
            
            const landTitleNumber = removeSymbolsFromInput(landProposal.landTitleIdNumber)
            if(
                landTitleNumber && 
                await checkIfLandTitleIdNumberExists(landTitleNumber)
            ) {
                const error = new Error("The Land Title Number you entered already exists in our database");
                error.code = "808"
                throw error
            }

            const modifiedLandProposal = {
                ...landProposal,
                landTitleIdNumber: landTitleNumber
            }

            const landProposalsCollectionRef = collection(db, `landProposals`)
            const landProposalRef = await addDoc(
                landProposalsCollectionRef, 
                {...modifiedLandProposal, timestamp: serverTimestamp()}
            )
            return landProposalRef.id
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const updateLandProposal = async (landProposalId, data) => {
        try {
            const landProposalRef = doc(db, `landProposals`, landProposalId);
            await updateDoc(landProposalRef, data)
            return true
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const getLandProposal = async landProposalId => {
        try {
            const landProposalRef = doc(db, `landProposals`, landProposalId)
            const landProposalSnap = await getDoc(landProposalRef)
            if(landProposalSnap.exists()) {
                return {...landProposalSnap.data(), id: landProposalRef.id}
            } else {
                return null
            }
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const watchLandProposalsForOpp = async (globalPrefId, oppId, setProposedLandsForOpp) => {
        try {
            const opp = await getOpportunity(globalPrefId, oppId);
            const q = query(collection(
                db, "landProposals"), 
                where(documentId(), 'in', opp.proposedLands)
            );
            const unsub = onSnapshot(q, snapshot => {
                const landProposals = []
                snapshot.forEach(doc => landProposals.push({id: doc.id, ...doc.data()}))
                setProposedLandsForOpp(landProposals)
            })
            return unsub
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const deleteLandProposal = async landProposalId => {
        try {
            const landProposalRef = doc(db, `landProposals`, landProposalId);
            await deleteDoc(landProposalRef);
            return true;
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const watchAllLandProposals = async setLandProposals => {
        try {
            const q = query(collection(db, "landProposals"), orderBy("timestamp", "desc"));
            const unsub = onSnapshot(q, snapshot => {
                const landProposals = []
                snapshot.forEach(doc => landProposals.push({id: doc.id, ...doc.data()}))
                setLandProposals(landProposals)
            })
            return unsub
        } catch (error) {
           throw new Error(error.message) 
        }
    }

    const watchAllLandProposalsForOppLinking = async setLandProposals => {
        try {
            const q = query(collection(db, "landProposals"), where("accepted", "==", true), orderBy("timestamp", "desc"));
            const unsub = onSnapshot(q, snapshot => {
                const landProposals = []
                snapshot.forEach(doc => landProposals.push(`${doc.id} - ${doc.data().landLocation} | ${doc.data().landSize} | ₦${parseInt(doc.data().landCost).toLocaleString()}`))
                setLandProposals(landProposals)
            })
            return unsub
        } catch (error) {
           throw new Error(error.message) 
        }
    }

    
    const uploadLandPhotos = async (landProposalId, files) => {
        const landPhotos = []
        files.forEach(file => {
            if(file.size > 524288) throw new Error('Image cannot be larger than 512KB')
            const storagePath = `/landProposals/${landProposalId}/photos` + file.name
            const storageRef = ref(storage, storagePath);
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on('state_changed',
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes);
                const progressPercentage = progress * 100
                dispatch(setUploadingProgressState({
                    show: true,
                    progress: progressPercentage,
                    message: "Uploading Photos..."
                }))
                switch (snapshot.state) {
                    case 'paused':
                        dispatch(setUploadingProgressState({
                            show: true,
                            progress: progressPercentage,
                            message: "Upload Paused..."
                        }))
                        break;
                    case 'running':
                        dispatch(setUploadingProgressState({
                            show: true,
                            progress: progressPercentage,
                            message: "Uploading Photos..."
                        }))
                        break;
                    default:
                }
            }, 
            (error) => {
                switch (error.code) {
                    case 'storage/unauthorized':
                        // User doesn't have permission to access the object
                        break;
                    case 'storage/canceled':
                        // User canceled the upload
                        break;
    
                    case 'storage/unknown':
                        // Unknown error occurred, inspect error.serverResponse
                        break;
                    default: 
                }
            }, 
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                    landPhotos.push(downloadURL)
                    updateLandProposal(
                        landProposalId, 
                        {landPhotos: landPhotos}
                    )
                });
            }); 
        })
    }

    const acceptLandProposal = async landProposalId => {
        try {
            const landProposalsRef = doc(db, `landProposals`, landProposalId)
            await updateDoc(landProposalsRef, {accepted: true, ignored: false})
            return true
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const ignoreLandProposal = async landProposalId => {
        try {
            const landProposalsRef = doc(db, `landProposals`, landProposalId)
            await updateDoc(landProposalsRef, {accepted: false, ignored: true})
            return true
        } catch (error) {
            throw new Error(error.message)
        }
    }

    return {
        sendLandProposal,
        getLandProposal,
        updateLandProposal,
        watchAllLandProposals,
        watchAllLandProposalsForOppLinking,
        uploadLandPhotos,
        acceptLandProposal,
        ignoreLandProposal,
        deleteLandProposal,
        watchLandProposalsForOpp,
        watchLandsForUser
    }
}