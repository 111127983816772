import React, {useState, useEffect} from 'react'
import { useOpportunity } from '../hooks/useOpportunity'
import Participant from './Participant'
import LoadingIndicator from './LoadingIndicator'

function Participants({ globalPrefId, oppId }) {
    const [participants, setParticipants] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const {watchParticipants} = useOpportunity()

  useEffect(() => {
    let unsub;
    (async () => {
      unsub = await watchParticipants(globalPrefId, setParticipants)
      setIsLoading(false)
    })()
    return unsub;
  }, [])

  return (
    <div>
        {
            isLoading ?
            <div className='text-center'>
                <LoadingIndicator color='#000' />
            </div> :
            <div className='table-responsive'>
             <table className="table table-sm table-striped p2o-table text-center">
                 <thead>
                     <tr>
                         <th scope="col">#</th>
                         <th scope="col">Email Address</th>
                         <th scope="col">First Name</th>
                         <th scope="col">Last Name</th>
                         <th scope="col">Phone Number</th>
                     </tr>
                 </thead>
                 <tbody>
                     {
                         participants.length < 1 ? 
                         <div className='text-center'>No Participants</div> :
                         participants.map((participant, index) => <Participant key={index} sn={index + 1} participant={participant} />)
                     }
                 </tbody>
             </table>
         </div>
        }
    </div>
  )
}

export default Participants