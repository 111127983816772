import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import { usePreference } from '../hooks/usePreference'
import { useProject } from '../hooks/useProject'
import { useSelector } from 'react-redux'
import LoadingIndicator from './LoadingIndicator'

function MyPreference({pref, index}) {
    const user = useSelector(state => state.user)
    const [isDeleting, setIsDeleting] = useState(false)
    const [projectsStartedCount, setProjectsStartedCount] = useState(0)
    const {deletePreference} = usePreference()
    const {countProjectsStarted} = useProject()

    useEffect(() => {
        (async ()=> {
            if(pref?.globalPrefId){
                setProjectsStartedCount(await countProjectsStarted(pref?.globalPrefId))
            } 
        })()
    }, [])

    const potentialMatchesExist = () => {
        if(pref?.similarPreferencesCount && pref?.similarPreferencesCount > 1) return true
        return false
    }

    const handleDeletePreference = async () => {
        const confirm = window.confirm("Are you sure you want to delete this preference?")
        if(!confirm) return;
        setIsDeleting(true)
        await deletePreference(user.uid, pref.id);
        setIsDeleting(false)
    }
  return (
    <div className='col-md-6'>
            <div className="card my-pref-card">
                <div className="card-body text-center my-pref-card-title">
                    <h3 className="card-title my-pref-card-title-header">
                        <strong>Join this Opportunity</strong>
                    </h3>
                    <hr className='add-preference-form-title-divider'/>
                    {/* <span className="badge badge-danger my-pref-card-title-value-badge">
                        {`PREF-${new Date().getFullYear()}${new Date().getMonth() + 1}${index + 1}`}
                    </span> */}
                </div>
                <ul class="list-group list-group-flush">
                    <li class="my-pref-card-item list-group-item d-flex justify-content-between align-items-center">
                        Property Type
                        <span class="badge badge-primary my-pref-card-item-value-badge">
                            {pref?.propertyType}
                        </span>
                    </li>
                    <li class="my-pref-card-item list-group-item d-flex justify-content-between align-items-center">
                        Property Use <br />
                        <span class="badge badge-primary my-pref-card-item-value-badge">
                            {pref?.propertyUse}
                        </span>
                    </li>
                    <li class="my-pref-card-item list-group-item d-flex justify-content-between align-items-center">
                        Choice Location <br />
                        <span class="badge badge-primary my-pref-card-item-value-badge">
                            {pref?.choiceLocation}
                        </span>
                    </li>
                    <li class="my-pref-card-item list-group-item d-flex justify-content-between align-items-center">
                        Budget <br />
                        <span class="badge badge-primary my-pref-card-item-value-badge">
                            {pref?.budget}
                        </span>
                    </li>
                  {/*   {
                        pref?.globalPrefId && 
                        <>
                            <li class="my-pref-card-item list-group-item d-flex justify-content-between align-items-center">
                                Projects Started <br />
                                <span class="badge badge-primary my-pref-card-item-value-badge">
                                    {projectsStartedCount}
                                </span>
                            </li>
                        </>
                    } */}
                </ul>
                <div class="card-body">
                    <div className='text-center' style={{marginBottom: '20px'}}>
                        <div className='badge badge-danger also-interested-badge'>
                            {
                                potentialMatchesExist() ? pref?.similarPreferencesCount - 1 + 
                                ` other ${pref?.similarPreferencesCount - 1 === 1? ' person has': ' people have'} 
                                this preference. `: 
                                ''
                            }
                        </div>
                    </div>        
                    <Link 
                        disabled={pref?.globalPrefId ? true : false} 
                        to={pref?.globalPrefId ? `/join-opportunity/${pref?.globalPrefId}` : '#'} 
                        className='btn btn-primary cta-btn view-opp-btn' 
                    >
                        <span style={{fontSize: '20px'}}>Join Opportunity</span>
                    </Link>
                    <br /><br />
                    <Link onClick={handleDeletePreference} className="btn btn-default delete-my-pref-btn">
                        {
                            isDeleting?
                            <LoadingIndicator color='#000' />:
                            'Delete'
                        }
                    </Link>
                </div>
            </div>
    </div>
    
  )
}

export default MyPreference