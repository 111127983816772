import {db} from '../firebase'
import {storage} from '../firebase'
import { 
    doc,
    getDoc, 
    setDoc,
    updateDoc,
    getDocs,
    onSnapshot,
    query,
    collection,
    orderBy
} from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL} from 'firebase/storage'
import { useSelector, useDispatch } from "react-redux";
import {UserActions} from "../redux/store"

export function useUser() {
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()
    const {setUserState} = UserActions

    const getUser = async uid => {
        try {
            const userRef = doc(db, `users`, uid)
            const userSnap = await getDoc(userRef)
            if(userSnap.exists()) {
                return {...userSnap.data(), uid: userRef.id}
            } else {
                return null
            }
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const registerUser = async (curUser, newUser) => {
        try {
            const _newUser = {
                ...newUser,
                uid: curUser.uid,
                active: true,
                role: 'member',
                emailAddress: curUser.email,
                emailVerified: curUser.emailVerified,
                timeJoined: new Date()
            }
            await storeUserState(_newUser)
            dispatch(setUserState(_newUser))
        } catch (error) {
            throw new Error(error.message)
        }
    }
    
    const storeUserState = async (user) => {
        try{
            await setDoc(doc(db, "users", user.uid), user);
            return true
        }catch(e){
            throw new Error(e.message)
        }
    }

    const updateUserState = async (uid, data) => {
        try {
            const userRef = doc(db, "users", uid);
            await updateDoc(userRef, data)
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const uploadUserProfileImage = async (uri, setImgUploading, setUploadProgress) => {
        setImgUploading(true)
        const response = await fetch(uri);
        const blob = await response.blob();
        const metadata = {
            contentType: 'image/jpeg'
        };

        let profileImgUrlsLength = user.profileImgUrls? user.profileImgUrls.length : 0

        const storagePath = 'profile-imgs/' + user.uid + "/profile-img-" + profileImgUrlsLength
        const storageRef = ref(storage, storagePath);
        const uploadTask = uploadBytesResumable(storageRef, blob, metadata);

        uploadTask.on('state_changed',
        (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes);
            setUploadProgress(progress)
            const progressPercentage = progress * 100
            console.log('Upload is ' + progressPercentage + '% done');
            switch (snapshot.state) {
            case 'paused':
                console.log('Upload is paused');
                break;
            case 'running':
                console.log('Upload is running');
                break;
            default:
                console.log('whatever')
            }
        }, 
        (error) => {
            switch (error.code) {
            case 'storage/unauthorized':
                setImgUploading(false)
                // User doesn't have permission to access the object
                break;
            case 'storage/canceled':
                setImgUploading(false)
                // User canceled the upload
                break;

            // ...

            case 'storage/unknown':
                setImgUploading(false)
                // Unknown error occurred, inspect error.serverResponse
                break;
            default:
                console.log('whatever')    
            }
        }, 
        () => {
            getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                const profileImgUrls = user.profileImgUrls
                const newProfileImgUrls = [...profileImgUrls, downloadURL]
                const newUser = {
                    ...user, 
                    profileImgUrls: newProfileImgUrls
                }
                await storeUserState(newUser)
                dispatch(setUserState(newUser))
                setImgUploading(false)
                setUploadProgress(0)
            });
        });
    }

    const deleteUserProfileImg = async (user, imgIndex, setSavingProfile) => {
        try {
            setSavingProfile(true)
            const profileImgUrls = user.profileImgUrls
            const profileImg = profileImgUrls[imgIndex]
            let filteredProfileImgUrls = profileImgUrls.filter(img => img !== profileImg)
            const newUser = {
                ...user,
                profileImgUrls: filteredProfileImgUrls
            }
            await storeUserState(newUser)
            dispatch(setUserState(newUser))
            setSavingProfile(false)
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const watchUserState = (curUser, setUserState) => {
        try{
            const userRef = doc(db, "users", curUser.uid);
            const unsub = onSnapshot(userRef, async (userSnap) => {
                const data = userSnap.data()
                if(data) {
                    const newUser = {
                        ...data,
                        uid: curUser.uid,
                        loggedIn: true,
                        emailAddress: curUser.email,
                        emailVerified: curUser.emailVerified,
                    }
                    dispatch(setUserState(newUser))
                } else {
                    throw new Error("There was a problem fetching user data")
                }
            })
            return unsub
        }catch(e){
            throw new Error(e.message)
        }
    }

    const watchAllUsers = async setUsers => {
        try {
            const q = query(collection(db, "users"), orderBy("timeJoined", "desc"));
            const unsub = onSnapshot(q, snapshot => {
                const users = [];
                snapshot.forEach(doc => users.push({id: doc.id, ...doc.data()}))
                setUsers(users)
            });
            return unsub
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const activateUser = async uid => {
        try {
            await updateUserState(uid, {active: true})
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const deactivateUser = async uid => {
        try {
            await updateUserState(uid, {active: false})
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const subscribeUser = async uid => {
        try {
            await updateUserState(uid, {isSubscribed: true})
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const unsubscribeUser = async uid => {
        try {
            await updateUserState(uid, {isSubscribed: false})
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const isAdmin = () => {
        try {
            return user.role === 'admin'
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const storeReferrerIdLocally = referrerId => {
        try {
            window.localStorage.setItem("referrerId", referrerId);
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const getReferrerIdLocally = () => {
        try {
            return window.localStorage.getItem("referrerId");
        } catch (error) {
            throw new Error(error.message)
        }
    }

    return {
        storeUserState,
        updateUserState,
        watchUserState,
        uploadUserProfileImage,
        deleteUserProfileImg,
        registerUser,
        watchAllUsers,
        activateUser,
        deactivateUser,
        subscribeUser,
        unsubscribeUser,
        isAdmin,
        getUser,
        storeReferrerIdLocally,
        getReferrerIdLocally
    }
}