import React from 'react'

function RequestsFilter({searchQuery, filterValue, handleSearch, handleFilterRequests}) {
  return (
    <div className='row g-3'>
        <div className='col-sm-9'>
            <input 
                value={searchQuery} 
                onChange={handleSearch} 
                className='form-control' 
                placeholder='Search Requests'
            />
       </div>
        <div className='col-sm'>
            <select value={filterValue} onChange={handleFilterRequests} className='form-control'>
                <option value="all">All Requests</option>
                <option value="pending">Pending Requests</option>
                <option value="approved">Approved Requests</option>
                <option value="declined">Declined Requests</option>
            </select>
        </div>
    </div>
  )
}

export default RequestsFilter