import React from 'react'
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';

function AutoSuggest({placeholder, options, selected, setSelected, ref}) {
  return (
    <Typeahead
      defaultSelected={options.slice(0, 4)}
      selected={selected}
      onChange={setSelected}
      id="selected-proposed-lands"
      labelKey="name"
      multiple
      options={options}
      placeholder={placeholder}
      ref={ref}
      autoFocus={true}
    />
  )
}

export default AutoSuggest