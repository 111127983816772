import React, {useState, useEffect, useRef} from 'react'
import { useOpportunity } from '../hooks/useOpportunity'
import { useLandProposal } from '../hooks/useLandProposal'
import Toast from './Toast'
import LoadingIndicator from './LoadingIndicator'
import AutoSuggest from './AutoSuggest'
import DragDrop from './DragDropFile'

function EditOpportunityForm({opportunity, globalPrefId, oppId}) {
  const [title, setTitle] = useState(opportunity?.title || '')
  const [description, setDescription] = useState(opportunity?.description || '')
  const [intendedLandSize, setIntendedLandSize] = useState(opportunity?.intendedLandSize || '')
  const [intendedLandLocation, setIntendedLandLocation] = useState(opportunity?.intendedLandLocation || '')
  const [numOfParticipants, setNumOfParticipants] = useState(opportunity?.numOfParticipantsRequired || '')
  const [estimatedCostPerParticipant, setEstimatedCostPerParticipant] = useState(opportunity?.estimatedCostPerParticipant || '')
  const [projectDesignPhotosFiles, setProjectDesignPhotosFiles] = useState([]);
  const [projectDesignPhotosFileURLs, setProjectDesignPhotosFileURLs] = useState([]);
  const [projectDesignImagesUrls, setProjectDesignImagesUrls] = useState(opportunity?.projectDesignImagesUrls || []);
  const [proposedLands, setProposedLands] = useState([])
  const [selectedProposedLands, setSelectedProposedLands] = useState([])
  const [selectedProposedLandsIds, setSelectedProposedLandsIds] = useState(opportunity?.proposedLands || [])
  const [successMessage, setSuccessMessage] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const ref = useRef(null)

  const {updateOpportunity, uploadProjectDesignPhotos} = useOpportunity()
  const {watchAllLandProposalsForOppLinking, getLandProposal} = useLandProposal()

  useEffect(() => {
    const landProposalIds = []
    selectedProposedLands.forEach(land => {
      const split = land.split(" - ")
      landProposalIds.push(split[0])
    })
    setSelectedProposedLandsIds(landProposalIds)
  }, [selectedProposedLands])

  useEffect(() => {
      let unsub;
      const loadSelectedProposedLands = async () => {
        const selectedProposedLands = [];
        opportunity.proposedLands.forEach(async proposedLandId =>  {
              const land = await getLandProposal(proposedLandId)
              selectedProposedLands.push(
                `${land.id} - ${land.landLocation} | ${land.landSize} | ₦${parseInt(land.landCost).toLocaleString()}`
              )
        })
        setSelectedProposedLands(selectedProposedLands)
      }

      (async () => {
          unsub = await watchAllLandProposalsForOppLinking(setProposedLands)
          await loadSelectedProposedLands()
      })()
      return unsub;
  }, [])

  useEffect(() => {
    const newfileURLs = []
    projectDesignPhotosFiles.forEach(file => newfileURLs.push({
        file: file,
        fileURL: URL.createObjectURL(file)
    }))
    setProjectDesignPhotosFileURLs(newfileURLs)
  }, [projectDesignPhotosFiles, setProjectDesignPhotosFiles])

  const handleProjectDesignPhotos = _files => {
    try {
      if(projectDesignPhotosFiles.length >= 5) throw new Error("The max number of images you can upload is 5.")
        const filesFromList = Object.values(_files)
        setProjectDesignPhotosFiles(prev => {
          const newFiles = []
          filesFromList.forEach(file => newFiles.push(file))
          return [...newFiles, ...prev]
        })
    } catch (error) {
        setErrorMessage(error.message)
    }
  }

  const handleRemovePhoto = (photoUrl, file) => {
    const newProjectDesignPhotosFileURLs = projectDesignPhotosFileURLs.filter(url => url.fileURL !== photoUrl)
    const newProjectDesignPhotosFiles = projectDesignPhotosFiles.filter(f => f !== file)

    setProjectDesignPhotosFileURLs(newProjectDesignPhotosFileURLs)
    setProjectDesignPhotosFiles(newProjectDesignPhotosFiles)
  }

  const _handleRemovePhoto = async imageUrl => {
    const confirm = window.confirm("Are you sure you want to delete this photo?")
    if(!confirm) return;
    const filter = projectDesignImagesUrls.filter(url => url !== imageUrl)
    setProjectDesignImagesUrls(filter)
    await updateOpportunity(globalPrefId, oppId, {projectDesignImagesUrls: filter})
  }

  const validateForm = () => {
    if(title === '') throw new Error("Enter Opportunity Title")
    if(description === '') throw new Error("Enter Opportunity Description")
    if(intendedLandSize === '') throw new Error("Enter Intended Land Size")
    if(intendedLandLocation === '') throw new Error("Enter Intended Land Location")
    if(numOfParticipants === '') throw new Error("Enter Number of Participants Required")
    if(estimatedCostPerParticipant === '') throw new Error("Enter Estimated Cost Per Participant")

    return true;
  }

  const handleSubmit = async event => {
    try {
      event.preventDefault()
      setIsLoading(true)

      if(!validateForm()) return;

      const editedOpp = {
        title: title,
        description: description,
        intendedLandSize: intendedLandSize,
        intendedLandLocation: intendedLandLocation,
        numOfParticipantsRequired: numOfParticipants,
        estimatedCostPerParticipant: estimatedCostPerParticipant,
        projectDesignImagesUrls: projectDesignImagesUrls,
        proposedLands: selectedProposedLandsIds
      }

      await updateOpportunity(globalPrefId, oppId, editedOpp)
      if(projectDesignPhotosFiles.length > 0) await uploadProjectDesignPhotos(globalPrefId, oppId, projectDesignPhotosFiles)
      setSuccessMessage("Opportunity Updated")
      setIsLoading(false)
    } catch (error) {
      setErrorMessage(error.message)
      setIsLoading(false)
    }
  }

  return (
    <div>
       {
          successMessage &&
          <Toast message={successMessage} setSuccess={setSuccessMessage}/>
        }
        {
          errorMessage &&
          <Toast message={errorMessage} setError={setErrorMessage}/>
        }
       <form onSubmit={handleSubmit}>
            <div class="form-group">
                <label for="titleControlSelect">Title</label>
                <input 
                  className='form-control p2o-form-field' 
                  value={title}
                  onChange={event => setTitle(event.target.value)} 
                />
            </div>
            <div class="form-group">
                <label for="descriptionControlSelect">Description</label>
                <textarea 
                  className='form-control p2o-form-field' 
                  value={description}
                  onChange={event => setDescription(event.target.value)} 
                />
            </div>
            <div className='form-group'>
              <label>Project Design Photos</label>
              <DragDrop 
                fileURLs={projectDesignPhotosFileURLs}
                _fileURLs={projectDesignImagesUrls}
                handleChange={handleProjectDesignPhotos}
                handleRemovePhoto={handleRemovePhoto}
                _handleRemovePhoto={_handleRemovePhoto}
              />
            </div>
            <div class="form-group">
                <label for="landSizeControlSelect">Intended Land Size</label>
                <input 
                  className='form-control p2o-form-field' 
                  value={intendedLandSize}
                  onChange={event => setIntendedLandSize(event.target.value)} 
                />
            </div>
            <div class="form-group">
                <label for="landLocationControlSelect">Intended Land Location</label>
                <input 
                  className='form-control p2o-form-field' 
                  value={intendedLandLocation} 
                  onChange={event => setIntendedLandLocation(event.target.value)}
                />
            </div>
            <div className='form-group'>
                <label>Link Proposed Lands</label>
                <AutoSuggest 
                  placeholder='Choose a Proposed Land to Link to this Opportunity...'
                  options={proposedLands}
                  selected={selectedProposedLands}
                  setSelected={setSelectedProposedLands}
                  className='form-control p2o-form-field'
                  ref={ref}
                />
            </div>
            <div class="form-group">
                <label for="numOfParticipantsControlSelect">
                  Number of Participants Required
                </label>
                <input 
                  className='form-control p2o-form-field' 
                  value={numOfParticipants} 
                  onChange={event => setNumOfParticipants(event.target.value)}
                />
            </div>
            <div class="form-group">
                <label for="estimatedCostPerParticipantControlSelect">
                  Estimated Contribution Per Participant
                </label>
                <input 
                  className='form-control p2o-form-field' 
                  value={estimatedCostPerParticipant} 
                  onChange={event => setEstimatedCostPerParticipant(event.target.value)}
                />
            </div>
            <button type='submit' className='btn btn-primary p2o-form-btn'>
                {isLoading ? <LoadingIndicator /> : 'Submit'}
            </button>
        </form>
    </div>
  )
}

export default EditOpportunityForm