import React, {useState, useEffect} from 'react'
import Nav from '../components/Nav';
import Footer from '../components/Footer';
import LoadingIndicator from '../components/LoadingIndicator'
import { Helmet } from 'react-helmet';
import CustomLightbox from '../components/CustomLightbox';
import { useSelector } from 'react-redux';
import { useProject } from '../hooks/useProject';
import Project from '../components/Project';
import { useParams } from 'react-router-dom';

function ViewProject() {
    const user = useSelector(state => state.user)

    const {projId} = useParams()

    const [project, setProject] = useState(null)
    const [isLoading, setIsLoading] = useState(true)

    const [openLightbox, setOpenLightbox] = useState(false)
    const [slides, setSlides] = useState([])

    const {getProject} = useProject()

    useEffect(() => {
        (async () => {
            const proj = await getProject(projId)
            setProject(proj)
            setIsLoading(false)
        })()
    }, [])

    const handleOpenLightbox = projectDesignImages => {
        setOpenLightbox(true)
        const images = []
        projectDesignImages.forEach(img => {
            images.push({
                src: img,
                alt: "Building Model Image",
                width: 3840,
                height: 2560,
                srcSet: [
                    { src: img, width: 320, height: 213 },
                    { src: img, width: 640, height: 427 },
                    { src: img, width: 1200, height: 800 },
                    { src: img, width: 2048, height: 1365 },
                    { src: img, width: 3840, height: 2560 },
                ],
            })
        })
        setSlides(images)
    }

  return (
    <div>
        <Helmet>
            <title>Peer2Own -  Project</title>
        </Helmet>
        <Nav />
        <br />
        <div className='container'>
            <CustomLightbox 
                open={openLightbox} 
                setOpen={setOpenLightbox} 
                slides={slides}
            />
            {
              project ?
              <Project project={project} /> :
              (
                isLoading ? 
                <div className='text-center'>
                  <LoadingIndicator color='#000'/>
                </div> :
                <div className='h3 text-center'>
                  Selected Project does not exist.
                </div>
              ) 
            }
        </div>
        <br />
        <Footer />
    </div>
  )
}

export default ViewProject