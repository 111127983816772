import React, {useState, useEffect} from 'react'
import LoadingIndicator from './LoadingIndicator'
import Toast from './Toast'
import DragDrop from './DragDropFile'
import { useLandProposal } from '../hooks/useLandProposal'

function EditLandProposalForm({landProposal}) {
    const [landLocation, setLandLocation] = useState(landProposal?.landLocation || '')
    const [landSize, setLandSize] = useState(landProposal?.landSize || '')
    const [landCost, setLandCost] = useState(landProposal?.landCost || '')
    const [landTitle, setLandTitle] = useState(landProposal?.landTitle || '')
    const [landPhotosFiles, setLandPhotosFiles] = useState([]);
    const [landPhotosFileURLs, setLandPhotosFileURLs] = useState([]);
    const [landPhotos, setLandPhotos] = useState(landProposal?.landPhotos || []);
   
    const [isLoading, setIsLoading] = useState(false)
    const [successMessage, setSuccessMessage] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)

    const {updateLandProposal, uploadLandPhotos} = useLandProposal()

    useEffect(() => {
      const newfileURLs = []
      landPhotosFiles.forEach(file => newfileURLs.push(
        {
          file: file,
          fileURL: URL.createObjectURL(file)
        }
      ))
      setLandPhotosFileURLs(newfileURLs)
    }, [landPhotosFiles, setLandPhotosFiles])

  const handleLandPhotos = _files => {
      try {
        if(landPhotosFiles.length >= 5) throw new Error("The max number of images you can upload is 5.")
        const filesFromList = Object.values(_files)
        setLandPhotosFiles(prev => {
          const newFiles = []
          filesFromList.forEach(file => newFiles.push(file))
          return [...newFiles, ...prev]
        })
      } catch (error) {
        setErrorMessage(error.message)
      }
  };

  const handleRemovePhoto = (photoUrl, file) => {
    const newLandPhotosFileURLs = landPhotosFileURLs.filter(url => url.fileURL !== photoUrl)
    const newLandPhotosFiles = landPhotosFiles.filter(f => f !== file)

    setLandPhotosFileURLs(newLandPhotosFileURLs)
    setLandPhotosFiles(newLandPhotosFiles)
  }

  const _handleRemovePhoto = async imageUrl => {
    const confirm = window.confirm("Are you sure you want to delete this photo?")
    if(!confirm) return;
    const filter = landPhotos.filter(url => url !== imageUrl)
    setLandPhotos(filter)
    await updateLandProposal(landProposal.id, {landPhotos: filter})
  }

    useEffect(() => {
        const newfileURLs = []
        landPhotosFiles.forEach(file => newfileURLs.push(URL.createObjectURL(file)))
        setLandPhotosFileURLs(newfileURLs)
    }, [landPhotosFiles, setLandPhotosFiles])

    const validateFormInput = () => {
      if(landLocation === '') throw new Error("Enter Land Location")
      if(landSize === '') throw new Error("Enter Land Size")
      if(landCost === '') throw new Error("Enter Land Cost")
      if(landTitle === '') throw new Error("Select Land Title")

      return true
    }  

    const handleSubmit = async event => {
        try {
            event.preventDefault()
            setIsLoading(true)
            if(!validateFormInput()) return;

            const updatedLandProposal = {
                landLocation,
                landSize,
                landCost,
                landTitle
            }

            const landProposalId = await updateLandProposal(landProposal.id, updatedLandProposal)
            if(landPhotosFiles.length > 0) await uploadLandPhotos(landProposalId, landPhotosFiles)
            setSuccessMessage("Land Proposal Updated.")
            setIsLoading(false)
        } catch (error) {
            setErrorMessage(error.message)
            setIsLoading(false)
        }
    }
  return (
    <div>
         {
          successMessage &&
          <Toast message={successMessage} setSuccess={setSuccessMessage}/>
        }
        {
          errorMessage &&
          <Toast message={errorMessage} setError={setErrorMessage}/>
        }
        <form onSubmit={handleSubmit}>
            <div className="form-group">
                <label for="landLocationControlInput">Land Location</label>
                <input 
                    id='landLocationControlInput'
                    className='form-control p2o-form-field' 
                    value={landLocation}
                    onChange={event => setLandLocation(event.target.value)} 
                />
            </div>
            <div className="form-group">
                <label for="landSizeControlInput">Land Size</label>
                <input 
                    id='landSizeControlInput'
                    className='form-control p2o-form-field' 
                    value={landSize}
                    onChange={event => setLandSize(event.target.value)} 
                />
            </div>
            <div className="form-group">
                <label for="landSizeControlInput">Cost of Land</label>
                <input 
                    id='landCostControlInput'
                    className='form-control p2o-form-field' 
                    value={landCost}
                    onChange={event => setLandCost(event.target.value)} 
                />
            </div>
            <div className='form-group'>
                <label for="landTitleControlSelect">Land Title</label>
                <select 
                    id='landTitleControlSelect'
                    className='form-control' 
                    value={landTitle}
                    onChange={event => setLandTitle(event.target.value)} 
                >
                    <option value="">Select Land Title</option>
                    <option value="Certificate of Occupancy">Certificate of Occupancy</option>
                    <option value="Governor's Consent">Governor's Consent</option>
                    <option value ="Deeds">Deeds</option>
                </select>
            </div>
            <div className='form-group'>
              <label>Land Photos</label>
              <DragDrop 
                fileURLs={landPhotosFileURLs}
                _fileURLs={landPhotos}
                handleChange={handleLandPhotos}
                handleRemovePhoto={handleRemovePhoto}
                _handleRemovePhoto={_handleRemovePhoto}
              />
            </div>
            <button type='submit' className='btn btn-primary p2o-form-btn'>
                {isLoading ? <LoadingIndicator /> : 'Submit'}
            </button>
        </form>
    </div>
  )
}

export default EditLandProposalForm