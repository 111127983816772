import React from 'react'
import { Link } from 'react-router-dom'

function ProposedLandForOpp({proposedLand, handleOpenLightbox}) {
  return (
    <div className='col-md-4'>
        <div className="card">
            <Link onClick={handleOpenLightbox}>
                <img 
                    src={proposedLand.landPhotos[0]} 
                    className="card-img-top" 
                    alt="Proposed Land" 
                    style={{width: '100%', height: '200px', objectFit: 'cover'}}
                />
            </Link>
            <div className="card-body">
                <h5 className="card-title">
                    <strong>Land Title: </strong> <br />
                    {proposedLand.landTitle}
                </h5>
                <p className="card-text">
                    {
                        proposedLand.verified ? 
                        <span className='badge badge-success'>Verified</span> :
                        <span className='badge badge-danger'>Unverified</span>
                    }
                </p>
            </div>
            <ul className="list-group list-group-flush">
                <li className="list-group-item">
                    <strong>Size</strong> <br />
                    {proposedLand.landSize}
                </li>
                <li className="list-group-item">
                    <strong>Location</strong> <br />
                    {proposedLand.landLocation}
                </li>
                <li className="list-group-item">
                    <strong>Cost</strong> <br />
                    ₦{parseInt(proposedLand.landCost).toLocaleString()}
                </li>
            </ul>
            <div className="card-body">
                <Link className="btn btn-primary p2o-form-btn">Vote</Link>
            </div>
        </div>
    </div>
  )
}

export default ProposedLandForOpp