import React from 'react'
import NavBar from '../components/Nav'
import Footer from '../components/Footer'

function FAQs() {
  return (
    <>
        <NavBar />
        <br />
        <div className='container'>
            <h2 className='p2o-page-title'>Frequently Asked Questions</h2>
        </div>
        <br />
        <Footer />
    </>
  )
}

export default FAQs