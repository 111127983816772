const { createSlice } = require("@reduxjs/toolkit")

const initialState = {
   exists: false,
   message: null
}

const ErrorSlice = createSlice({
    name: 'error',
    initialState,
    reducers: {
        setErrorState(state, action) {
            const newstate = action.payload
            return newstate
        }
    }
})

module.exports = {
    ErrorSlice,
}    