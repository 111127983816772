import React from 'react'
import { Link } from 'react-router-dom'
import { useLandProposal } from '../hooks/useLandProposal'
import { usePreference } from '../hooks/usePreference'
import { useSelector } from 'react-redux'

function LandProposal({sn, landProposal, handleOpenLightbox}) {
    const user = useSelector(state => state.user);

    const {
        getGlobalPreference,
        updateGlobalPreference
    } = usePreference()
    const {
        acceptLandProposal, 
        ignoreLandProposal,
        deleteLandProposal
    } = useLandProposal()

    const handleAccept = async () => {
        const confirm = window.confirm("Are you sure you want to accept this land proposal?")
        if(!confirm) return;
        if(landProposal.accepted) return;
        await acceptLandProposal(landProposal.id)

        if(landProposal.globalPrefId) {
            const globalPref = await getGlobalPreference(landProposal.globalPrefId)
            await updateGlobalPreference(
                landProposal.globalPrefId,
                { proposedLands: [landProposal.id, ...globalPref.proposedLands] }
            )
        }
    }

    const handleIgnore = async () => {
        const confirm = window.confirm("Are you sure you want to ignore this land proposal?")
        if(!confirm) return;
        await ignoreLandProposal(landProposal.id)

        if(landProposal.globalPrefId) {
            const globalPref = await getGlobalPreference(landProposal.globalPrefId)
            if(!globalPref.proposedLands.includes(landProposal.id)) return; 

            const filteredProposedLands = globalPref.proposedLands.filter(
                landProposalId => landProposalId !== landProposal.id
            )
            await updateGlobalPreference(
                landProposal.globalPrefId,
                { proposedLands: filteredProposedLands }
            )
        }
    }

    const handleDelete = async () => {
        const confirm = window.confirm("Are you sure you want to delete this land proposal?")
        if(!confirm) return;
        await deleteLandProposal(landProposal.id)
    }
  return (
    <tr className='p2o-table-row'>
      <th scope="row">{sn}</th>
      <td>{landProposal?.landLocation}</td>
      <td>{landProposal?.landSize}</td>
      <td>₦{ parseInt(landProposal?.landCost).toLocaleString() }</td>
      <td>{landProposal?.globalPrefId}</td>
      <td>
            <Link onClick={handleOpenLightbox} className='btn btn-sm cta-btn text-white'>
                View Photos
            </Link>
      </td>
      {
        user.role === 'admin' &&
        <>
            <td>
                {
                    landProposal?.accepted ? 'Accepted' :
                    <Link onClick={handleAccept} className='btn btn-sm btn-success'>
                        Accept
                    </Link>
                }  
            </td>
             <td>
                {
                    landProposal?.ignored ? 'Ignored' :
                    <Link onClick={handleIgnore} className='btn btn-sm btn-danger'>
                        Ignore
                    </Link>
                }    
            </td>
        </>
      }
      <td className='text-center'>
        <Link to={`/land-proposals/${landProposal.id}/edit`} className='btn btn-sm btn-primary cta-btn'>
            Edit
        </Link>
        <br/>
        <Link onClick={handleDelete} className='btn btn-sm btn-default'>
            Delete
        </Link>
      </td>
    </tr>
  )
}

export default LandProposal