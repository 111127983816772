import React from 'react'

function LandProposalsFilter({searchQuery, filterValue, handleSearch, handleFilterLandProposals}) {
  return (
    <div className='row g-3'>
        <div className='col-sm-9'>
            <input 
                value={searchQuery} 
                onChange={handleSearch} 
                className='form-control' 
                placeholder='Search Land Proposals'
            />
       </div>
        <div className='col-sm'>
            <select value={filterValue} onChange={handleFilterLandProposals} className='form-control'>
                <option value="all">All Land Proposals</option>
                <option value="pending">Pending Land Proposals</option>
                <option value="accepted">Accepted Land Proposals</option>
                <option value="ignored">Ignored Land Proposals</option>
            </select>
        </div>
    </div>
  )
}

export default LandProposalsFilter