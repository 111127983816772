import React, {useState, useEffect} from 'react'
import Project from './Project';
import { useProject } from '../hooks/useProject';
import LoadingIndicator from './LoadingIndicator';

function AllProjects() {
    const [projects, setProjects] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    const {watchAllProjects} = useProject()

    useEffect(() => {
        (async () => {
            await watchAllProjects(setProjects)
            setIsLoading(false)
        })()
    }, [])
  
  return (
    <>
        {
            isLoading ?
            <div className='text-center'>
                <LoadingIndicator color='#000' />
            </div> :
            (
                projects.length > 0 ?
                projects.map((project, index) => <Project key={index} project={project} />) :
                <div className='h3 text-center'>
                    No projects have been created yet.
                </div>
            ) 
        }
    </>
  )
}

export default AllProjects