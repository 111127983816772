import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import Toast from './Toast'
import InviteFromQueueModalToast from './Toast'
import ParticipantsModalToast from './Toast'
import EditModalToast from './Toast'
import ConfirmJoinModalToast from './Toast'
import CustomLightbox from './CustomLightbox';
import { usePreference } from '../hooks/usePreference'
import { useOpportunity } from '../hooks/useOpportunity'
import { useProject } from '../hooks/useProject';
import { useUser } from '../hooks/useUser';
import { useSelector } from 'react-redux'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import LoadingIndicator from './LoadingIndicator';

function Project({project}) {
    const user = useSelector(state => state.user)

    const [globalPref, setGlobalPref] = useState(null)
    const [buildingType, setBuildingType] = useState(null)
   
    const [userIsAProjectParticipant, setUserIsAProjectParticipant] = useState(false)
    const [successMessage, setSuccessMessage] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)

    const [openLightbox, setOpenLightbox] = useState(false)
    const [slides, setSlides] = useState([])

    const [showInviteModal, setShowInviteModal] = useState(false)
    const [showParticipantsModal, setShowParticipantsModal] = useState(false)
    const [showEditModal, setShowEditModal] = useState(false)
    const [showConfirmJoinModal, setShowConfirmJoinModal] = useState(false)

    const {globalPrefId} = project

    const {getBuildingType} = useOpportunity();
    const {getGlobalPreference} = usePreference();

    const {
        joinProject,
        markInviteAsAccepted,
    } = useProject()

    const {
        checkIfUserIsAProjectParticipant
    } = useProject()

    useEffect(() => {
        (async () => {
            const globalPref = await getGlobalPreference(globalPrefId);
            setGlobalPref(globalPref)

            const isParticipant = checkIfUserIsAProjectParticipant(
                user.uid, 
                project.participants
            )
            setUserIsAProjectParticipant(isParticipant)
        })()
    }, [])

    useEffect(() => {
        (async() => {
            if(!globalPref) return;
            const buildingType = await getBuildingType(globalPref.propertyType)
            setBuildingType(buildingType)
        })()
    }, [globalPref])

    const handleOpenInviteModal = () => setShowInviteModal(true)
    const handleCloseInviteModal = () => setShowInviteModal(false)

    const handleOpenParticipantsModal = () => setShowParticipantsModal(true)
    const handleCloseParticipantsModal = () => setShowParticipantsModal(false)

    const handleOpenEditModal = () => setShowEditModal(true)
    const handleCloseEditModal = () => setShowEditModal(false)

    const handleOpenConfirmJoinModal = () => setShowConfirmJoinModal(true)
    const handleCloseConfirmJoinModal= () => setShowConfirmJoinModal(false)

    const handleOpenLightbox = projectDesignImages => {
        setOpenLightbox(true)
        const images = []
        projectDesignImages.forEach(img => {
            images.push({
                src: img,
                alt: "Building Model Image",
            })
        })
        setSlides(images)
    }

  return (
    <>
        <InviteFromQueueModal 
            showInviteModal = {showInviteModal}
            handleClose = {handleCloseInviteModal}
            projId = {project.id}
            globalPrefId = {globalPrefId}
        />
        <ParticipantsModal 
            showParticipantsModal = {showParticipantsModal}
            handleClose = {handleCloseParticipantsModal}
            projId = {project.id}
            projectParticipants = {project.participants}
            successMessage = {successMessage}
        />
        <EditModal 
            showEditModal={showEditModal}
            handleClose={handleCloseEditModal}
            project={project}
        />
        <ConfirmJoinModal 
            showConfirmJoinModal={showConfirmJoinModal}
            handleClose={handleCloseConfirmJoinModal}
            project={project}
            globalPref={globalPref}
            setUserIsAProjectParticipant={setUserIsAProjectParticipant}
            joinProject={joinProject}
            markInviteAsAccepted={markInviteAsAccepted}
        />
      <div class="list-group">
        <CustomLightbox 
            open={openLightbox} 
            setOpen={setOpenLightbox} 
            slides={slides}
        />
        {
            errorMessage &&
            <Toast message={errorMessage} setError={setErrorMessage}/>
        }
        <div class="opp-card list-group-item list-group-item-action flex-column align-items-start">
            <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1 text-black">
                    <strong>{project?.title}</strong>
                    &nbsp;
                    {
                        user.role === 'admin' &&
                        <>
                            <Link
                                onClick={handleOpenInviteModal}
                                className='btn btn-danger btn-sm cta-btn-transparent'
                            >
                                Invite From Queue
                            </Link>&nbsp;
                            <Link
                                onClick={handleOpenEditModal}
                                className='btn btn-danger btn-sm cta-btn-transparent'
                            >
                                Edit
                            </Link>
                        </>
                    }
                </h5>
                { 
                    !userIsAProjectParticipant ?
                    (
                        project.status === 'open' ?
                        <Link 
                            onClick={handleOpenConfirmJoinModal}
                            className='btn btn-danger btn-sm cta-btn'
                        >
                            Join Project
                        </Link> :
                        <button 
                            className='btn btn-primary cta-btn'
                            disabled={true}
                        >
                            Join Project
                        </button>
                    ) :
                    <span className='badge badge-primary opp-card-badge-green'>
                        Joined
                    </span>
                }
            </div>
            <br />
            <p className="mb-1 text-black">{project?.description}</p>
            <br/>
            <div className='row'>
                <div className='col-md-4' style={{padding: '8px'}}>
                    <Link onClick={() => handleOpenLightbox(buildingType.images)}>
                        {
                        buildingType && buildingType.images.length > 0 &&
                            <img 
                                className='opp-img'
                                style={{width: '100%', height: '100%', objectFit: 'cover'}}
                                src={buildingType.images[0]} 
                                alt='Project Model Images' 
                            />
                        }
                    </Link>
                    <br />
                </div>
                <div className='col' style={{padding: '8px'}}>
                    <ul className="list-group">
                        <li className='opp-card-item list-group-item d-flex justify-content-between align-items-center'>
                            <span className='text-black'>
                                <strong>
                                    Number of Participants Target
                                </strong>
                            </span>
                            <span className="badge badge-primary opp-card-badge-green text-uppercase">
                                <strong>{project?.numOfParticipantsRequired}</strong>
                            </span>
                        </li>
                        <li className='opp-card-item list-group-item d-flex justify-content-between align-items-center'>
                            <span>
                                Be among the {project.numOfParticipantsRequired} people to
                                participate in this project. The window will be closed once we have {project.numOfParticipantsRequired} people. Join only if you have the minimum amount required 
                                to begin in a week.
                            </span>
                        </li>
                        <li className='opp-card-item list-group-item d-flex justify-content-between align-items-center'>
                            <span className='text-black'>
                                <strong>
                                    Current Number of Participants
                                </strong>
                            </span>
                            {
                                user.isSubscribed ?
                                <div>
                                    <span className="badge badge-primary opp-card-badge-green">
                                        {project?.participants.length}
                                    </span> 
                                    {
                                        user.role === 'admin' &&
                                        <> 
                                            &nbsp;
                                            <Link 
                                                onClick={handleOpenParticipantsModal}
                                                className='btn btn-sm btn-success'
                                            >
                                                View
                                            </Link>
                                        </>
                                    }
                                </div>
                                :
                                <Link to='/subscribe' className='btn btn-success btn-sm'>
                                    Subscribe to View
                                </Link>
                            }
                        </li>
                        <li className='opp-card-item list-group-item d-flex justify-content-between align-items-center'>
                            <span className='text-black'>
                                <strong>
                                    Estimated Cost Per Participant
                                </strong>
                            </span>
                            <span className="badge badge-primary opp-card-badge-green">
                                ₦{parseInt(project?.estimatedCostPerParticipant).toLocaleString()}
                            </span>
                        </li>
                        <li className='opp-card-item list-group-item d-flex justify-content-between align-items-center'>
                            <span className='text-black'>
                                <strong>
                                    Project Status
                                </strong>
                            </span>
                            <span className="badge badge-primary opp-card-badge-green text-uppercase">
                                <strong>{project?.status}</strong>
                            </span>
                        </li>
                        {
                           project?.status === 'open' &&
                           <li className='opp-card-item list-group-item d-flex justify-content-between align-items-center'>
                                <span>
                                    When a project is open, you can join it 
                                    and make payment. Once the project status changes, payments and 
                                    invites won't be allowed.
                                </span>
                            </li>
                        }
                        {
                           project?.status === 'in progress' &&
                           <li className='opp-card-item list-group-item d-flex justify-content-between align-items-center'>
                                <span>
                                    You can no longer join this project. Please 
                                </span>
                            </li>
                        }
                        <li className='opp-card-item list-group-item d-flex justify-content-between align-items-center'>
                            <span className='text-black'>
                                <strong>
                                    Global Pref ID
                                </strong>
                            </span>
                            <Link 
                                to={'/join-opportunity/' + project?.globalPrefId}
                                className="badge badge-primary opp-card-badge-green text-uppercase"
                            >
                                <strong>{project?.globalPrefId}</strong>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div> 
        </div>
        <br/>
    </div>
    </>
    )
}

const InviteFromQueueModal = props => {
    const {
        showInviteModal, 
        handleClose,
        projId,
        globalPrefId
    } = props

    const [participantsDetails, setParticipantsDetails] = useState([])
    const [sendingInvite, setSendingInvite] = useState(false)
    const [successMessage, setSuccessMessage] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)

    const {getGlobalPreference} = usePreference();
    const {getUser} = useUser();
    const {checkIfInviteHasBeenSent, sendInvite} = useProject();

    useEffect(() => {
        (async () => {
            const globalPref = await getGlobalPreference(globalPrefId)
            const participantsDetails = []
            globalPref?.participantsQueue.forEach(async participantUid => {
                const user = await getUser(participantUid)
                const inviteSentBefore = await checkIfInviteHasBeenSent(user?.uid, projId)
                const participantDetail = {
                    uid: user.uid,
                    emailAddress: user.emailAddress,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    phoneNumber: user.phoneNumber,
                    inviteSentBefore: inviteSentBefore
                }
                participantsDetails.push(participantDetail)
            })
            setParticipantsDetails(participantsDetails)
        })()
    }, [])

    const handleSendInvite = async uid => {
        try {
            setSendingInvite(true)
            await sendInvite(uid, globalPrefId, projId)
        
            setSuccessMessage("Invite Sent")
            setSendingInvite(false)
        } catch (error) {
            setErrorMessage(error.message)
            setSendingInvite(false)
        }
    }

    return (
            <Modal
                size='lg'
                show={showInviteModal}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                fullscreen={true}
            >
                {
                    successMessage &&
                    <InviteFromQueueModalToast 
                        message={successMessage} 
                        setSuccess={setSuccessMessage}
                        position='top-center'
                    />
                }
                {
                    errorMessage &&
                    <InviteFromQueueModalToast 
                        message={errorMessage} 
                        setError={setErrorMessage}
                        position='top-center'
                    />
                }
                <Modal.Header closeButton>
                    <Modal.Title>
                        <span className='text-black'>
                            <strong>Send Invites To People In Queue</strong>
                        </span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        { 
                                participantsDetails?.length < 1 ?
                                <div className='text-center'>
                                    There's no one in the Queue
                                </div> :
                                <div className='table-responsive'>
                                <table className="table table-borderless table-sm table-striped p2o-table text-center">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Email Address</th>
                                            <th scope="col">First Name</th>
                                            <th scope="col">Last Name</th>
                                            <th scope="col">Phone Number</th>
                                            <th scope='col'>&nbsp;</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        participantsDetails.map((participant, index) => (
                                                <tr>
                                                    <th scope="row">{index + 1}</th>
                                                    <td>{participant?.emailAddress}</td>
                                                    <td>{participant?.firstName}</td>
                                                    <td>{participant?.lastName}</td>
                                                    <td>{participant?.phoneNumber}</td>
                                                    <td>
                                                        { 
                                                            participant.inviteSentBefore ?
                                                            <span className='badge badge-primary opp-card-badge-green'>
                                                                Invite Sent
                                                            </span> :
                                                            (
                                                                successMessage ?
                                                                <span className='badge badge-primary opp-card-badge-green'>
                                                                    {successMessage}
                                                                </span> :
                                                                <Link
                                                                    onClick={() => handleSendInvite(participant?.uid)}
                                                                    className='btn btn-sm btn-primary cta-btn'
                                                                >
                                                                    {
                                                                        sendingInvite ?
                                                                        <LoadingIndicator 
                                                                            color='#ffffff'
                                                                        /> :
                                                                        'Invite'
                                                                    }
                                                                </Link>
                                                            )
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        )
                                    }
                                    </tbody>
                                </table>
                            </div>
                        }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
        </Modal>
    )
}

const ParticipantsModal = props => {
    const {
        showParticipantsModal,
        handleClose,
        projId,
        projectParticipants
    } = props

    const [participantsDetails, setParticipantsDetails] = useState([])
    const [successMessage, setSuccessMessage] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)
    
    const {getUser} = useUser()
    const {updateProject} = useProject()

    useEffect(() => {
        (async () => {
            const participantsDetails = []
            projectParticipants.forEach(async participant => {
                const user = await getUser(participant.uid)
                const participantDetail = {
                    uid: user.uid,
                    emailAddress: user.emailAddress,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    phoneNumber: user.phoneNumber,
                    hasMadePayment: participant.hasMadePayment ? "YES" : "NO"
                }
                participantsDetails.push(participantDetail)
            })
            setParticipantsDetails(participantsDetails)
        })()
    }, [])

    const updateParticipantsDetails = participantDetails => {
        try {
            const findIndex = participantsDetails.findIndex(
                participant => participant.uid === participantDetails.uid
            ) 
            const updatedParticipantsDetails = [
                ...participantsDetails.slice(0, findIndex),
                participantDetails,
                ...participantsDetails.slice(findIndex + 1)
            ] 
            setParticipantsDetails(updatedParticipantsDetails)
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const handleUpdateParticipants = async updatedParticipant => {
        try {
            const findIndex = projectParticipants.findIndex(
                participant => participant.uid === updatedParticipant.uid
            ) 
            const updatedParticipants = [
                ...projectParticipants.slice(0, findIndex),
                updatedParticipant,
                ...projectParticipants.slice(findIndex + 1)
            ] 
            await updateProject(
                projId,
                {participants: updatedParticipants}
            )
            setSuccessMessage("Update Successful")
        } catch (error) {
            setErrorMessage(error.message)
        }
    }

    return (
            <Modal
                size='lg'
                show={showParticipantsModal}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                fullscreen={true}
            >
                {
                    successMessage &&
                    <ParticipantsModalToast 
                        message={successMessage} 
                        setSuccess={setSuccessMessage}
                        position='top-center'
                    />
                }
                {
                    errorMessage &&
                    <ParticipantsModalToast 
                        message={errorMessage} 
                        setError={setErrorMessage}
                        position='top-center'
                    />
                }
                <Modal.Header closeButton>
                    <Modal.Title>
                        <span className='text-black'>
                            <strong>Participants</strong>
                        </span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        { 
                                participantsDetails?.length < 1 ?
                                <div className='text-center'>
                                    There are no participants
                                </div> :
                                <div className='table-responsive'>
                                <table className="table table-borderless table-sm table-striped p2o-table text-center">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Email Address</th>
                                            <th scope="col">First Name</th>
                                            <th scope="col">Last Name</th>
                                            <th scope="col">Phone Number</th>
                                            <th scope="col">Has Made Payment</th>
                                            <th scope='col'>&nbsp;</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        participantsDetails.map((participant, index) => (
                                                <ParticipantTableRow
                                                    key={index}
                                                    sn={index + 1}
                                                    participant={participant}
                                                    updateParticipantsDetails={updateParticipantsDetails}
                                                    handleUpdateParticipants={handleUpdateParticipants}
                                                    setErrorMessage={setErrorMessage}
                                                />
                                            )
                                        )
                                    }
                                    </tbody>
                                </table>
                            </div>
                        }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
        </Modal>
    )
}

const ParticipantTableRow = props => {
    const {
        participant, 
        sn, 
        updateParticipantsDetails, 
        handleUpdateParticipants,
        setErrorMessage
    } = props

    const [participantDetail, setParticipantDetails] = useState(participant)
    const [isLoading, setIsLoading] = useState(false)

    const handleHasMadePaymentChange = event => {
        const newParticipantDetails = {...participantDetail, hasMadePayment: event.target.value}
        setParticipantDetails(newParticipantDetails)
        updateParticipantsDetails(newParticipantDetails)
    }

    const handleUpdateParticipant = async () => {
        try {
            setIsLoading(true)
            const updatedParticipant = {
                uid: participant.uid,
                hasMadePayment: participantDetail?.hasMadePayment === "YES" ? true : false
            }
            await handleUpdateParticipants(updatedParticipant)
            setIsLoading(false)
        } catch (error) {
            setErrorMessage(error.message)
            setIsLoading(false)
        }
    }

    return (
            <tr>
                    <th scope="row">{sn}</th>
                    <td>{participant?.emailAddress}</td>
                    <td>{participant?.firstName}</td>
                    <td>{participant?.lastName}</td>
                    <td>{participant?.phoneNumber}</td>
                    <td>
                        <select
                            value={participantDetail?.hasMadePayment}
                            onChange={handleHasMadePaymentChange}
                            className='form-control' 
                        >
                            <option value="YES">YES</option>
                            <option value="NO">NO</option>
                        </select>
                    </td>
                    <td>
                        <Link
                            onClick={handleUpdateParticipant}
                            className='btn btn-sm btn-primary cta-btn'
                        >
                            {
                                isLoading ? 
                                <LoadingIndicator color='#fff' /> :
                                'Update'
                            }
                        </Link>
                    </td>
            </tr>
    )
}

const EditModal = props => {
    const {
        showEditModal,
        handleClose,
        project
    } = props

    const [title, setTitle] = useState(project?.title || '')
    const [description, setDescription] = useState(project?.description || '')
    const [numOfParticipantsRequired, setNumOfParticipantsRequired] = useState(project?.numOfParticipantsRequired || '')
    const [estimatedCostPerParticipant, setEstimatedCostPerParticipant] = useState(project?.estimatedCostPerParticipant || '')
    const [projectStatus, setProjectStatus] = useState(project?.status || '')
    const [isLoading, setIsLoading] = useState(false)
    const [successMessage, setSuccessMessage] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)
    
    const {updateProject} = useProject()

    const handleSubmit = async event => {
        try {
            event.preventDefault();
            setIsLoading(true)
            const updatedProject = {
                ...project,
                title: title,
                description: description,
                numOfParticipantsRequired: numOfParticipantsRequired,
                estimatedCostPerParticipant: estimatedCostPerParticipant,
                status: projectStatus
            }
            await updateProject(project.id, updatedProject)

            setSuccessMessage("Project Updated")
            setIsLoading(false)
        } catch (error) {
            setErrorMessage(error.message)
            setIsLoading(false)
        }
    }

    return (
            <Modal
                size='md'
                show={showEditModal}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                fullscreen={true}
            >
                {
                    successMessage &&
                    <EditModalToast 
                        message={successMessage} 
                        setSuccess={setSuccessMessage}
                        position='top-center'
                    />
                }
                {
                    errorMessage &&
                    <EditModalToast 
                        message={errorMessage} 
                        setError={setErrorMessage}
                        position='top-center'
                    />
                }
                <Modal.Header closeButton>
                    <Modal.Title>
                        <span className='text-black'>
                            <strong>Edit Project</strong>
                        </span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label for="titleControlSelect">Title</label>
                            <input 
                            className='form-control p2o-form-field' 
                            value={title}
                            onChange={event => setTitle(event.target.value)} 
                            />
                        </div>
                        <div className="form-group">
                            <label for="descriptionControlSelect">Description</label>
                            <textarea
                            className='form-control p2o-form-field'
                            value={description}
                            onChange={event => setDescription(event.target.value)} 
                            />
                        </div>
                        <div className="form-group">
                            <label for="numOfParticipantsControlSelect">
                            Number of Participants Required
                            </label>
                            <input 
                            className='form-control p2o-form-field' 
                            value={numOfParticipantsRequired} 
                            onChange={event => setNumOfParticipantsRequired(event.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <label for="estimatedCostPerParticipantControlSelect">
                                Estimated Contribution Per Participant
                            </label>
                            <input 
                                className='form-control p2o-form-field' 
                                value={estimatedCostPerParticipant} 
                                onChange={event => setEstimatedCostPerParticipant(event.target.value)}
                            />
                        </div>
                        <div className='form-group'>
                            <label for="projectStatusControlSelect">
                                Project Status
                            </label>
                            <select
                                value={projectStatus}
                                onChange={e => setProjectStatus(e.target.value)}
                                className='form-control' 
                            >
                                <option value="open">Open</option>
                                <option value="in progress">In Progress</option>
                                <option value="closed">Closed</option>
                            </select>
                        </div>
                        <button type='submit' className='btn btn-primary p2o-form-btn'>
                            {isLoading ? <LoadingIndicator /> : 'Submit'}
                        </button>
                    </form>
                </Modal.Body>
        </Modal>
    )
}

const ConfirmJoinModal = props => {
    const {
        showConfirmJoinModal,
        handleClose,
        project,
        globalPref,
        setUserIsAProjectParticipant,
        joinProject,
        markInviteAsAccepted,
    } = props

    const user = useSelector(state => state.user)
    const [isLoading, setIsLoading] = useState(false)
    const [successMessage, setSuccessMessage] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)

    const {removeUserFromParticipantsQueue} = useOpportunity()

    const handleJoinProject = async () => {
        try {
            setIsLoading(true)
            await joinProject(user.uid, project.id)
            await removeUserFromParticipantsQueue(user.uid, globalPref)
            await markInviteAsAccepted(user.uid, project.id)

            setSuccessMessage("Successful! You've Joined Project.")

            setUserIsAProjectParticipant(true)
            setIsLoading(false)
        } catch (error) {
            setErrorMessage(error.message)
            setIsLoading(false)
        }
    }

    return (
            <Modal
                className='modal red-modal'
                size='sm'
                show={showConfirmJoinModal}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                fullscreen={true}
            >
                {
                    successMessage &&
                    <ConfirmJoinModalToast
                        message={successMessage} 
                        setSuccess={setSuccessMessage}
                        position='top-center'
                    />
                }
                {
                    errorMessage &&
                    <ConfirmJoinModalToast
                        message={errorMessage} 
                        setError={setErrorMessage}
                        position='top-center'
                    />
                }
                <Modal.Header closeButton>
                    <Modal.Title>
                        <span className='text-black'>
                            <strong>Join Project Confirmation</strong>
                        </span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className='text-black popup-text'>
                        Please join only if you are financially ready with at least
                        <strong>
                        {
                            ` ₦${parseInt(project.estimatedCostPerParticipant).toLocaleString()}.`
                        }
                        </strong>
                    </p>
                    <p className='text-black popup-text'>
                        If you are not financially ready, you can always join the next project.
                    </p>
                    <p className='text-black popup-text'>
                        Once you click the "Confirm" button, you will be taken out of the 
                        opportunity queue.
                    </p>
                    <p className='text-black popup-text' >
                        Do you want to continue?
                    </p>
                    <Link
                        onClick={handleJoinProject}
                        className='btn btn-primary p2o-form-btn'
                    >
                        {
                            isLoading ?
                            <LoadingIndicator color='#fff' /> :
                            'Confirm'
                        }
                    </Link>
                </Modal.Body>
        </Modal>
    )
}


export default Project