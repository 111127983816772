import React from 'react'
import NavBar from '../components/Nav'
import Footer from '../components/Footer'
import ProposedLandsForOpp from '../components/ProposedLandsForOpp'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'

function ViewProposedLandsForOpp() {
    const {globalPrefId, oppId} = useParams()
  
  return (
    <div>
        <Helmet>
            <title>Peer2Own -  View Proposed Lands For Opportunity</title>
        </Helmet>
        <NavBar />
        <br />
        <div className='container'>
            <h2 className='p2o-page-title'>
                Proposed Lands
            </h2>
            <p>
                <small className='text-black'>
                    These are the proposed lands where this opportunity could be developed.
                </small>
            </p>
            <ProposedLandsForOpp 
                globalPrefId={globalPrefId}
                oppId={oppId}
            />
        </div>
        <br />
        <Footer />
    </div>
  )
}

export default ViewProposedLandsForOpp