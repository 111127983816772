import React, {useEffect, useState} from 'react'
import { useBlog } from '../hooks/useBlog'
import BlogPost from './BlogPost';
import { useDispatch } from 'react-redux';
import { BlogPostsActions } from '../redux/store';
import LoadingIndicator from './LoadingIndicator';

function BlogPosts() {
  const [posts, setPosts] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const {getAllPosts} = useBlog();

  const {setBlogPostsState} = BlogPostsActions

  const dispatch = useDispatch()

  useEffect(() => {
    (async () => {
      const posts = await getAllPosts();
      setPosts(posts)
      setIsLoading(false)
      dispatch(setBlogPostsState(posts))
    })()
  }, [])

  return (
    isLoading ?
    <div className='text-center'><LoadingIndicator color='#000' /></div> :
    <div className='row'>
        {posts.map((post, index) => <BlogPost key={index} post={post} />)}
    </div>
  )
}

export default BlogPosts