import React, {useState, useEffect} from 'react'
import Nav from '../components/Nav'
import Footer from '../components/Footer'
import { useSelector } from 'react-redux'
import { useSubscription } from '../hooks/useSubscription'
import LoadingIndicator from '../components/LoadingIndicator'
import { Helmet } from 'react-helmet'

function Subscribe() {
    const user = useSelector(state => state.user)
    const [plans, setPlans] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const {
      fetchSubscriptionPlans, 
      subscribeUser,
      fetchCustomer,
      createCustomer,
      updateCustomer
    } = useSubscription()

    useEffect(() => {
      (async () => {
        const plans = await fetchSubscriptionPlans()
        setPlans(plans)
        setIsLoading(false)

        const customer = await fetchCustomer(user.emailAddress)
        if(customer.email !== user.emailAddress) await createCustomer(user.uid, user.emailAddress)
        if(!customer.metadata.uid) await updateCustomer(
            customer.customer_code,
            {metadata: {uid: user.uid, ...customer.metadata}}
        )
      })()
    }, [])

    const calculateSavings = (amount, interval) => {
      try {
        const monthly_plan = plans.filter(plan => plan.interval === 'monthly')
        const annualized_monthly_plan_amount = monthly_plan[0].amount * 12

        let savings;
        if(interval === 'quarterly') {
          const annualized_quarterly_plan_amount = amount * 4
          savings =  annualized_monthly_plan_amount -  annualized_quarterly_plan_amount
        }

        if(interval === 'annually') {
          savings =  annualized_monthly_plan_amount -  amount
        }

        return parseInt(savings / 100).toLocaleString();
      } catch (error) {
        throw new Error(error.message)
      }
    }

    const handleSubscribe = async (amount, plan_code = null) => {
      try {
        const params = {
          uid: user.uid,
          emailAddress: user.emailAddress, 
          amount, 
          plan_code
        }
        await subscribeUser(params)
      } catch (error) {
        console.log(error.message)
      }
    }

    
  return (
    <div>
        <Helmet>
            <title>Peer2Own -  Subscribe</title>
        </Helmet>
        <Nav />
        <div>
            <div className='jumbotron text-center p2o-header'>
              <h2 className='text-white text-capitalize'>
                Subscribe to join opportunities to start a project with Peers & More
              </h2><br />
              <h5 className='text-white text-capitalize'>
                Choose one of the subscription plans below
              </h5>
            </div>
            <div className='container card benefits-of-subscribing-card'>
                <div className='row'>
                  {
                    isLoading &&
                    <div className='text-center'>
                      <LoadingIndicator color='#000'/>
                    </div>
                  }
                  <div className='col-md-6'>
                    <div className='payment-plan'>
                      <h3 className='text-center'>
                        <strong>Project Starter <br />Plan</strong>
                      </h3>
                      <h5 className='text-grey-2 text-center'>
                        <strong>One-Off Payment</strong>
                      </h5>
                      <ul className='benefits-of-subscribing text-black text-capitalize'>
                        <li>
                            Join Opportunities to connect with peers to start Projects that match 
                            your preferences Within A Month Of Subscription
                        </li>
                        <li>
                            Join Projects Open For Those Opportunities Even After Your Subscription 
                            Elapses
                        </li>
                        <li>
                            Receive Unlimited Notifications on Projects for Opportunities you have
                            joined.
                        </li>
                      </ul>
                      <ul className='benefits-of-subscribing not-included-in-plan text-black text-capitalize'>
                          <li>Propose Land for Opportunities.</li>
                          <li>
                            Track and Monitor the Progress of your Ongoing Real Estate Project.
                          </li>
                      </ul>
                      <div className='text-center'>
                          <h3 className='text-grey-2 text-large-bold text-center'>
                            ₦{(parseInt(2000000) / 100).toLocaleString()}
                          </h3>
                          <button 
                            onClick={() => handleSubscribe(2000000)} 
                            className ='btn btn-primary cta-btn'
                          >
                            Subscribe
                          </button>
                      </div>
                    </div>
                  </div> 
                  { 
                    plans.map(plan => {
                      return (
                        <div key={plan.plan_code} className='col-md-6'>
                          <div className='payment-plan'>
                            <h3 className='text-center'>
                              <strong>Project Management / Land Sales Plan</strong>
                            </h3>
                            <h5 className='text-grey-2 text-center'>
                              <strong>{plan.name} Payment</strong>
                            </h5>
                            <ul className='benefits-of-subscribing text-black text-capitalize'>
                              {
                                plan.interval !== 'monthly' &&
                                <li>
                                    Save ₦{calculateSavings(plan.amount, plan.interval)}
                                </li>
                              }
                              <li>
                                  Join Opportunities to connect with peers to start Projects that 
                                  match your preferences Within A Month Of Subscription
                              </li>
                              <li>
                                  Join Projects Open For Those Opportunities Even After Your 
                                  Subscription Elapses
                              </li>
                              <li>
                                  Receive Unlimited Notifications on Projects for Opportunities you 
                                  have joined.
                              </li>
                            </ul>
                            <ul className='benefits-of-subscribing text-black text-capitalize'>  
                              <li>Propose Land for Opportunities.</li>
                              <li>
                                Track and Monitor the Progress of your Ongoing Real Estate Project.
                              </li>
                            </ul>
                            <div className='text-center'>
                              <h3 className='text-grey-2 text-large-bold'>
                                ₦{(parseInt(plan.amount) / 100).toLocaleString()}
                              </h3>
                              <button 
                                onClick={() => handleSubscribe(plan.amount, plan.plan_code)} 
                                className ='btn btn-primary cta-btn'
                              >
                                Subscribe
                              </button>
                            </div>
                          </div> 
                        </div>
                      )
                    })
                  }
                </div>
            </div>
        </div>
        <br />
        <Footer />
    </div>
  )
}

export default Subscribe