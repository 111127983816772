import React from 'react'
import PulseLoader from "react-spinners/PulseLoader";

function LoadingIndicator({
    loading = true, 
    color = "#ffffff", 
    cssOverride = {}, 
    speedMulitipler = 1
}) {
  return (
    <PulseLoader
        color={color}
        loading={loading}
        cssOverride={cssOverride}
        size={15}
        aria-label="Loading Spinner"
        data-testid="loader"
    />
  )
}

export default LoadingIndicator