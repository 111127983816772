import React from 'react'
import { Link } from 'react-router-dom'
import { useOpportunity } from '../hooks/useOpportunity'

function Request({sn, request}) {
    const {approveJoinRequest, declineJoinRequest} = useOpportunity()

    const handleApprove = async () => {
        const confirm = window.confirm("Are you sure you want to approve this request?")
        if(!confirm) return;
        await approveJoinRequest(request)
    }

    const handleDecline = async () => {
        const confirm = window.confirm("Are you sure you want to decline this request?")
        if(!confirm) return;
        await declineJoinRequest(request)
    }
  return (
    <tr className='p2o-table-row'>
      <th scope="row">{sn}</th>
      <td>{request?.emailAddress}</td>
      <td>{request?.globalPrefId}</td>
      <td>
            <Link to={`${request?.proofOfFundsUrl}`} target='_blank' className='btn btn-sm cta-btn text-white'>
                View Proof-of-Funds
            </Link>
      </td>
      <td>
            {
                request?.approved ? 'Approved' :
                <Link onClick={handleApprove} className='btn btn-sm btn-success'>
                    Approve
                </Link>
            }  
      </td>
      <td>
            {
                request?.declined ? 'Declined' :
                <Link onClick={handleDecline} className='btn btn-sm btn-danger'>
                    Decline
                </Link>
            }    
      </td>
    </tr>
  )
}

export default Request