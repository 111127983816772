import React from 'react'
import Nav from '../components/Nav'
import Footer from '../components/Footer'
import ChangePasswordForm from '../components/ChangePasswordForm'
import { Helmet } from 'react-helmet'

function ChangePassword() {
  return (
    <div>
        <Helmet>
            <title>Peer2Own - Change Password</title>
        </Helmet>
        <Nav />
        <br />
        <div className='container'>
            <div className='row justify-content-center'>
                <div className='col-md-6 p2o-form'>
                    <h2 className='p2o-form-title'>Change Password</h2>
                    <hr className='p2o-form-title-divider' />
                    <ChangePasswordForm />
                </div>
            </div>
        </div>
        <br />
        <Footer />
    </div>
  )
}

export default ChangePassword