import React from 'react'
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
    Outlet
} from 'react-router-dom'
import Welcome from './pages/Welcome';
import SignUp from './pages/SignUp';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import Home from './pages/Home';
import ViewOpportunities from './pages/ViewOpportunities';
import SendJoinRequest from './pages/SendJoinRequest';
import Subscribe from './pages/Subscribe';
import EditProfile from './pages/EditProfile';
import ChangePassword from './pages/ChangePassword';
import ViewAllUsers from './pages/ViewAllUsers';
import UnAuthorized from './pages/UnAuthorized';
import ViewGlobalPrefs from './pages/ViewGlobalPrefs';
import EditOpportunity from './pages/EditOpportunity';
import ViewRequests from './pages/ViewRequests';
import ProposeLand from './pages/ProposeLand';
import ViewParticipants from './pages/ViewParticipants';
import ViewLandProposals from './pages/ViewLandProposals';
import EditLandProposal from './pages/EditLandProposal';
import ViewProposedLandsForOpp from './pages/ViewProposedLandsForOpp';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import FAQs from './pages/FAQs';
import CreateProject from './pages/CreateProject';
import ViewMyProjects from './pages/ViewMyProjects';
import ViewAllProjects from './pages/ViewAllProjects';
import ViewProject from './pages/ViewProject';
import LandSellers from './pages/LandSellers';
import ManageSubscription from './pages/ManageSubscription';
import ViewMyLandProposals from './pages/ViewUserLands';
import ViewBlog from './pages/ViewBlog';
import ViewBlogPost from './pages/ViewBlogPost';

import { useSelector } from 'react-redux';


const ProtectedRouteLoggedIn = ({
  user,
  redirectPath = '/my-preferences',
  children,
}) => {
  if (user?.loggedIn) return <Navigate to={redirectPath} replace />;

  return children? children : <Outlet />;
};

const ProtectedRouteLoggedOut = ({
  user,
  redirectPath = '/',
  children,
}) => {
if (!user?.loggedIn) return <Navigate to={redirectPath} replace />;

  return children? children : <Outlet />;
};

const ProtectedAdminRouteLoggedOut = ({
  user,
  redirectPath = '/',
  children,
}) => {
  if (!user?.loggedIn) return <Navigate to={redirectPath} replace />;

  if(user.role !== 'admin') return <Navigate to='/unauthorized' replace />;

  return children? children : <Outlet />;
};


function AppRouter() {
  const user = useSelector(state => state.user)

  return (
        <Router>
            <Routes>
              <Route path='/' element={<Welcome />} />
              <Route path='/:referrerId' element={<Welcome />} />
              <Route path='/land-sellers' element={<LandSellers />} />
              <Route path='/terms' element={<Terms />} />
              <Route path='/privacy' element={<Privacy />} />
              <Route path='/faqs' element={<FAQs />} />
              <Route path='/blog' element={<ViewBlog />} />
              <Route path='/blog/:slug/:id' element={<ViewBlogPost />} />
              <Route path='/unauthorized' element={<UnAuthorized />} />
              <Route element={<ProtectedRouteLoggedIn user={user} />}>
                <Route path='/signup' element={<SignUp />} />
                <Route path='/login' element={<Login />} />
                <Route path='/forgot-password' element={<ForgotPassword />} />
              </Route>
              <Route element={<ProtectedRouteLoggedOut user={user} />}>
                <Route path='/my-preferences' element={<Home />} />
                <Route path='/my-projects' element={<ViewMyProjects />} />
                <Route path='/join-opportunity/:globalPrefId' element={<ViewOpportunities />} />
                <Route path='/:globalPrefId/send-join-request' element={<SendJoinRequest />} />
                <Route path='/subscribe' element={<Subscribe />} />
                <Route path='/edit-profile' element={<EditProfile />} />
                <Route path='/change-password' element={<ChangePassword />} />
                <Route path='/popular-preferences' element={<ViewGlobalPrefs />} />
                <Route path='/:globalPrefId/sell-your-land' element={<ProposeLand />} />
                <Route path='/opportunities/:globalPrefId/:oppId/proposed-lands' element={<ViewProposedLandsForOpp />} />
                <Route path='/project/:projId' element={<ViewProject />} />
                <Route path='/manage-subscription' element={<ManageSubscription />} />
                <Route path='/my-land-proposals' element={<ViewMyLandProposals />} />
                <Route path='/land-proposals/:landProposalId/edit' element={<EditLandProposal />} />
              </Route>
              <Route element={<ProtectedAdminRouteLoggedOut user={user} />}>
                <Route path='/admin/all-users' element={<ViewAllUsers />} />
                <Route path='/admin/all-projects' element={<ViewAllProjects />} />
                <Route path='/admin/:globalPrefId/create-project' element={<CreateProject />} />
                <Route path='/admin/edit-opportunity/:globalPrefId/:oppId/' element={<EditOpportunity />} />
                <Route path='/admin/requests' element={<ViewRequests />} />
                <Route path='/admin/:globalPrefId/opportunity-participants-queue' element={<ViewParticipants />} />
                <Route path='/admin/land-proposals/' element={<ViewLandProposals />} />
              </Route>
            </Routes>
        </Router>
  )
}

export default AppRouter