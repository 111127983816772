import React from 'react'
import parse from 'html-react-parser'
import { Link } from 'react-router-dom'

function BlogPost({post}) {
    const title = post.title.rendered
    const excerpt = parse(post.excerpt.rendered)
    const featured_image = post?.jetpack_featured_media_url
    const id = post.id
    const slug = post.slug

  return (
    <div className='col-md-6 text-black'>
        {
          featured_image &&
          <>
            <Link to={`/blog/${slug}/${id}`}>
              <img 
                style={{width: '100%', height: '70%', objectFit: 'cover'}}
                src={featured_image} 
                alt='Featured' 
                width="100%" 
              />
            </Link> 
            <br /><br />
          </>
        }
        <h4>
          <Link to={`/blog/${slug}/${id}`} className='text-purple'>
            <strong>{title}</strong>
          </Link>
        </h4>
        {excerpt}
    </div>
  )
}

export default BlogPost