import React from 'react'
import MyPreference from './MyPreference'
import { Link } from 'react-router-dom'
import { IoReturnDownBack } from "react-icons/io5";

function UserPreferences({user, userPreferences, setShowUserPreferences}) {
  const reversedPrefs = [...userPreferences].reverse()
  return (
    <div className='row'>
        <Link 
            href="#" 
            style={{fontSize: '30px', color: '#C13ABB'}} 
            onClick={() => setShowUserPreferences(false)}
        >
            <IoReturnDownBack /> &nbsp; Go Back To All Users
        </Link><br /><br />
        <h2 className='text-black text-center my-prefs-title font-weight-bold'>
            Preferences For {user?.firstName + ' ' + user?.lastName}
        </h2>
        <hr className='my-prefs-title-divider' />
        <div className='flex flex-row'>
            <div className='row'>
                {
                    reversedPrefs.length > 0 ?
                    reversedPrefs.map((myPref, index) => {
                        return <MyPreference key={index} pref = {myPref} index={index} />
                    }) : 
                    <div className='h3 text-center'>
                        User has not added any Preferences.
                    </div>
                }
            </div>
        </div>
    </div>
  )
}

export default UserPreferences