import React from 'react'

function Participant({sn, participant}) {
  return (
    <tr>
      <th scope="row">{sn}</th>
      <td>{participant?.emailAddress}</td>
      <td>{participant?.firstName}</td>
      <td>{participant?.lastName}</td>
      <td>{participant?.phoneNumber}</td>
    </tr>
  )
}

export default Participant