import React, {useEffect} from 'react';
import { ToastContainer, toast, } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
  
function Toast({message, setSuccess = null, setError = null, position = 'top-left'}) {

    const notify = () => {
      setSuccess ? 
      toast.success(message, {toastId: message}) :
      toast.error(message, {toastId: message})
    }
    useEffect(() => {
        notify()
        const unsubscribe = toast.onChange(payload => {
            if(payload.status === "removed") {
              setSuccess && setSuccess(null)
              setError && setError(null)
            }
        })
        return unsubscribe
    }, [])

    return (
      <div>
        <ToastContainer 
          position={position}
        />
      </div>
    );
}

export default Toast