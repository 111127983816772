import { useEffect } from "react";
import { 
    createUserWithEmailAndPassword, 
    signInWithEmailAndPassword, 
    reauthenticateWithCredential,
    onAuthStateChanged,
    sendEmailVerification,
    signOut,
    applyActionCode,
    updatePassword,
    sendPasswordResetEmail,
    EmailAuthProvider
} from "firebase/auth";
import {auth} from '../firebase'
import { useSelector, useDispatch } from "react-redux";
import { UserActions } from "../redux/store";
import { useUser } from "./useUser";
import { usePreference } from "./usePreference";
import { userInitialState } from "../redux/store";

export function useAuth() {
    const user = useSelector(state => state.user)
    const {
        watchUserState, 
        registerUser, 
        updateUserState
    } = useUser()
    const { watchMyPreferences } = usePreference()
    const {setUserState} = UserActions
    const dispatch = useDispatch()

    useEffect(() => {
            const unsubscribeFromAuthStatusChanged = onAuthStateChanged(auth, async curUser => {
                if (curUser) {
                    watchUserState(curUser, setUserState)
                    watchMyPreferences(curUser.uid)
                    if(curUser.emailVerified) await updateUserState(curUser.uid, {emailVerified: curUser.emailVerified});
                } else {
                    const newUser = {
                        ...user,
                        loggedIn: false
                    }
                    dispatch(setUserState(newUser))
                }  
            });
            return unsubscribeFromAuthStatusChanged;
    }, []);
  
    const signUpUser = async (emailAddress, password, newUser) => {
        try{
            const userCredential = await createUserWithEmailAndPassword(auth, emailAddress, password)
            await registerUser(userCredential.user, newUser)
            return userCredential.user
        }catch(e){
            throw new Error(e.message)
        }
    }

    const sendVerificationEmail = async () => {
        try{
            var actionCodeSettings = {
                url: 'https://peer2own.com',
                handleCodeInApp: false,
                dynamicLinkDomain: 'peer2own.page.link'
            };
            await sendEmailVerification(auth.currentUser, actionCodeSettings)
            return true
        }catch(e){
            throw new Error(e.message)
        }
    }

    const _sendPasswordResetEmail = async emailAddress => {
        try {
            var actionCodeSettings = {
                url: 'https://peer2own.com',
                handleCodeInApp: false,
                dynamicLinkDomain: 'peer2own.page.link'
            };
            await sendPasswordResetEmail(auth, emailAddress, actionCodeSettings)
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const _updatePassword = async (emailAddress, currentPassword, newPassword) => {
        try {
            await reauthenticateWithCredential(
                auth.currentUser,
                EmailAuthProvider.credential(emailAddress, currentPassword)
            )
            await updatePassword(auth.currentUser, newPassword)
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const handleVerifyEmail = actionCode => {
        applyActionCode(auth, actionCode).then((resp) => {
          console.log("Email Address Verified")
        }).catch((error) => {
          throw new Error(error.message)
        });
    }

    const signInUser = async (emailAddress, password) => {
        try{
            const userCredential = await signInWithEmailAndPassword(auth, emailAddress, password)
            return userCredential.user
        }catch(e){
            throw new Error(e.message)
        }
    }

    const sendEmailVerificationLink = async () => {
        try{
            var actionCodeSettings = {
                url: 'https://peer2own.com',
                handleCodeInApp: false,
                dynamicLinkDomain: 'peer2own.page.link'
            };
            await sendEmailVerification(auth.currentUser, actionCodeSettings)
            return true
        }catch(e){
            throw new Error(e.message)
        }
    }

    const signOutUser = async () => {
        try {
            await signOut(auth)
            dispatch(setUserState(userInitialState))
        } catch (error) {
            throw new Error(error.message)
        }
    }

    return {
        signUpUser,
        signInUser,
        signOutUser,
        sendVerificationEmail,
        handleVerifyEmail,
        sendEmailVerificationLink,
        _sendPasswordResetEmail,
        _updatePassword,
        auth
    }
}