import axios from "axios"
import { useUser } from "./useUser"
import PaystackPop from '@paystack/inline-js';
import { useNavigate } from "react-router-dom";
import { API_URL } from "../constants";

export function useSubscription () {
    const {updateUserState} = useUser()
    const navigate = useNavigate()

    const fetchSubscriptionPlans = async () => {
        try {
            const {data} = await axios.get(API_URL + '/plans')
            return data;
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const verifyTransaction = async refId => {
        try {
            const {data} = await axios.get(API_URL + `/verify-transaction/${refId}`)
            return data;
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const subscribeUser = async ({uid, emailAddress, amount, plan_code}) => {
        try {    
            const paystack = new PaystackPop();

            const newTransactionWithPlan = {
                key: 'pk_live_4867dda9b06341c961a4dd014f0285ce12bcbb88',
                email: emailAddress,
                metadata: {uid: uid},
                amount: amount,
                channels: ['card'],
                plan: plan_code,
                subaccount: 'ACCT_xdfpnfcjtt1ukkf',
                onSuccess: async (transaction) => { 
                    const {status} = await verifyTransaction(transaction.reference)
                    if(status === 'success') handleSubscribed(uid)
                },
                onCancel: () => {
                    console.log("User Closed Popup")
                }
            }

            const newTransactionWithoutPlan = {
                key: 'pk_live_4867dda9b06341c961a4dd014f0285ce12bcbb88',
                email: emailAddress,
                metadata: {uid: uid},
                amount: amount,
                channels: ['card', 'bank', 'ussd', 'qr', 'mobile_money', 'bank_transfer'],
                subaccount: 'ACCT_xdfpnfcjtt1ukkf',
                onSuccess: async (transaction) => { 
                    const {status} = await verifyTransaction(transaction.reference)
                    if(status === 'success') handleSubscribed(uid)
                },
                onCancel: () => {
                    console.log("User Closed Popup")
                }
            }

            if(plan_code) {
                paystack.newTransaction(newTransactionWithPlan); 
            } else {
                paystack.newTransaction(newTransactionWithoutPlan); 
            }      
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const handleSubscribed = async (uid) => {
        await updateUserState(uid, {isSubscribed: true})
        navigate(-1)
    }

    const cancelSubscription = () => {

    }

    const fetchCustomer = async (emailAddress) => {
        try {
            const {data} = await axios.get(API_URL + `/fetch-customer/${emailAddress}`)
            return data
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const createCustomer = async (uid, emailAddress) => {
        try {
            const {data} = await axios.post(API_URL + '/create-customer', {
                email: emailAddress,
                metadata: {uid: uid}
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            const customer = {
                paystackCustomerEmail: data.email,
                paystackCustomerCode: data.customer_code
            }

            await updateUserState(uid, customer)
            return customer
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const updateCustomer = async (customer_code, updateObj) => {
        try {
            const {data} = await axios.post(API_URL + `/update-customer/${customer_code}`, {
                updateObj
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            return data;
        } catch (error) {
            throw new Error(error.message)
        }
    }

    return {
        fetchSubscriptionPlans,
        createCustomer,
        fetchCustomer,
        updateCustomer,
        subscribeUser
    }

}