import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import { useOpportunity } from '../hooks/useOpportunity'
import { useSelector } from 'react-redux'
import Toast from './Toast'

function JoinOpportunity({globalPref, buildingType, handleOpenLightbox}) {
    const user = useSelector(state => state.user)

    const [_requestExists, setRequestExists] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)

    const {requestExists} = useOpportunity()

  return (
    <div class="list-group">
        {
          successMessage &&
          <Toast message={successMessage} setSuccess={setSuccessMessage}/>
        }
        {
          errorMessage &&
          <Toast message={errorMessage} setError={setErrorMessage}/>
        }
        <div class="opp-card list-group-item list-group-item-action flex-column align-items-start">
            <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1 mr-2 text-black">
                    <strong>{globalPref?.propertyType}</strong>
                </h5>
                { 
                    !_requestExists ?
                    <div>
                        <Link 
                            to={`/${globalPref.id}/send-join-request`} 
                            className='btn btn-danger btn-sm cta-btn'
                        >
                            Request To Join
                        </Link>
                    </div> :
                    <span>Request Sent</span>
                }
            </div>
            <br />
            {/* <p class="mb-1 text-black">{opportunity?.description}</p> */}
            <br/>
            <div className='row'>
                <div className='col-md-4' style={{padding: '8px'}}>
                    <Link onClick={handleOpenLightbox}>
                        {
                           buildingType && buildingType.images.length > 0 &&
                            <img 
                                className='opp-img'
                                style={{width: '100%', height: '100%', objectFit: 'cover'}}
                                src={buildingType.images[0]} 
                                alt='Project Model Images' 
                            />
                        }
                    </Link>
                    <br />
                </div>
                <div className='col' style={{padding: '8px'}}>
                    <ul className="list-group">
                        <li className='opp-card-item list-group-item d-flex justify-content-between align-items-center'>
                            <span className='text-black'>
                                <strong>
                                     Number of People in Queue
                                </strong>
                            </span>
                            {
                                user.isSubscribed ?
                                <div>
                                    <span class="badge badge-primary opp-card-badge-green">
                                        {globalPref?.participantsQueue.length}
                                    </span> 
                                    {
                                        (
                                            user.role === 'admin' &&
                                            globalPref?.participantsQueue.length > 0
                                        ) ?
                                        <> 
                                            &nbsp;
                                            <Link 
                                                to={`/admin/${globalPref.id}/opportunity-participants-queue`} 
                                                className='btn btn-sm btn-success'
                                            >
                                                View
                                            </Link>
                                        </> : null
                                    }
                                </div>
                                 :
                                <Link to='/subscribe' className='btn btn-success btn-sm'>
                                    Subscribe to View
                                </Link>
                            }
                        </li>
                    </ul>
                    <br />
                    <p className='opp-card-left-padding'>
                        <h5 className='text-black'>
                            Project participants will be selected on a first-come, first-served basis.
                            The queue is used to ensure that everyone has a fair chance to be selected for a project.
                        </h5>
                        <h5 className='text-black'>
                            The earlier you join this queue, the faster you will get matched with
                            peers to start a project.
                        </h5>
                    </p>
                    <hr className='p2o-form-title-divider' />
                    <ul className="list-group">
                        <li className='opp-card-item list-group-item d-flex justify-content-between align-items-center'>
                            <span className='text-purple'>
                                <strong>
                                    Do You Have Land for Sale @ {globalPref.choiceLocation}?
                                </strong>
                            </span>
                            <Link to={`/${globalPref.id}/sell-your-land`} className='btn btn-sm btn-danger cta-btn'>
                                Sell Your Land
                            </Link>
                        </li>
                    </ul>
                </div>
            </div> 
        </div>
        <br/>
    </div>
  )
}

export default JoinOpportunity