import React, {useState, useEffect, memo} from 'react'
import { useUser } from '../hooks/useUser'
import Nav from '../components/Nav'
import Footer from '../components/Footer'
import AllUsers from '../components/AllUsers'
import UsersFilter from '../components/UsersFilter'
import LoadingIndicator from '../components/LoadingIndicator'
import { Helmet } from 'react-helmet'

function ViewAllUsers() {
    const [users, setUsers] = useState([])
    const [showUserPreferences, setShowUserPreferences] = useState(false)
    const [selectedUserPreferences, setSelectedUserPreferences] = useState([])
    const [selectedUser, setSelectedUser] = useState(null)
    const [filterModeOn, setFilterModeOn] = useState(false)
    const [filteredUsers, setFilteredUsers] = useState([])
    const [searchQuery, setSearchQuery] = useState('')
    const [filterValue, setFilterValue] = useState('all')
    const [isLoading, setIsLoading] = useState(true)
    const {watchAllUsers} = useUser()

    useEffect(() => {
        let unsub
        (async () => {
            unsub = await watchAllUsers(setUsers)
            setIsLoading(false)
        })()

        return unsub
    }, [])

    const handleSearch = event => {
        setFilterValue('all')
        setFilterModeOn(true)
        setSearchQuery(event.target.value)
        const searchQuery = event.target.value
        const searchedUsers = users.filter(user => {
            if (
                (
                    user.firstName && 
                    user.firstName.toLowerCase().startsWith(searchQuery.toLowerCase())
                ) ||
                (
                    user.lastName && 
                    user.lastName.toLowerCase().startsWith(searchQuery.toLowerCase())
                ) ||
                (
                    user.emailAddress && 
                    user.emailAddress.toLowerCase().startsWith(searchQuery.toLowerCase())
                ) ||
                (
                    user.phoneNumber && 
                    user.phoneNumber.toLowerCase().startsWith(searchQuery.toLowerCase())
                )
            ) return true
            return false
        })
        setFilteredUsers(searchedUsers)
        if(searchQuery.length === 0) setFilterModeOn(false)
    }

    const handleFilterUsers = event => {
        setSearchQuery('')
        setFilterValue(event.target.value)
        if(event.target.value === 'subscribed') {
            const subscribedUsers = users.filter(user => user.isSubscribed)
            setFilterModeOn(true)
            setFilteredUsers(subscribedUsers)
        }
        if(event.target.value === 'all') setFilterModeOn(false)
    }

  return (
    <div>
        <Helmet>
            <title>Peer2Own -  View All Users</title>
        </Helmet>
        <Nav />
        <br />
        {
            <div className='container'>
                <h1 className='p2o-page-title'>All Users</h1>
                <UsersFilter 
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    filterValue={filterValue}
                    handleSearch={handleSearch}
                    handleFilterUsers={handleFilterUsers}
                />
            </div>
        }
        <br/>
        <div className='container'>
            {
                isLoading ?
                <div className='text-center'>
                    <LoadingIndicator color='#000' />
                </div> :
                <AllUsers
                    users={
                        filterModeOn ? 
                        filteredUsers : 
                        users
                    }
                    showUserPreferences={showUserPreferences}
                    setShowUserPreferences={setShowUserPreferences}
                    selectedUser={selectedUser}
                    setSelectedUser={setSelectedUser}
                    selectedUserPreferences={selectedUserPreferences}
                    setSelectedUserPreferences={setSelectedUserPreferences}
                /> 
            }
        </div>
        <br />
        <Footer />
    </div>
  )
}

export default memo(ViewAllUsers)