import {db, serverTimestamp} from '../firebase'
import { 
    doc,
    collection,
    getDoc,
    getDocs,
    addDoc,
    updateDoc,
    deleteDoc,
    query,
    orderBy,
    limit,
    onSnapshot
} from "firebase/firestore";
import { useSelector, useDispatch } from "react-redux";
import {MyPreferencesActions} from "../redux/store"

export function usePreference() {
    const myPreferences = useSelector(state => state.myPreferences)
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()
    const {setMyPreferencesState} = MyPreferencesActions

    const getPreference = async (uid, prefId) => {
        try {
            const prefSnap = await getDoc(doc(db, `users/${uid}/preferences`, prefId))
            if(prefSnap.exists()){
                const pref = {...prefSnap.data(), id: prefSnap.id}
                return pref
            } else {
                return null
            }
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const getUserPreferences = async (uid) => {
        try {
            const myPreferencesCollection = collection(db, `users/${uid}/preferences`)
            const q = query(myPreferencesCollection, orderBy("similarPreferencesCount", "desc"));
            const querySnapshot = await getDocs(q);

            const userPreferences = []
            querySnapshot.forEach((doc) => {
                userPreferences.push( {id: doc?.id, ...doc?.data() });
            });
            return userPreferences
        } catch (error) {
            throw new Error(error?.message)
        }
    }

    const getGlobalPreference = async globalPrefId => {
        try {
            const prefSnap = await getDoc(doc(db, `preferences`, globalPrefId))
            if(prefSnap.exists()){
                const pref = {...prefSnap.data(), id: prefSnap.id}
                return pref
            } else {
                return null
            }
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const addPreference = async (uid, preference) => {
        try {
            if(preferenceExists(preference)) throw new Error("Preference already exists.");
            if(myPreferences.length >= 12) throw new Error("You can add only 12 Preferences.")
            if(!user.emailVerified) throw new Error("Verify Your Email Address To Add Preferences.")
            const pref = {...preference, timeAdded: serverTimestamp()}
            await addDoc(collection(db, `users/${uid}/preferences`), pref)
            return true;
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const preferenceExists = preference => {
        const filter = myPreferences.filter(pref => 
            pref.propertyType === preference.propertyType &&
            pref.propertyUse === preference.propertyUse &&
            pref.choiceLocation === preference.choiceLocation &&
            pref.budget === preference.budget
        )

        if(filter.length > 0) return true;
        return false;
    }

    const fetchChoiceLocationPlaces = async choiceLocation => {
        try {
            const placesSnap = await getDoc(doc(db, `locations`, choiceLocation.toLowerCase()))
            if(placesSnap.exists()){
                const places = placesSnap.data().places
                return places
            } else {
                return null
            }
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const watchMyPreferences = (uid) => {
        try {
            const myPreferencesCollection = collection(db, `users/${uid}/preferences`)
            const q = query(myPreferencesCollection, orderBy("similarPreferencesCount", "desc"), limit(100));
            const unsub = onSnapshot(q, async snapshot => {
                const newPrefs = []
                snapshot.forEach((doc) => {
                    newPrefs.push({...doc.data(), id: doc.id});
                });
                dispatch(setMyPreferencesState(newPrefs.reverse()))
            });
            return unsub
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const watchAllGlobalPreferences = async setPrefs => {
        try {
            const q = query(collection(db, "preferences"), orderBy("occurences", "desc"));
            const unsub = onSnapshot(q, snapshot => {
                const prefs = []
                snapshot.forEach(doc => prefs.push({id: doc.id, ...doc.data()}))
                setPrefs(prefs)
            })
            return unsub
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const deletePreference = async (uid, prefId) => {
        try {
            await deleteDoc(doc(db, `users/${uid}/preferences/`, prefId));
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const updateGlobalPreference = async (globalPrefId, data) => {
        try {
           await updateDoc(doc(db, `preferences`, globalPrefId), data)
           return true
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const updatePreference = async (uid, prefId, data) => {
        try {
           await updateDoc(doc(db, `users/${uid}/preferences`, prefId), data)
           return true
        } catch (error) {
            throw new Error(error.message)
        }
    }

    return {
        getPreference,
        getGlobalPreference,
        addPreference,
        watchMyPreferences,
        deletePreference,
        updatePreference,
        watchAllGlobalPreferences,
        updateGlobalPreference,
        fetchChoiceLocationPlaces,
        getUserPreferences,
    }
}