import React, {useState, useEffect} from 'react'
import Nav from '../components/Nav'
import Footer from '../components/Footer'
import AllGlobalPrefs from '../components/AllGlobalPreferences'
import { usePreference } from '../hooks/usePreference'
import LoadingIndicator from '../components/LoadingIndicator'
import { Helmet } from 'react-helmet'

function ViewGlobalPrefs() {
    const [prefs, setPrefs] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const {watchAllGlobalPreferences} = usePreference()

    useEffect(() => {
        let unsub;
        (async () => {
            unsub = await watchAllGlobalPreferences(setPrefs)
            setIsLoading(false)
        })()

        return unsub;
    }, [])

  return (
    <div>
         <Helmet>
            <title>Peer2Own -  Popular Preferences</title>
        </Helmet>
        <Nav />
        <br />
        <div className='container'>
            <h1 className='p2o-page-title'>Popular Preferences</h1>
            {
                isLoading ?
                <div className='text-center'>
                    <LoadingIndicator color='#000' />
                </div> :
                <AllGlobalPrefs prefs={prefs} />
            }
        </div>
        <br />
        <Footer />
    </div>
  )
}

export default ViewGlobalPrefs