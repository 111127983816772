import React from 'react'
import Request from './Request'

function Requests({ requests }) {
  return (
    <div className='table-responsive'>
        <table className="table table-sm table-striped text-center">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Email Address</th>
                    <th scope="col">GlobalPref ID</th>
                    <th scope="col">&nbsp;</th>
                    <th scope="col">&nbsp;</th>
                    <th scope="col">&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                {
                    requests.length === 0 ? 
                    <div className='text-center'>No Requests</div> :
                    requests.map((request, index) => <Request key={index} sn={index + 1} request={request} />)
                }
            </tbody>
        </table>
    </div>
  )
}

export default Requests