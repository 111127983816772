import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"
import { getFirestore, serverTimestamp, documentId } from "firebase/firestore";
import { getStorage } from "firebase/storage"

const firebaseConfig = {
    apiKey: "AIzaSyCMYIUMyxoBXtiFCtstIg0rpo78BOF31FQ",
    authDomain: "peer2own-f396a.firebaseapp.com",
    projectId: "peer2own-f396a",
    storageBucket: "peer2own-f396a.appspot.com",
    messagingSenderId: "350192189222",
    appId: "1:350192189222:web:2485defc14e0916d2d4be7",
    measurementId: "G-5DL8YSDD9F"
  };


const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const db = getFirestore(app)
const storage = getStorage(app)

export {
  app,
  auth,
  db,
  storage,
  serverTimestamp,
  documentId
}  