import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Logo from '../assets/logo.png'
import {Link} from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useAuth } from '../hooks/useAuth'

function NavBar() {
    const user = useSelector(state => state.user)
    const {signOutUser} = useAuth()

    const handleLogout = async () => {
        await signOutUser()
    }

    return (
        <Navbar 
            expand="lg" 
            className="bg-body-tertiary"
        >
        <Container>
            <Navbar.Brand>
                <Link to='/' style={{textDecoration: 'none'}}>
                    <img 
                        src={Logo}
                        alt='Logo'
                        height={80}
                    />
                </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto"> 
                        {
                        user.loggedIn ?
                        <>
                            <Nav.Link>
                                <Link to='/' className='nav-link'>Home</Link>
                            </Nav.Link>
                            <Nav.Link>
                                <Link to='/my-preferences' className='nav-link'>My Preferences</Link>
                            </Nav.Link>
                            <Nav.Link>
                                <Link className='nav-link popular-prefs' to='/popular-preferences'>
                                    Popular Preferences
                                </Link>
                            </Nav.Link>
                            <Nav.Link>
                                <Link className='nav-link popular-prefs' to='/my-projects'>
                                    My Projects
                                </Link>
                            </Nav.Link>
                            <Nav.Link className='d-sm-block d-lg-none'>
                                <Link to='/my-land-proposals' className="nav-link">
                                    My Land Proposals
                                </Link>
                            </Nav.Link>
                            <Nav.Link className='d-sm-block d-lg-none'>
                                <Link to='/edit-profile' className="nav-link">
                                    My Profile
                                </Link>
                            </Nav.Link>
                            <Nav.Link className='d-sm-block d-lg-none'>
                                <Link to='/change-password' className="nav-link">
                                    Change Password
                                </Link>
                            </Nav.Link>
                            <Nav.Link className='d-sm-block d-lg-none'>
                                <Link onClick={handleLogout} className="nav-link">
                                    Logout
                                </Link>
                            </Nav.Link>
                        {/*<Nav.Link>
                                <Link to='/blog' className='nav-link'>Blog</Link>
                            </Nav.Link>*/}
                            {
                                user.role === 'admin' && 
                                <>
                                    <Nav.Link>
                                        <Link className='nav-link' to='/admin/all-projects'>
                                            All Projects
                                        </Link>
                                    </Nav.Link>
                                    <Nav.Link>
                                        <Link className='nav-link' to='/admin/all-users'>
                                            All Users
                                        </Link>
                                    </Nav.Link>
                                    <Nav.Link>
                                        <Link className='nav-link' to='/admin/requests'>
                                            Requests
                                        </Link>
                                    </Nav.Link>
                                    <Nav.Link>
                                        <Link className='nav-link' to='/admin/land-proposals'>
                                            Land Proposals
                                        </Link>
                                    </Nav.Link>
                                </>
                            }
                            {
                            /*  !user.isSubscribed &&
                                <Nav.Link>
                                    <Link to="/subscribe" className='btn btn-default subscribe-btn'>
                                        Subscribe
                                    </Link>
                                </Nav.Link> */
                            }
                            <NavDropdown title={``} id="basic-nav-dropdown" className='nav-link d-none d-lg-block'>
                                <NavDropdown.Item>
                                    <Nav.Link>
                                        <Link to='/my-land-proposals' className="nav-link">
                                            My Land Proposals
                                        </Link>
                                    </Nav.Link>
                                </NavDropdown.Item>
                                <NavDropdown.Item>
                                    <Nav.Link>
                                        <Link to='/edit-profile' className="nav-link">
                                            My Profile
                                        </Link>
                                    </Nav.Link>
                                </NavDropdown.Item>
                                <NavDropdown.Item>
                                    <Nav.Link>
                                        <Link to='/change-password' className="nav-link">
                                            Change Password
                                        </Link>
                                    </Nav.Link>
                                </NavDropdown.Item>
                            {/* {
                                    user.isSubscribed && 
                                    <NavDropdown.Item>
                                        <Nav.Link>
                                            <Link to='/manage-subscription' className="nav-link">
                                                Manage Subscription
                                            </Link>
                                        </Nav.Link>
                                    </NavDropdown.Item>
                                } */}
                                <NavDropdown.Divider />
                                <NavDropdown.Item>
                                    <Nav.Link>
                                        <Link onClick={handleLogout} className="nav-link">
                                            Logout
                                        </Link>
                                    </Nav.Link>
                                </NavDropdown.Item>
                            </NavDropdown> 
                        </> :
                        <>
                            <Nav.Link class="nav-item">
                                <Link class="nav-link" to="/signup">Sign Up</Link>
                            </Nav.Link>
                            <Nav.Link class="nav-item">
                                <Link class="nav-link" to="/login">Login</Link>
                            </Nav.Link>
                            {/* <Nav.Link>
                                <Link to='/blog' className='nav-link'>Blog</Link>
                            </Nav.Link> */}
                        </>
                        }
                    </Nav>
                </Navbar.Collapse>
        </Container>
      </Navbar>
    )
}

export default NavBar