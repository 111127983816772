import React, { useState, useEffect } from 'react'
import Nav from '../components/Nav'
import Footer from '../components/Footer'
import AddPreferenceForm from '../components/AddPreferenceForm'
import MyPreferences from '../components/MyPreferences'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { useAuth } from '../hooks/useAuth'
import LoadingIndicator from '../components/LoadingIndicator'
import Toast from '../components/Toast'
import { Helmet } from 'react-helmet'
import Tour from 'reactour'
import PrefCardImg from '../assets/pref-card.PNG'

function Home() {
    const user = useSelector(state => state.user)
    const myPreferences = useSelector(state => state.myPreferences)

    const [sendingEmail, setSendingEmail] = useState(null)
    const [successMessage, setSuccessMessage] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)
    const [isTourOpen, setIsTourOpen] = useState(true)
    const {sendEmailVerificationLink} = useAuth()

    const { pathname } = useLocation();
    
    useEffect(() => {
        window.scrollTo(0, 0);
      }, [pathname]);

    const steps = [
        {
            selector: ".add-preference-form-title",
            content: 
            <>
                <h6 className='p2o-page-title'>Add Your Preferences</h6>
                <p className='text-black'>
                    A preference is composed of your choice
                    <strong> property type, property use, location and budget.</strong> 
                </p>
                <p className='text-black'>
                    Your preferences will let us know the kind of real 
                    estate opportunities you want to participate in.
                </p>
                <p className='text-black'>
                    After adding a preference, you will start seeing opportunities to join projects
                    that match your preference.
                </p>
                <p className='text-black'>You can add multiple preferences.</p>
            </>
        },
        {
            selector: '.my-prefs-title',
            content: 
            <>
                <h4 className='p2o-page-title'>Your Preferences</h4>
                <p className='text-black'>This is where the preferences you add will be displayed.</p>
                <p className='text-center'>
                    <img 
                        src={PrefCardImg}
                        alt='Preference Card Sample'
                        width="60%"
                    />
                </p>
                <p className='text-black'>
                    Each Preference Card has a <strong>Join Opportunity</strong> button 
                    which allows you join opportunities to participate in projects that match
                    your preference.
                </p>
            </>
        },
        {
            selector: ".popular-prefs",
            content: 
            <>
                <h4 className='p2o-page-title'>
                    Popular Preferences
                </h4>
                <p className='text-black'>
                    Popular Preferences are the preferences that have lots of 
                    engagement from other Peer2Own members.
                </p>
                <p className='text-black'>
                    You can join opportunities to participate in projects for each of your preferences.
                </p>
                <p className='text-black'>
                    If you are having a hard time deciding your own preference, 
                    viewing popular preferences could be a way to get inspired.
                </p>
                <p className='text-black'>
                    This is also the place to sell your land if you have one.
                </p>
            </>
        },
        {
            selector: ".subscribe-btn",
            content: 
            <>
                <h4 className='p2o-page-title'>Subscribe</h4>
                <p className='text-black'>
                    Become a subscribed member to join opportunities to be part of
                    projects that match your preferences.
                </p>
                <p className='text-black'>
                    Becoming a subscribed member also gives you access to other premium 
                    features such as <strong>Selling Your Land</strong>,
                    <strong> Project Tracking and Monitoring</strong>.
                </p>
            </>
        }
    ]

    const closeTour = () => {
        setIsTourOpen(false)
    }

    const handleSendEmailVerificationLink = async () => {
        try {
            setSendingEmail(true)
            await sendEmailVerificationLink()
            setSendingEmail(false)
            setSuccessMessage("Email Verification Link Sent")
        } catch (error) {
            setErrorMessage(error.message)
        }
    }
  return (
    <div>
        <Helmet>
            <title>Peer2Own -  My Preferences</title>
        </Helmet>
        <Nav />
       {/*  {
            myPreferences.length < 1 &&
            <Tour
                steps={steps}
                isOpen={isTourOpen}
                onRequestClose={closeTour}
                lastStepNextButton={<button onClick={closeTour} className='btn btn-primary cta-btn'>
                    Close Tour
                </button>}
            />
        } */}
        {
            successMessage &&
            <Toast message={successMessage} setSuccess={setSuccessMessage}/>
        }
        {
            errorMessage &&
            <Toast message={errorMessage} setError={setErrorMessage}/>
        }
        <div className='container'><br/>
            {
                !user.emailVerified &&
                <div className='alert alert-danger text-center'>
                    Verify Your Email Address To Start Adding Preferences. 
                    &nbsp;
                    <Link onClick={handleSendEmailVerificationLink} className='btn btn-success'>
                        {
                            sendingEmail ? <LoadingIndicator /> : "Send Verification Link"
                        }
                    </Link>
                </div>
            }
            {
                /* user.emailVerified && !user.isSubscribed &&
                <div className='alert alert-danger text-center'>
                    Subscribe to Join Opportunities 
                    &nbsp;
                    <Link to='/subscribe' className='btn btn-success'>Subscribe</Link>
                </div> */
            }
            {
                <div className='alert alert-info text-center'>
                    <span className='text-capitalize h4'>
                        <strong>
                            Do you have Land For Sale? View popular preferences to sell
                        </strong>
                    </span> 
                    &nbsp;&nbsp;&nbsp;
                    <Link to='/popular-preferences' className='btn btn-success cta-btn'>
                        <span className='h4'>Popular Preferences</span>
                    </Link>
                </div>
            }
            <div className='row'>
                <div className='col-md-4'>
                    <AddPreferenceForm />
                </div>
                <div className='col'>
                    <br/>
                    <h2 className='my-prefs-title text-center'>
                        My Preferences
                    </h2>
                    <hr className='my-prefs-title-divider' />
                    <MyPreferences 
                        myPreferences={myPreferences} 
                    />
                    <br />
                </div>
            </div>
        </div>
        <Footer />
    </div>
  )
}

export default Home