import React, {useState, useEffect} from 'react'
import {
    Link,
    useLocation
} from 'react-router-dom'
import Nav from '../components/Nav'
import Footer from '../components/Footer'
import { useAuth } from '../hooks/useAuth'
import { useSelector } from 'react-redux'
import LoadingIndicator from '../components/LoadingIndicator'
import Toast from '../components/Toast'
import { Helmet } from 'react-helmet'
import { useUser } from '../hooks/useUser'

function SignUp() {
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [emailAddress, setEmailAddress] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [password, setPassword] = useState('')
    const [repeatPassword, setRepeatPassword] = useState('')
    const [referrerId, setReferrerId] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)

    const user = useSelector(state => state.user)
    const {signUpUser} = useAuth()

    const {getReferrerIdLocally} = useUser()

    const { pathname } = useLocation();

    useEffect(() => {
        const referrerId = getReferrerIdLocally()
        if(referrerId) setReferrerId(referrerId)
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const handleFirstName = event => {
        setFirstName(event.target.value)
    }

    const handleLastName = event => {
        setLastName(event.target.value)
    }

    const handleEmailAddress = event => {
        setEmailAddress(event.target.value)
    }

    const handlePhoneNumber = event => {
        setPhoneNumber(event.target.value)
    }

    const handlePassword = event => {
        setPassword(event.target.value)
    }

    const handleRepeatPassword = event => {
        setRepeatPassword(event.target.value)
    }

    const validateSignUp = () => {
        if(firstName.length === '') throw new Error("First Name is Empty")
        if(lastName.length === '') throw new Error("Last Name is Empty")
        if(emailAddress.length === '') throw new Error("Email Address is Empty")
        if(phoneNumber.length === '') throw new Error("Phone Number is Empty")
        if(password.length === '') throw new Error("Password is Empty")

        if(password !== repeatPassword) {
            throw new Error("Both Passwords don't match")
        }
        return true
    }

    const handleSignUp = async (event) => {
        try{
            event.preventDefault();
            setIsLoading(true)

            const newUser = {
                ...user,
                firstName: firstName,
                lastName: lastName,
                emailAddress: emailAddress,
                phoneNumber: phoneNumber,
                referrerId: referrerId
            }
        
            if(!validateSignUp()) return;

            await signUpUser(emailAddress, password, newUser)
            setIsLoading(false)
        }catch(e){
            setErrorMessage(e.message)
            setIsLoading(false) 
        }
    }
  return (
    <>
        <Helmet>
            <title>Peer2Own -  Sign Up</title>
        </Helmet>
        <Nav />
        {
            errorMessage &&
            <Toast 
                message={errorMessage} 
                setError={setErrorMessage} 
            />
        }
        <div class="container">
            <div class="card o-hidden border-0 shadow-lg my-5">
                <div class="card-body p-0">
                    <div class="row">
                        <div class="col-lg-5 d-none d-lg-block bg-register-image"></div>
                        <div class="col-lg-7">
                            <div class="p-5">
                                <h1 class="h4 mb-4 p2o-form-title">
                                    Welcome - Sign Up to Get Started
                                </h1>
                                <hr className='p2o-form-title-divider' />
                                <p className='text-black'>
                                    <strong>Sign Up </strong> 
                                    to start finding peers to share land and even build with you.
                                </p>
                                <p className='text-black'>
                                    After signing up, you can start <strong>adding your preferences</strong> which will help us 
                                    know the type of property you want, match you with the right people and 
                                    <strong> create opportunities for you and your peers to join projects </strong> 
                                    that match your preferences.
                                </p>
                                <form onSubmit={handleSignUp} class="user">
                                    <div class="form-group row">
                                        <div class="col-sm-6 mb-3 mb-sm-0">
                                            <input
                                                value={firstName} 
                                                onChange={handleFirstName}
                                                type="text" 
                                                class="form-control form-control-user" 
                                                id="exampleFirstName" 
                                                placeholder="First Name" 
                                            />
                                        </div>
                                        <div class="col-sm-6">
                                            <input 
                                                value={lastName}
                                                onChange={handleLastName}
                                                type="text" 
                                                class="form-control form-control-user" 
                                                id="exampleLastName" 
                                                placeholder="Last Name" 
                                            />
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <input 
                                            value={emailAddress}
                                            onChange={handleEmailAddress}
                                            type="email" 
                                            class="form-control form-control-user" 
                                            id="exampleInputEmail" 
                                            placeholder="Email Address" 
                                        />
                                    </div>
                                    <div class="form-group">
                                        <input 
                                            value={phoneNumber}
                                            onChange={handlePhoneNumber}
                                            type="phone" 
                                            class="form-control form-control-user" 
                                            id="exampleInputPhoneNumber" 
                                            placeholder="Phone Number" 
                                        />
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-sm-6 mb-3 mb-sm-0">
                                            <input
                                                value={password}
                                                onChange={handlePassword} 
                                                type="password" 
                                                class="form-control form-control-user"
                                                id="exampleInputPassword" 
                                                placeholder="Password" 
                                            />
                                        </div>
                                        <div class="col-sm-6">
                                            <input
                                                value={repeatPassword} 
                                                onChange={handleRepeatPassword}
                                                type="password" 
                                                class="form-control form-control-user"
                                                id="exampleRepeatPassword" 
                                                placeholder="Repeat Password" 
                                            />
                                        </div>
                                    </div>
                                    <button type='submit' class="btn btn-primary cta-btn btn-user btn-block">
                                        {isLoading ? <LoadingIndicator /> : 'Register'}
                                    </button>
                                    <hr />
                                    {/* <a href="index.html" class="btn btn-google btn-user btn-block">
                                        <i class="fab fa-google fa-fw"></i> Register with Google
                                    </a>
                                    <a href="index.html" class="btn btn-facebook btn-user btn-block">
                                        <i class="fab fa-facebook-f fa-fw"></i> Register with Facebook
                                    </a> */}
                                </form>
                                <hr />
                                <div class="text-center">
                                    <Link class='small' to='/forgot-password'>Forgot Password?</Link>
                                </div>
                                <div class="text-center">
                                    <Link class='small' to='/login'>
                                        Already have an account? Login!
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
    <Footer />
</>
  )
}

export default SignUp