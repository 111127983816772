import React, {useState} from 'react'
import { useAuth } from '../hooks/useAuth'
import { useSelector } from 'react-redux';
import LoadingIndicator from './LoadingIndicator';
import Toast from './Toast';

function ChangePasswordForm() {
    const user = useSelector(state => state.user)
    const {_updatePassword} = useAuth()
    const [currentPassword, setCurrentPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [repeatNewPassword, setRepeatNewPassword] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [successMessage, setSuccessMessage] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)

    const handleCurrentPassword = event => {
        setCurrentPassword(event.target.value)
    }

    const handleNewPassword = event => {
        setNewPassword(event.target.value)
    }

    const handleRepeatNewPassword = event => {
        setRepeatNewPassword(event.target.value)
    }

    const validateChangePassword = () => {
        if(currentPassword === '') throw new Error("Enter Your Current Password");
        if(newPassword === '') throw new Error("Enter New Password");
        if(repeatNewPassword === '') throw new Error("Repeat New Password");
        if(newPassword !== repeatNewPassword) throw new Error("Make sure you entered the new password and password repeat correctly!")

        return true
    }

    const handleChangePassword = async event => {
        try {
            event.preventDefault();
            setIsLoading(true)
    
            if(!validateChangePassword()) return
            await _updatePassword(user.email, currentPassword, newPassword)
            setSuccessMessage("Password Changed Successfully")
            setIsLoading(false)
        } catch (error) {
            setErrorMessage(error.message)
            setIsLoading(false)
        }
    }

  return (
    <div>
        {
            successMessage &&
            <Toast message={successMessage} setSuccess={setSuccessMessage}/>
        }
        {
            errorMessage &&
            <Toast message={errorMessage} setError={setErrorMessage}/>
        }
        <form onSubmit={handleChangePassword}>
            <div class="form-group">
                <label for="currentPasswordControlSelect">Current Password</label>
                <input 
                    id='curPasswordInput'
                    type='password'
                    name='currentPassword'
                    value={currentPassword}
                    onChange={handleCurrentPassword}
                    className='form-control p2o-form-field'
                />
            </div>
            <div class="form-group">
                <label for="newPasswordInput">New Password</label>
                <input 
                    id='newPasswordInput'
                    type='password'
                    name='newPassword'
                    value={newPassword}
                    onChange={handleNewPassword}
                    className='form-control p2o-form-field'
                />
            </div>
            <div class="form-group">
                <label for="repeatNewPasswordInput">Repeat New Password</label>
                <input 
                    id='repeatNewPasswordInput'
                    type='password'
                    name='repeatNewPassword'
                    value={repeatNewPassword}
                    onChange={handleRepeatNewPassword}
                    className='form-control p2o-form-field'
                />
            </div>
            <button type="submit" class="btn btn-primary p2o-form-btn">
                {isLoading ? <LoadingIndicator /> : 'Submit'}
            </button>
        </form>
    </div>
  )
}

export default ChangePasswordForm