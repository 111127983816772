import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import Nav from '../components/Nav'
import Footer from '../components/Footer'
import { useAuth } from '../hooks/useAuth'
import LoadingIndicator from '../components/LoadingIndicator'
import Toast from '../components/Toast'
import { Helmet } from 'react-helmet'

function Login() {
    const {signInUser} = useAuth();

    const [emailAddress, setEmailAddress] = useState('')
    const [password, setPassword] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)

    const handleEmailAddress = event => {
        setEmailAddress(event.target.value)
    }

    const handlePassword = event => {
        setPassword(event.target.value)
    }

    const handleLogin = async event => {
        try {
            event.preventDefault()
            setIsLoading(true)
            await signInUser(emailAddress, password)
            setIsLoading(false)   
        } catch (error) {
            setErrorMessage(error.message)
            setIsLoading(false) 
        }
    }
  return (
    <>
        <Helmet>
            <title>Peer2Own -  Login</title>
        </Helmet>
        <Nav />
        {
            errorMessage &&
            <Toast message={errorMessage} setError={setErrorMessage}/>
        }
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xl-10 col-lg-12 col-md-9">
                    <div class="card o-hidden border-0 shadow-lg my-5">
                        <div class="card-body p-0">
                            <div class="row">
                                <div class="col-lg-6 d-none d-lg-block bg-login-image"></div>
                                    <div class="col-lg-6">
                                        <div class="p-5">
                                            <h1 class="h4 mb-4 p2o-form-title">Welcome Back!</h1>
                                            <hr className='p2o-form-title-divider' />
                                            <form onSubmit={handleLogin} class="user">
                                                <div class="form-group">
                                                    <input 
                                                        value={emailAddress}
                                                        onChange={handleEmailAddress}
                                                        type="email" 
                                                        className="form-control form-control-user"
                                                        id="inputEmail" 
                                                        aria-describedby="emailHelp"
                                                        placeholder="Enter Email Address..." 
                                                    />
                                                </div>
                                                <div class="form-group">
                                                    <input 
                                                        value={password}
                                                        onChange={handlePassword}
                                                        type="password" 
                                                        className="form-control form-control-user"
                                                        id="inputPassword" 
                                                        placeholder="Password" 
                                                    />
                                                </div>
                                                <div class="form-group">
                                                    <div class="custom-control custom-checkbox small">
                                                        <input 
                                                            type="checkbox" 
                                                            class="custom-control-input" 
                                                            id="customCheck" 
                                                        />
                                                        <label 
                                                            class="custom-control-label" 
                                                            for="customCheck">
                                                                Remember Me
                                                        </label>
                                                    </div>
                                                </div>
                                                <button type='submit' class="btn btn-primary btn-user btn-block cta-btn">
                                                    {isLoading ? <LoadingIndicator /> : 'Login'}
                                                </button>
                                                <hr />
                                                {/* <a href="index.html" class="btn btn-google btn-user btn-block">
                                                    <i class="fab fa-google fa-fw"></i> Login with Google
                                                </a>
                                                <a href="index.html" class="btn btn-facebook btn-user btn-block">
                                                    <i class="fab fa-facebook-f fa-fw"></i> Login with Facebook
                                                </a> */}
                                            </form>
                                            <hr />
                                            <div class="text-center">
                                                <Link class="small" to="/forgot-password">Forgot Password?</Link>
                                            </div>
                                            <div class="text-center">
                                                <Link class="small" to="/signup">Create an Account!</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
  )
}

export default Login