import React, { useEffect } from "react";
import { FileUploader } from "react-drag-drop-files";
import {FaRegWindowClose} from "react-icons/fa"
import { Link } from "react-router-dom";

function DragDrop({fileURLs, _fileURLs = [], handleChange, handleRemovePhoto, _handleRemovePhoto}) {
  return (
    <>
      <div>
         {
            _fileURLs.map((fileURL, index) => {
              return (
                <div className="photo-upload-img-item-container">
                    <img 
                      className="photo-upload-img" 
                      key={index} 
                      src={fileURL} 
                      alt={`Preview ${index}`} 
                      height={100} 
                    />
                    <Link 
                      className="photo-remove-icon"
                      onClick={() => _handleRemovePhoto(fileURL)}
                    >
                      <FaRegWindowClose />
                    </Link>
                </div>
              )
            })
          }
          {
            fileURLs.map((fileURL, index) => {
                return (
                  <div className="photo-upload-img-item-container">
                      <img 
                        className="photo-upload-img" 
                        key={index} 
                        src={fileURL.fileURL} 
                        alt={`Preview ${index}`} 
                        height={100} 
                      />
                      <Link 
                        className="photo-remove-icon"
                        onClick={() => handleRemovePhoto(fileURL.fileURL, fileURL.file)}
                      >
                        <FaRegWindowClose />
                      </Link>
                  </div>
                )
              })
            }
      </div>
      <br />
      <FileUploader 
        handleChange={handleChange} 
        name="file"
        types={["JPG", "PNG", "GIF"]}
        multiple={true}
      />
    </>
  );
}

export default DragDrop;