import React, {useEffect} from 'react'
import JoinRequestForm from '../components/JoinRequestForm'
import { useParams } from 'react-router-dom'
import Nav from '../components/Nav'
import Footer from '../components/Footer'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'

function SendJoinRequest() {
  const {globalPrefId, oppId} = useParams()
  const user = useSelector(state => state.user)

  const { pathname } = useLocation();

  useEffect(() => {
      window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div>
        <Helmet>
            <title>Peer2Own -  Join Opportunity</title>
        </Helmet>
        <Nav />
        <br />
        <div className='container'>
            <div className='row justify-content-center'>
                <div className='col-md-6 p2o-form'>
                    <h2 className='p2o-form-title'>
                      <strong>Join Opportunity</strong>
                    </h2>
                    <hr className='p2o-form-title-divider' />
                    {
                      /* !user.isSubscribed &&
                      <div className='alert alert-danger text-center text-capitalize'>
                        Only Subscribed Users can Join Opportunities. 
                        &nbsp;
                        <Link to='/subscribe' className='btn btn-success'>Subscribe</Link>
                      </div> */
                    }
                    <JoinRequestForm 
                      globalPrefId={globalPrefId} 
                    />
                </div>
            </div>
        </div>
        <br />
        <Footer />
    </div>
  )
}

export default SendJoinRequest