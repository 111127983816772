import axios from "axios"

export function useBlog() {

    const getAllPosts = async () => {
        try {
            const {data} = await axios.get('https://blog.peer2own.com/wp-json/wp/v2/posts');
            return data;
        } catch (error) {
            throw new Error(error.message)
        }
    }

    const getPost = async (id) => {
        try {
            const {data} = await axios.get(`https://blog.peer2own.com/wp-json/wp/v2/posts/${id}`);
            return data;
        } catch (error) {
            throw new Error(error.message)
        }
    }

    return {
        getAllPosts,
        getPost
    }
}