import React, {useEffect} from 'react'
import Nav from '../components/Nav'
import Footer from '../components/Footer'
import { Link, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'

function LandSellers() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
      }, [pathname]);

  return (
    <div>
        <Helmet>
            <title>Peer2Own | Find People to Share Land And Build With You.</title>
        </Helmet>
        <Nav />
        <section className='container-fluid text-center welcome-header land-sellers'>
            <br /><br />
            <h1 className='welcome-header-title'>Do You Have Direct</h1>
            <span className='welcome-header-title welcome-header-title-2'>
                Authority to Sell A Piece of Land
            </span><span className='welcome-header-title welcome-header-title-2'>?</span>
            <br /><br />
            <p className='welcome-header-elevator-pitch'>
                Let's help you sell your land faster by providing you with first-hand information on what buyers want.
            </p>
            <p className='welcome-header-elevator-pitch'>
                Sell your land to a highly qualified audience who are serious about buying.
            </p>
            <br />
            <Link className='btn btn-primary p2o-large-btn get-started-btn' to='/signup'>
                Sign Up
            </Link>
        </section>
        <section className='container-fluid why-p2o'>
            <br/><br/>
            <div className='container'>
              <h3 className='text-center text-white why-p2o-header'>
                Why Sell Through Peer2Own?
              </h3>
              <div className='row align-items-center why-p2o-item'>
                    <div className='col-md-4 text-center'>
                      <img src='./assets/only-one.jpg' width='100%' alt='Financially Ready' />
                    </div>
                    <div className='col'>
                        <br/>
                        <h2 className='text-white why-p2o-item-header'>
                            Be the only one selling your land
                        </h2>
                        <p className='text-white why-p2o-item-body'>
                            Peer2Own uses land title IDs to track each piece of land and ensure 
                            that no title is duplicated. This means that when you list your land on
                            Peer2Own, you can be confident that you are the only seller of that 
                            piece of land. This can give you a competitive advantage and help you 
                            sell your land faster.
                        </p>
                        <p className='text-white why-p2o-item-body'>
                            In addition, Peer2Own's land title tracking system helps to prevent 
                            fraud and protect buyers and sellers. By ensuring that each land title 
                            is unique and cannot be duplicated, Peer2Own helps to reduce the risk 
                            of title disputes and other problems. This will give buyers the confidence
                            to buy from you.
                        </p>
                    </div>
              </div>
              <div className='row align-items-center why-p2o-item'>
                  <div className='col'>
                      <br/>
                      <h2 className='text-white why-p2o-item-header'>
                        Sell Faster
                      </h2>
                      <p className='text-white why-p2o-item-body'>
                        Selling land quickly is a Real estate agent's dream, but most struggle to achieve this. 
                        Peer2Own can help by connecting you to qualified buyers, 
                        saving you time and money, and helping you close sales faster.
                      </p>
                  </div>
                  <div className='col-md-4 text-center'>
                      <img src='./assets/faster.jpg' width='100%' alt='Luxury Apartments' />
                  </div>
              </div>
              <div className='row align-items-center why-p2o-item'>
                    <div className='col-md-4 text-center'>
                      <img src='./assets/buyer-demands.jpg' width='100%' alt='Save Cost' />
                    </div>
                    <div className='col'>
                        <br/>
                            <h2 className='text-white why-p2o-item-header'>
                                Remain close to buyers
                            </h2>
                        <p className='text-white why-p2o-item-body'>
                            <strong>Stay ahead of the curve with Peer2Own.</strong> Gain insights into 
                            where buyers are looking to purchase land and build, helping you identify 
                            emerging trends and make informed decisions about your listings. 
                            With Peer2Own, you'll always be aligned with the market.
                        </p>
                    </div>
              </div>
              <div className='row align-items-center why-p2o-item'>
                  <div className='col'>
                      <br/>
                      <h2 className='text-white why-p2o-item-header'>
                        Have an Edge Over Other Land Sellers
                      </h2>
                      <p className='text-white why-p2o-item-body'>
                        In today's competitive land market, it's more important than ever to have an 
                        edge over other sellers. Selling your land through Peer2Own gives you an edge 
                        over other land sellers because we use sophisticated algorithms to match 
                        millions of buyers with opportunities that meet their needs. This means your land is 
                        more likely to be purchased by buyers who are genuinely interested in purchasing 
                        land. Also, Peer2Own takes the hassle out of selling land and gives you a fair
                        return for your expenditure.
                      </p>
                  </div>
                  <div className='col-md-4 text-center'>
                      <img 
                        src='./assets/have-an-edge.jpg' 
                        width='100%' alt='Tailored Opportunities' 
                      />
                  </div>
              </div>
              <div className='row align-items-center why-p2o-item'>
                    <div className='col-md-4 text-center'>
                      <img src='./assets/sold.jpg' width='100%' alt='Financially Ready' />
                    </div>
                    <div className='col'>
                        <br/>
                        <h2 className='text-white why-p2o-item-header'>
                            Sell More Land
                        </h2>
                        <p className='text-white why-p2o-item-body'>
                            Business cannot get any better than selling more. Become a member of Peer2Own and 
                            give yourself an opportunity to sell land more than you've ever sold before. 
                            Peer2Own helps you sell more land by connecting you with qualified buyers, so you 
                            can close more sales and ultimately sell more land over time.
                        </p>
                    </div>
              </div>
              <br /><br />
              <div className='text-center'>
                <Link className='btn btn-primary p2o-large-btn get-started-btn' to='/signup'>
                    Get Started
                </Link>
              </div>
            </div>
        </section>
        <section className='welcome-sign-up'>
          <div className='container'>
              <div className='row justify-content-center align-items-center'>
                  <div className='col-md-7 text-center'>
                        <h4 className='text-white'>
                          <strong>Sign up now for exclusive access.</strong>
                        </h4>
                        <p className='text-white'>
                          Start selling smarter today!
                        </p>
                  </div>
                  <div className='col text-center'>
                        <Link to='/signup' className='btn btn-primary cta-btn white-btn welcome-sign-up-btn'>
                          Sign Up Now
                        </Link>
                  </div>
              </div>
          </div>
        </section>
        <Footer />
    </div>
  )
}

export default LandSellers