import React, {useState, useEffect} from 'react'
import Nav from '../components/Nav'
import EditOpportunityForm from '../components/EditOpportunityForm'
import { useParams } from 'react-router-dom'
import { useOpportunity } from '../hooks/useOpportunity'
import LoadingIndicator from '../components/LoadingIndicator'
import { Helmet } from 'react-helmet'


function EditOpportunity() {
    const [opportunity, setOpportunity] = useState(null)
    const { globalPrefId, oppId } = useParams()
    const { getOpportunity } = useOpportunity()

    useEffect(() => {
        (async () => {
            const opp = await getOpportunity(globalPrefId, oppId)
            setOpportunity(opp)
        })()
    }, [])
  return (
    <div>
        <Helmet>
            <title>Peer2Own -  Edit Opportunity</title>
        </Helmet>
        <Nav />
        <br />
        <div className='container'>
            <div className='row justify-content-center'>
                <div className='col-md-6 p2o-form'>
                    <h1 className='p2o-form-title'>Edit Opportunity</h1>
                    <hr className='p2o-form-title-divider' />
                    {
                        opportunity ?
                        <EditOpportunityForm 
                            opportunity={opportunity}
                            globalPrefId={globalPrefId} 
                            oppId={oppId}
                        /> :
                        <div className='text-center'>
                            <LoadingIndicator color='#000'/>
                        </div>
                    }   
                </div>
            </div>
        </div>
        <br />
    </div>
  )
}

export default EditOpportunity