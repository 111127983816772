import React from 'react'
import NavBar from './Nav'

function ErrorFallBack() {
  return (
    <div>
        <NavBar />
        <div className='container'>
            <h2 className='text-black text-center'>
                <strong>
                    Oops! An Error Occured. We apologize for this. 
                    Please check back later as we work to fix this. Thanks.
                </strong>
            </h2>
        </div>
    </div>
  )
}

export default ErrorFallBack