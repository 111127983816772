import React, {useState, useEffect} from 'react'
import LoadingIndicator from './LoadingIndicator'
import Toast from './Toast'
import { useSelector } from 'react-redux'
import DragDrop from './DragDropFile'
import { useLandProposal } from '../hooks/useLandProposal'
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom'
import DisputeModalToast from './Toast'

function ProposeLandForm({globalPrefId}) {
    const user = useSelector(state => state.user)
    const [landLocation, setLandLocation] = useState('')
    const [landSize, setLandSize] = useState('')
    const [landCost, setLandCost] = useState('')
    const [landTitle, setLandTitle] = useState('')
    const [landTitleName, setLandTitleName] = useState('')
    const [landTitleIdNumber, setLandTitleIdNumber] = useState('')
    const [landPhotosFiles, setLandPhotosFiles] = useState([]);
    const [landPhotosFileURLs, setLandPhotosFileURLs] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [successMessage, setSuccessMessage] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)

    const [showDisputeModal, setShowDisputeModal] = useState(false)

    const {sendLandProposal, uploadLandPhotos} = useLandProposal()

    const handleOpenDisputeModal = () => setShowDisputeModal(false)
    const handleCloseDisputeModal = () => setShowDisputeModal(false)

    useEffect(() => {
        const newfileURLs = []
        landPhotosFiles.forEach(file => newfileURLs.push(
          {
            file: file,
            fileURL: URL.createObjectURL(file)
          }
        ))
        setLandPhotosFileURLs(newfileURLs)
      }, [landPhotosFiles, setLandPhotosFiles])

    const handleLandPhotos = _files => {
        try {
          if(landPhotosFiles.length >= 2) throw new Error("The max number of images you can upload is 2.")
          const filesFromList = Object.values(_files)
          setLandPhotosFiles(prev => {
            const newFiles = []
            filesFromList.forEach(file => newFiles.push(file))
            return [...newFiles, ...prev]
          })
        } catch (error) {
          setErrorMessage(error.message)
        }
    };

    const handleRemovePhoto = (photoUrl, file) => {
      const newLandPhotosFileURLs = landPhotosFileURLs.filter(url => url.fileURL !== photoUrl)
      const newLandPhotosFiles = landPhotosFiles.filter(f => f !== file)
  
      setLandPhotosFileURLs(newLandPhotosFileURLs)
      setLandPhotosFiles(newLandPhotosFiles)
    }

    const validateFormInput = () => {
      if(landLocation === '') throw new Error("Enter Land Location")
      if(landSize === '') throw new Error("Enter Land Size")
      if(landCost === '') throw new Error("Enter Land Cost")
      if(landTitle === '') throw new Error("Select Land Title")
      if(landTitleName === '') throw new Error("Enter the Name of the Land Title Document")
      if(landTitleIdNumber === '') throw new Error("Enter the Land Title ID Number of your Land")
      if(landPhotosFiles.length < 1) throw new Error("At least one image is required.")
      return true
    }  

    const handleSubmit = async event => {
        try {
            event.preventDefault()
            //if(!user.isSubscribed) throw new Error("Only Subscribed Members Are Authorized To Send Land Proposals.")
            setIsLoading(true)
            if(!validateFormInput()) return;
            const landProposal = {
                landLocation,
                landSize,
                landCost,
                landTitle,
                landTitleIdNumber,
                globalPrefId,
                uid: user.uid,
                accepted: false,
                ignored: false,
                verified: false,
                landPhotos: []
            }

            const landProposalId = await sendLandProposal(landProposal)
            if(landPhotosFiles.length > 0) await uploadLandPhotos(landProposalId, landPhotosFiles)
            setSuccessMessage("Land Proposal Sent.")
            setIsLoading(false)
        } catch (error) {
            setErrorMessage(error.message)
            setIsLoading(false)
            error.code = '808' && handleOpenDisputeModal()
        }
    }
  return (
    <div>
        <DisputeModal 
          showDisputeModal={showDisputeModal}
          handleClose={handleCloseDisputeModal}
          landTitleIdNumber={landTitleIdNumber}
        />
        {
          successMessage &&
          <Toast message={successMessage} setSuccess={setSuccessMessage}/>
        }
        {
          errorMessage &&
          <Toast message={errorMessage} setError={setErrorMessage}/>
        }
        <form onSubmit={handleSubmit}>
            <div className="form-group">
                <label for="landLocationControlInput">Land Location</label>
                <input 
                    id='landLocationControlInput'
                    className='form-control p2o-form-field' 
                    value={landLocation}
                    onChange={event => setLandLocation(event.target.value)} 
                />
            </div>
            <div className="form-group">
                <label for="landSizeControlInput">Land Size</label>
                <input 
                    id='landSizeControlInput'
                    className='form-control p2o-form-field' 
                    value={landSize}
                    onChange={event => setLandSize(event.target.value)} 
                />
            </div>
            <div className="form-group">
                <label for="landSizeControlInput">Cost of Land</label>
                <input 
                    id='landCostControlInput'
                    className='form-control p2o-form-field' 
                    value={landCost}
                    onChange={event => setLandCost(event.target.value)} 
                />
            </div>
            <div className='form-group'>
                <label for="landTitleControlSelect">Land Title Type</label>
                <select 
                  id='landTitleControlSelect'
                  className='form-control' 
                  value={landTitle}
                  onChange={event => setLandTitle(event.target.value)}
                >
                  <option value="">Select Land Title Type</option>
                  <option value="Certificate of Occupancy">Certificate of Occupancy</option>
                  <option value="Survey Plan">Survey Plan</option>
                  <option value="Deeds Of Assignment - Governor's Consent">Deeds Of Assignment (Governor's Consent)</option>
                  <option value ="Other Deeds">Other Deeds</option>
                </select>
            </div>
            <div className='form-group'>
                <label for="landTitleNameControlSelect">Name on Land Title Document</label>
                <input 
                  id='landTitleNameControlInput'
                  className='form-control p2o-form-field' 
                  value={landTitleName}
                  onChange={event => setLandTitleName(event.target.value)} 
                />
                <small className='text-danger'>
                  Enter this information only if you have the direct authority to sell this land. 
                </small>
            </div>
            <div className='form-group'>
                <label for="landTitleIdNumberControlSelect">Land Title Number</label>
                <input 
                  id='landTitleIdNumberControlInput'
                  className='form-control p2o-form-field' 
                  value={landTitleIdNumber}
                  onChange={event => setLandTitleIdNumber(event.target.value)} 
                />
                <small className='text-danger'>
                  Enter this information only if you have the direct authority to sell this land. 
                </small>
            </div>
            <div className='form-group'>
              <label>Land Photos</label>
              <div>
                <small className='text-info'>
                  Upload clear, zoomed-out images of the neighborhood where the land is located. 
                  Use a marker to indicate the exact location of the land.
                </small>
              </div>
              <DragDrop 
                fileURLs={landPhotosFileURLs}
                handleChange={handleLandPhotos}
                handleRemovePhoto={handleRemovePhoto}
              />
            </div>
            <button type='submit' className='btn btn-primary p2o-form-btn'>
                {isLoading ? <LoadingIndicator /> : 'Submit'}
            </button>
        </form>
    </div>
  )
}

const DisputeModal = props => {
  const {
      showDisputeModal,
      handleClose,
      landTitleIdNumber
  } = props

  const [isLoading, setIsLoading] = useState(false)
  const [successMessage, setSuccessMessage] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)

  const handleSubmitDispute = async () => {
      try {
          setIsLoading(true)
          setSuccessMessage("Successful! Your dispute has been submitted. We'll contact you soon.")
          setIsLoading(false)
      } catch (error) {
          setErrorMessage(error.message)
          setIsLoading(false)
      }
  }

  return (
          <Modal
              className='modal red-modal'
              size='md'
              show={showDisputeModal}
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
              fullscreen={true}
          >
              {
                  successMessage &&
                  <DisputeModalToast
                      message={successMessage} 
                      setSuccess={setSuccessMessage}
                      position='top-center'
                  />
              }
              {
                  errorMessage &&
                  <DisputeModalToast
                      message={errorMessage} 
                      setError={setErrorMessage}
                      position='top-center'
                  />
              }
              <Modal.Header closeButton>
                  <Modal.Title>
                      <span className='text-black'>
                          <strong>Dispute Land Title ID</strong>
                      </span>
                  </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <p className='text-black popup-text'>
                      Another real estate agent has previously submitted a piece of land with the 
                      Land Title ID Number you just provided - <strong>{landTitleIdNumber}</strong>.
                  </p>
                  <p className='text-black popup-text'>
                      Are you the right real estate agent with direct authority from 
                      the land owner to sell the land with Land Title ID Number 
                      <strong> {landTitleIdNumber}</strong>?
                  </p>
                  <p className='text-black popup-text'>
                      If you are, then click the button below to let us know and investigate.
                  </p>
                  <Link
                      onClick={handleSubmitDispute}
                      className='btn btn-primary p2o-form-btn'
                  >
                      {
                          isLoading ?
                          <LoadingIndicator color='#fff' /> :
                          'Submit'
                      }
                  </Link>
              </Modal.Body>
      </Modal>
  )
}

export default ProposeLandForm