import React, {useState, useEffect} from 'react'
import Nav from '../components/Nav'
import EditLandProposalForm from '../components/EditLandProposalForm'
import { useParams } from 'react-router-dom'
import { useLandProposal } from '../hooks/useLandProposal'
import LoadingIndicator from '../components/LoadingIndicator'
import { Helmet } from 'react-helmet'

function EditLandProposal() {
    const [landProposal, setLandPropsal] = useState(null)
    const { landProposalId } = useParams()
    const { getLandProposal } = useLandProposal()

    useEffect(() => {
        (async () => {
            const landProposal = await getLandProposal(landProposalId)
            setLandPropsal(landProposal)
        })()
    }, [])
  return (
    <div>
        <Helmet>
            <title>Peer2Own -  Edit Land Proposal</title>
        </Helmet>
        <Nav />
        <br />
        <div className='container'>
            <div className='row justify-content-center'>
                <div className='col-md-6 p2o-form'>
                    <h1 className='p2o-form-title'>Edit Land Proposal</h1>
                    <hr className='p2o-form-title-divider' />
                    {
                        landProposal ?
                        <EditLandProposalForm 
                            landProposal={landProposal}
                        /> :
                        <div className='text-center'>
                            <LoadingIndicator color='#000'/>
                        </div>
                    }   
                </div>
            </div>
        </div>
    </div>
  )
}

export default EditLandProposal