import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from "redux-persist"
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage'

const {UserSlice, userInitialState} = require('./reducers/userReducer')
const {MyPreferencesSlice} = require('./reducers/myPreferences')
const {LoadingSlice} = require('./reducers/loading')
const {ErrorSlice} = require('./reducers/errorReducer')
const {UploadingProgressSlice} = require('./reducers/uploadingProgress')
const {BlogPostsSlice} = require('./reducers/blogPostsReducer')

const userReducer = UserSlice.reducer
const loadingReducer = LoadingSlice.reducer
const errorReducer = ErrorSlice.reducer
const myPreferencesReducer = MyPreferencesSlice.reducer
const uploadingProgressReducer = UploadingProgressSlice.reducer
const blogPostsReducer = BlogPostsSlice.reducer

const persistConfig = {
  key: 'root',
  storage: storage,
  timeout: 5000
}

const rootReducer = combineReducers({
    user: userReducer,
    myPreferences: myPreferencesReducer,
    loading: loadingReducer,
    error: errorReducer,
    uploadingProgress: uploadingProgressReducer,
    blogPosts: blogPostsReducer
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
    reducer: persistedReducer,
    middleware: [thunk]
})

const UserActions = UserSlice.actions
const MyPreferencesActions = MyPreferencesSlice.actions
const LoadingActions = LoadingSlice.actions
const ErrorActions = ErrorSlice.actions
const UploadingProgressActions = UploadingProgressSlice.actions
const BlogPostsActions = BlogPostsSlice.actions

const persistor = persistStore(store)

export {
    store,
    persistor,
    userInitialState,
    UserActions,
    MyPreferencesActions,
    LoadingActions,
    ErrorActions,
    UploadingProgressActions,
    BlogPostsActions
}    