import React from 'react'
import Nav from '../components/Nav'
import Footer from '../components/Footer'
import EditProfileForm from '../components/EditProfileForm'
import { Helmet } from 'react-helmet'

function EditProfile() {
  return (
    <div>
        <Helmet>
            <title>Peer2Own -  Edit Profile</title>
        </Helmet>
        <Nav />
        <br />
        <div className='container'>
            <div className='row justify-content-center'>
                <div className='col-md-6 p2o-form'>
                    <h2 className='p2o-form-title'>Edit Profile</h2>
                    <hr className='p2o-form-title-divider'/>
                    <EditProfileForm />
                </div>
            </div>
        </div>
        <br />
        <Footer />
    </div>
  )
}

export default EditProfile