import React from 'react'
import Nav from '../components/Nav';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet';
import AllProjects from '../components/AllProjects';

function ViewAllProjects() {
  return (
    <div>
        <Helmet>
            <title>Peer2Own -  All Projects</title>
        </Helmet>
        <Nav />
        <br />
        <div className='container'>
            <h2 className='p2o-page-title'>
              <strong>All Projects</strong>
            </h2>
            <p>
              <h5 className='text-black'>
                These are all the projects that have been created so far.
              </h5>
            </p>
            <AllProjects /> 
        </div>
        <br />
        <Footer />
    </div>
  )
}

export default ViewAllProjects