const { createSlice } = require("@reduxjs/toolkit")

const initialState = []

const BlogPostsSlice = createSlice({
    name: 'blogPosts',
    initialState,
    reducers: {
        setBlogPostsState(state, action) {
            const newstate = action.payload
            return newstate
        }
    }
})

module.exports = {
    BlogPostsSlice,
}    