import React from 'react'
import Logo from '../assets/logo.png'
import { Link } from 'react-router-dom'
import {
    contactPhoneNumber, 
    contactEmailAddress,
    contactAddress
} from '../constants'
import {FaFacebook, FaInstagram} from 'react-icons/fa'
import {FaXTwitter} from 'react-icons/fa6'

function Footer() {
  return (
    <footer className='footer'>
        <div className='container'>
            <div className='row'>
                <div className='col-md-5'>
                    <Link to='/'>
                        <img 
                            src={Logo}
                            alt='Logo'
                            height={80}
                        />
                    </Link>
                    <br />
                    <p className='footer-p2o-description'>
                        Peer2Own makes it simple to collaborate with other people to collectively 
                        develop and own real estate. This is a great way to invest in real estate 
                        without having to come up with a large down payment. 
                    </p>
                </div>
                <div className='col-md-2'>
                    <br />
                    <h4 className='text-white'><strong>Site Links</strong></h4>
                    <ul>
                        <li><Link to="/land-sellers">Land Sellers</Link></li>
                        <li><Link to="/terms">Terms of Engagement</Link></li>
                        <li><Link to="/privacy">Privacy Policy</Link></li>
                    </ul>
                </div>
                <div className='col-md-3'>
                    <br />
                    <h4 className='text-white'><strong>Contact Us</strong></h4>
                    <p>{contactAddress}</p>
                    <p>Phone: {contactPhoneNumber}</p>
                    <p>Email: {contactEmailAddress}</p>
                </div>
                <div className='col-md-2'>
                    <br />
                    <h4 className='text-white'><strong>Follow Us</strong></h4>
                    <div>
                        <span>
                            <a href='https://facebook.com/peer2own' target='_blank' rel='noreferrer'>
                                <FaFacebook size={40} />
                            </a>
                        </span> &nbsp;
                        <span>
                            <a href='https://instagram.com/peer2own' target='_blank' rel='noreferrer'>
                                <FaInstagram size={40} />
                            </a>
                        </span>
                        <span>
                            <a href='https://twitter.com/peer2own' target='_blank' rel='noreferrer'>
                                <FaXTwitter size={40} />
                            </a>
                        </span>
                    </div>
                    <br />
                </div>
            </div>
        </div>
        <div className='container-fluid text-center footer-copyright'>
            &copy; {`${new Date().getFullYear()}`} Peer2Own by <a href='https://michandra.com' rel='noreferrer' target='_blank'>Michandra</a> | All Rights Reserved.
        </div>
    </footer>
  )
}

export default Footer