import {Provider} from 'react-redux'
import { store, persistor } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react'
import AppRouter from './AppRouter';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallBack from './components/ErrorFallBack';
import CookieConsent from "react-cookie-consent";
import { RWebShare } from "react-web-share";
import ShareIcon from './assets/share-icon.svg'
import { HelmetProvider } from 'react-helmet-async';

function App() {
  const helmetContext = {};
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <HelmetProvider context={helmetContext}>
          <ErrorBoundary fallback={<ErrorFallBack />}>
              <AppRouter />
              <CookieConsent
                location="bottom"
                buttonText="Okay"
                style={{ background: "#2B373B" }}
                buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                expires={150}
              >
                We use cookies to improve your browsing experience, remember your preferences, and 
                provide you with personalized content. By using our website, you agree to our use of 
                cookies.{" "}
              </CookieConsent>
              <div style={{position: 'fixed', bottom: '30px', right: '30px', borderRadius: '50px', padding: '8px', background: '#C13ABB'}}>
                <RWebShare
                  data={{
                    text: "Hey! I just found this service that enables you share land and build with others. I just thought to share it with you. Here's the link.",
                    url: "https://peer2own.com/",
                    title: "Find People To Share Land & Build With You.",
                  }}
                  onClick={() => console.log("shared successfully!")}
                >
                  <span className='text-white'>
                    <img src={ShareIcon} alt="Share Icon" width='30px' />
                    &nbsp;
                    Share With Friends
                  </span>
                </RWebShare>
              </div>
          </ErrorBoundary>
        </HelmetProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
