import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import NavBar from '../components/Nav'
import Footer from '../components/Footer'
import parse from 'html-react-parser'
import { Helmet } from 'react-helmet-async'
import { DiscussionEmbed } from 'disqus-react';
import { useBlog } from '../hooks/useBlog'
import LoadingIndicator from '../components/LoadingIndicator'
import { FacebookShareButton, TwitterShareButton } from 'react-share'
import { RWebShare } from 'react-web-share'
import ShareIcon from '../assets/share-icon.svg'

function ViewBlogPost() {
    const [blogPost, setBlogPost] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const {id, slug} = useParams()
    const blogPosts = useSelector(state => state.blogPosts)

    const {getPost} = useBlog()

    useEffect(() => {
        const filterBlogPost = () => {
            const blogPost = blogPosts.filter(post => parseInt(post.id) === parseInt(id) && post.slug === slug)
            return blogPost[0]
        }

        const fetchBlogPost = async () => {
            try {
                const blogPost = await getPost(id)
                return blogPost
            } catch (error) {
               throw new Error(error.message) 
            }
        }

        let blogPost;

        if(blogPosts.length < 1) {
            blogPost = fetchBlogPost()
            setBlogPost(blogPost)
            setIsLoading(false)
        } else {
            blogPost = filterBlogPost()
            setBlogPost(blogPost)
            setIsLoading(false)
        }
    }, [])

    const renderBody = () => {
        try {
            if(blogPost) return parse(blogPost.content.rendered) 
        } catch (error) {
            throw new Error(error.message)   
        }
    }  

function removeTags(str) { 
    if ((str===null) || (str==='')) 
        return false; 
    else
        str = str.toString();  
    return str.replace( /(<([^>]+)>)/ig, ''); 
}

function trimString(str) {
    var maxLength = 150
    var trimmedString = str.substr(0, maxLength);
    trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")))
    return trimmedString
}

  return (
    <div>
        <NavBar />
        <br />
        <div className='container blog-container'>
            {
                isLoading ?
                <div className='text-center'>
                    <LoadingIndicator color='#000' />
                </div> :
                (
                    blogPost &&
                    <div>
                        <Helmet prioritizeSeoTags>
                            <title>Peer2Own - {blogPost.title.rendered}</title>
                            <meta 
                                name="description" 
                                content={trimString(removeTags(blogPost.excerpt.rendered))} 
                            />
                            <meta property="og:title" content={`Peer2Own - ${blogPost.title.rendered}`} />
                            <meta property="og:description" content={trimString(removeTags(blogPost.excerpt.rendered))} />
                        </Helmet>
                        <div className='text-black'>
                            <h1 className='text-purple'>
                                <strong>{blogPost.title.rendered}</strong>
                            </h1>
                            {
                                blogPost.jetpack_featured_media_url &&
                                <>
                                    <img 
                                        src={blogPost.jetpack_featured_media_url} 
                                        alt='Featured' 
                                        width="100%" 
                                    />
                                    <br /><br />
                                </> 
                            }
                            {renderBody()}
                        </div>
                        <div className=''>
                            <h2>Share on Socials</h2>

                            <FacebookShareButton
                                quote={`Peer2Own - ${blogPost.title.rendered}`} 
                                url={`https://peer2own.com/blog/${slug}/${id}`}
                            >
                                Share on Facebook
                            </FacebookShareButton>
                            &nbsp;
                            <TwitterShareButton
                                title={`Peer2Own - ${blogPost.title.rendered}`}
                                url={`https://peer2own.com/blog/${slug}/${id}`}
                            >
                                Share on Twitter
                            </TwitterShareButton>
                        </div>
                        <DiscussionEmbed
                            shortname='peer2own-com'
                            config={
                                {
                                    url: blogPost.jetpack_featured_media_url,
                                    identifier: blogPost.slug,
                                    title: blogPost.title.rendered,
                                    language: 'en'
                                }
                            }
                        />
                    </div>
                )}
        </div>
        <br />
        <Footer />
    </div>
  )
}

export default ViewBlogPost